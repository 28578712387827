import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import {Bindings} from "data/constants/bindings";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {PlayerStatus, SortOrder} from "data/enums";
import type {ITeamStore} from "data/stores/team/team.store";

export interface IPlayerPoolPlayersController extends ViewController {
	readonly i18n: ILocalizationStore;
	get players(): IPlayer[];
	get selectedStatLabel(): string;
	get order(): SortOrder;
	sortPlayers: () => void;
}

@injectable()
export class PlayerPoolPlayersController implements IPlayerPoolPlayersController {
	get players(): IPlayer[] {
		return this._playersStore.filteredPlayers.filter(this.isNotEliminated);
	}

	get selectedStatLabel(): string {
		return this._teamBuilderStore.selectedStatLabel;
	}

	get order(): SortOrder {
		return this._teamBuilderStore.order;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	public sortPlayers = () => {
		this._teamBuilderStore.sortPlayerPool();
	};

	private isNotEliminated = (player: IPlayer) => {
		return (
			this._teamStore.lineupIDs.includes(player.id) ||
			player.status !== PlayerStatus.Eliminated
		);
	};
}
