import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeagues, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RequestState} from "data/enums";
import {isEmpty, isEqual} from "lodash";
import {extractErrorMessage} from "data/utils";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";

export interface IMyLeaguesController extends ViewController {
	readonly i18n: ILocalizationStore;

	get leagues(): ILeagues;
	get hasLeagues(): boolean;
	get leaguesForJoin(): ILeagues;
	get hasLeaguesForJoin(): boolean;
	get isLoading(): boolean;
	get isLoadingLeaguesForJoin(): boolean;
	get isSeasonStarted(): boolean;

	loadMoreMyLeagues: () => void;
	loadMoreLeaguesForJoin: () => void;
}

@injectable()
export class MyLeaguesController implements IMyLeaguesController {
	@observable private _requestStates = {
		general: RequestState.IDLE,
		leagues: RequestState.IDLE,
		leaguesForJoin: RequestState.IDLE,
	};

	get leagues() {
		return this._leaguesStore.myLeagues;
	}

	get hasLeagues() {
		return !isEmpty(this.leagues);
	}

	get leaguesForJoin() {
		return this._leaguesStore.leaguesForJoin;
	}

	get hasLeaguesForJoin() {
		return Boolean(
			!isEmpty(this.leaguesForJoin) && this._leaguesStore.leaguesForJoinSearchValue
		);
	}

	get isLoading() {
		return isEqual(this._requestStates.leagues, RequestState.PENDING);
	}

	get isLoadingLeaguesForJoin() {
		return isEqual(this._requestStates.leaguesForJoin, RequestState.PENDING);
	}

	get isSeasonStarted() {
		return this._roundsStore.isSeasonStarted;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	loadMoreMyLeagues = () => {
		this.setPendingState("leagues");
		this._leaguesStore
			.fetchMoreMyLeagues()
			.then(this.createSuccessHandlerOf("leagues"))
			.catch(this.createErrorHandlerOf("leagues"));
	};

	loadMoreLeaguesForJoin = () => {
		this.setPendingState("leaguesForJoin");
		this._leaguesStore
			.fetchMoreLeaguesForJoin({
				search: this._leaguesStore.leaguesForJoinSearchValue,
			})
			.then(this.createSuccessHandlerOf("leaguesForJoin"))
			.catch(this.createErrorHandlerOf("leaguesForJoin"));
	};

	@action private setPendingState = (type: keyof typeof this._requestStates) => {
		runInAction(() => {
			this._requestStates[type] = RequestState.PENDING;
		});
	};

	@action private createSuccessHandlerOf = (type: keyof typeof this._requestStates) => () => {
		runInAction(() => {
			this._requestStates[type] = RequestState.SUCCESS;
		});
	};

	@action private createErrorHandlerOf =
		(type: keyof typeof this._requestStates) => (error: AxiosError<IApiResponse>) => {
			runInAction(() => {
				this._requestStates[type] = RequestState.ERROR;
			});

			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error),
			});
		};

	@action init() {
		Promise.all([this._leaguesStore.fetchMyLeagues(), this._leaguesStore.fetchLeaguesForJoin()])
			.then(this.createSuccessHandlerOf("general"))
			.catch(this.createErrorHandlerOf("general"));
	}
}
