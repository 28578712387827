import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {ICompetition, type ICompetitionsStore} from "data/stores/competitions/competitions.store";
import {type ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {isEmpty} from "lodash";

interface IParam {
	filterCompetitions: string[];
}

export interface ILeagueFilterController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get competitions(): ICompetition[];
	isActive: (competitionId: number) => boolean;
}

@injectable()
export class LeagueFilterController implements ILeagueFilterController {
	@observable private _filterCompetitions: string[] = [];

	get competitions(): ICompetition[] {
		return this._competitionsStore.list;
	}

	private get isFilterCompetitionsEmpty(): boolean {
		return isEmpty(this._filterCompetitions) || this._filterCompetitions.includes("null");
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.CompetitionsStore) private _competitionsStore: ICompetitionsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore
	) {
		makeAutoObservable(this);
	}

	init(param: IParam): void {
		this._filterCompetitions = param.filterCompetitions;
	}

	onChange(param: IParam): void {
		this._filterCompetitions = param.filterCompetitions;
	}

	public isActive = (competitionId: number) => {
		return (
			this.isFilterCompetitionsEmpty ||
			this._filterCompetitions.includes(competitionId.toString())
		);
	};
}
