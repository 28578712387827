import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {ModalType, RequestState} from "data/enums";
import {isEqual} from "lodash";
import {IRanking} from "data/providers/api/rankings.api.provider";
import type {IRankingsStore, TLadderType} from "data/stores/rankings/rankings.store";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {extractErrorMessage} from "data/utils";
import {SelectChangeEvent} from "@mui/material";
import type {IPlayersStore} from "data/stores/players/players.store";
import type {ILiveScoresStore} from "data/stores/live_scores/live_scores.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";

export interface IRankingsController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isLoading(): boolean;
	get isLoadingMore(): boolean;
	get rankings(): IRanking[];
	get userRanking(): IRanking | null;
	get showUserRanking(): boolean;
	get nextPage(): boolean;
	get teamViewUserID(): number | null;
	get rounds(): IRound[];
	get ladderType(): TLadderType;
	get showGameWeek(): boolean;
	fetchMore: () => void;
	setTeamUserID: (value: number | null) => void;
	onChangeGameWeek: (event: SelectChangeEvent<unknown>) => void;
}

@injectable()
export class RankingsController implements IRankingsController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _requestStateMore: RequestState = RequestState.IDLE;
	@observable private _teamViewUserID: number | null = null;

	private _page = 1;
	private _limit = 20;

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isLoadingMore(): boolean {
		return isEqual(this._requestStateMore, RequestState.PENDING);
	}

	get rankings(): IRanking[] {
		return this._rankingsStore.leaderboard.rankings;
	}

	get userRanking(): IRanking | null {
		return this._rankingsStore.leaderboard.user;
	}

	get showUserRanking(): boolean {
		return !this.rankings.some((ranking) => ranking.userId === this.userRanking?.userId);
	}

	get nextPage(): boolean {
		return this._rankingsStore.leaderboard.nextPage;
	}

	get teamViewUserID(): number | null {
		return this._teamViewUserID;
	}

	get ladderType(): TLadderType {
		return this._rankingsStore.ladderType;
	}

	get rounds(): IRound[] {
		return this._roundsStore.completedAndActiveRounds;
	}

	get showGameWeek(): boolean {
		return this._rankingsStore.ladderType !== "overall";
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.LiveScoresStore) private _liveScoresStore: ILiveScoresStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action async init() {
		this._requestState = RequestState.PENDING;

		await Promise.all([
			this._roundsStore.fetchRounds(),
			this._playersStore.fetchPlayers(),
			this._squadsStore.fetchSquads(),
		]).catch(this.onError);

		if (this._roundsStore.scoreRound) {
			await this._liveScoresStore
				.fetchLiveScores(this._roundsStore.scoreRound.id)
				.catch(this.onError);
		}

		this._rankingsStore.fetchOverallLeaderboard({}).then(this.onSuccess).catch(this.onError);
	}

	@action dispose(): void {
		this._rankingsStore.ladderType = "overall";
	}

	@action onSuccessLoadMore = () => {
		this._requestStateMore = RequestState.SUCCESS;
	};

	@action onErrorLoadMore = (error: AxiosError<IApiResponse>) => {
		this._requestStateMore = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action fetchMore = () => {
		this._page += 1;
		this._requestStateMore = RequestState.PENDING;
		this._rankingsStore
			.fetchMoreOverallLeaderboard({
				limit: this._limit,
				page: this._page,
			})
			.then(this.onSuccessLoadMore)
			.catch(this.onErrorLoadMore);
	};

	@action setTeamUserID = (value: number | null) => {
		if (value === this._teamViewUserID) {
			this._teamViewUserID = null;
			return;
		}
		this._teamViewUserID = value;
	};

	@action onChangeGameWeek = async (event: SelectChangeEvent<unknown>) => {
		const value = event.target.value as TLadderType;
		this._rankingsStore.ladderType = value;

		this._teamViewUserID = null;

		if (this._rankingsStore.ladderType === "overall") {
			await this._rankingsStore.fetchOverallLeaderboard({});
		} else {
			await Promise.all([
				this._liveScoresStore.fetchLiveScores(this._rankingsStore.ladderType),
				this._rankingsStore.fetchWeeklyLeaderboard({
					roundId: this._rankingsStore.ladderType,
				}),
			]);
		}
	};
}
