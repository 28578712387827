import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {
	ILadderPayload,
	IRanking,
	IRankingsApiProvider,
	IRankingsPayload,
	IWeeklyLadderPayload,
	IWeeklyRankingsPayload,
} from "data/providers/api/rankings.api.provider";
import {Bindings} from "data/constants/bindings";
import {uniqBy} from "lodash";

export interface IRankings {
	rankings: IRanking[];
	user: IRanking | null;
	nextPage: boolean;
}

export type TLadderType = "overall" | number;

export interface IRankingsStore {
	get leaderboard(): IRankings;
	get ladder(): IRankings;
	get ladderType(): TLadderType;
	set ladderType(value: TLadderType);

	fetchOverallLeaderboard(params: IRankingsPayload): Promise<void>;
	fetchMoreOverallLeaderboard: (params: IRankingsPayload) => Promise<void>;
	fetchWeeklyLeaderboard(params: IWeeklyRankingsPayload): Promise<void>;
	fetchMoreWeeklyLeaderboard(params: IWeeklyRankingsPayload): Promise<void>;

	fetchOverallLadder(params: ILadderPayload): Promise<void>;
	fetchMoreOverallLadder(params: ILadderPayload): Promise<void>;
	fetchWeeklyLadder(params: IWeeklyLadderPayload): Promise<void>;
	fetchMoreWeeklyLadder(params: IWeeklyLadderPayload): Promise<void>;
}

@injectable()
export class RankingsStore implements IRankingsStore {
	@observable private _leaderboard: IRankings = {
		rankings: [],
		user: null,
		nextPage: false,
	};
	@observable private _ladder: IRankings = {
		rankings: [],
		user: null,
		nextPage: false,
	};
	@observable private _ladderType: TLadderType = "overall";

	get leaderboard(): IRankings {
		return this._leaderboard;
	}

	get ladder(): IRankings {
		return this._ladder;
	}

	get ladderType(): TLadderType {
		return this._ladderType;
	}

	set ladderType(value: TLadderType) {
		this._ladderType = value;
	}

	constructor(@inject(Bindings.RankingsApiProvider) private _rankingsApi: IRankingsApiProvider) {
		makeAutoObservable(this);
	}

	@action async fetchOverallLeaderboard(params: IRankingsPayload): Promise<void> {
		const response = await this._rankingsApi.overall_leaderboard(params);

		runInAction(() => {
			this._leaderboard = response.data.success.leaderboard;
		});
	}

	@action async fetchMoreOverallLeaderboard(params: IRankingsPayload): Promise<void> {
		const response = await this._rankingsApi.overall_leaderboard(params);

		runInAction(() => {
			const {rankings, ...otherData} = response.data.success.leaderboard;

			this._leaderboard = {
				rankings: uniqBy([...this.leaderboard.rankings, ...rankings], "userId"),
				...otherData,
			};
		});
	}

	@action async fetchWeeklyLeaderboard(params: IWeeklyRankingsPayload): Promise<void> {
		const response = await this._rankingsApi.weekly_leaderboard(params);

		runInAction(() => {
			this._leaderboard = response.data.success.leaderboard;
		});
	}

	@action async fetchMoreWeeklyLeaderboard(params: IWeeklyRankingsPayload): Promise<void> {
		const response = await this._rankingsApi.weekly_leaderboard(params);

		runInAction(() => {
			const {rankings, ...otherData} = response.data.success.leaderboard;

			this._leaderboard = {
				rankings: uniqBy([...this.leaderboard.rankings, ...rankings], "userId"),
				...otherData,
			};
		});
	}

	@action async fetchOverallLadder(params: ILadderPayload): Promise<void> {
		const response = await this._rankingsApi.overall_ladder(params);

		runInAction(() => {
			this._ladder = response.data.success.ladder;
		});
	}

	@action async fetchMoreOverallLadder(params: ILadderPayload): Promise<void> {
		const response = await this._rankingsApi.overall_ladder(params);

		runInAction(() => {
			const {rankings, ...otherData} = response.data.success.ladder;

			this._ladder = {
				rankings: uniqBy([...this.ladder.rankings, ...rankings], "userId"),
				...otherData,
			};
		});
	}

	@action async fetchWeeklyLadder(params: IWeeklyLadderPayload): Promise<void> {
		const response = await this._rankingsApi.weekly_ladder(params);

		runInAction(() => {
			this._ladder = response.data.success.ladder;
		});
	}

	@action async fetchMoreWeeklyLadder(params: IWeeklyLadderPayload): Promise<void> {
		const response = await this._rankingsApi.weekly_ladder(params);

		runInAction(() => {
			const {rankings, ...otherData} = response.data.success.ladder;

			this._ladder = {
				rankings: uniqBy([...this.ladder.rankings, ...rankings], "userId"),
				...otherData,
			};
		});
	}
}
