/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * as MUI theme override required override interfaces
 */
import {PaletteColorOptions, ThemeOptions} from "@mui/material/styles";

declare module "@mui/material/styles" {
	interface CustomPalette {
		tertiary: PaletteColorOptions;
		neutral: PaletteColorOptions;
		active: string;
		captain: string;
		viceCaptain: string;
		championship: string;
		league1: string;
		league2: string;
	}

	interface Palette extends CustomPalette {
		tertiary: Palette["primary"];
		neutral: Palette["primary"];
	}

	interface PaletteOptions extends CustomPalette {
		tertiary?: Palette["primary"];
		neutral?: Palette["primary"];
	}
}

export const paletteTheme: ThemeOptions = {
	palette: {
		primary: {
			main: "#001489",
			dark: "#0E132F",
			light: "#3343A0",
		},
		secondary: {
			main: "#BA0C2F",
			dark: "#820820",
			light: "#C73C58",
		},
		tertiary: {
			main: "#B4BDEE",
			dark: "#7D84A6",
			light: "#C3CAF1",
			contrastText: "#0E132F",
		},
		neutral: {
			main: "#EFEFEF",
			dark: "#B6B8C0",
			light: "#F2F2F2",
			contrastText: "#0E132F",
		},
		text: {
			primary: "#0E132F",
			disabled: "#7E808F",
		},
		error: {
			main: "#D12B2B",
			dark: "#921E1E",
			light: "#DA5555",
		},
		warning: {
			main: "#FF9800",
			dark: "#B26A00",
			light: "#FFAC33",
		},
		info: {
			main: "#00d4dc",
		},
		success: {
			main: "#18C971",
			dark: "#108C4F",
			light: "#46D38D",
		},
		active: "#001489",
		captain: "#FBF576",
		viceCaptain: "#E26FFF",
		championship: "#B69B42",
		league1: "#8E8F8F",
		league2: "#BA0C2F",
	},
};
