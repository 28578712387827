import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";

export interface IPlayerScores {
	playerId: number;
	gameId: number;
	squadId: number;
	roundId: number;
	points: number;
	minutesPlayed: number;
	goalsScored: number;
	hatTricks: number;
	assists: number;
	penaltyMisses: number;
	redCards: number;
	yellowCards: number;
	saves: number;
	penaltySaves: number;
	cleanSheet: number;
	goalsConceded: number;
	clearances: number;
	blocks: number;
	tackles: number;
	interceptions: number;
	keyPasses: number;
	shotsOnTarget: number;
}

export interface ISquadScores {
	squadId: number;
	gameId: number;
	roundId: number;
	points: number;
	win: number;
	draw: number;
	awayWin: number;
	cleanSheet: number;
	goalsScored: number;
}

export interface ILiveScores {
	players: IPlayerScores[];
	squads: ISquadScores[];
}

export interface ILiveScoresStore {
	getLiveScoresByRound(roundId: number): ILiveScores | undefined;
	getPlayerScoresByRound(roundId: number, playerId: number): IPlayerScores[];
	getSquadScoresByRound(roundId: number, squadId: number): ISquadScores[];
	fetchLiveScores(roundId: number): Promise<void>;
}

@injectable()
export class LiveScoresStore implements ILiveScoresStore {
	@observable private _liveScores: Record<number, ILiveScores> = {};

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	getLiveScoresByRound(roundId: number): ILiveScores | undefined {
		return this._liveScores[roundId];
	}

	getPlayerScoresByRound(roundId: number, playerId: number): IPlayerScores[] {
		const liveScores = this._liveScores[roundId];
		if (!liveScores) {
			return [];
		}
		return liveScores.players.filter((player) => player.playerId === playerId);
	}

	getSquadScoresByRound(roundId: number, squadId: number): ISquadScores[] {
		const liveScores = this._liveScores[roundId];
		if (!liveScores) {
			return [];
		}
		return liveScores.squads.filter((squad) => squad.squadId === squadId);
	}

	@action
	async fetchLiveScores(roundId: number) {
		const {data} = await this._jsonProvider.liveScores(roundId);

		runInAction(() => {
			this._liveScores[roundId] = data;
		});
	}
}
