import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, observable} from "mobx";

export interface ILegendController extends ViewController {
	get i18n(): ILocalizationStore;
	get isExpanded(): boolean;
	toggleLegend: () => void;
}

@injectable()
export class LegendController implements ILegendController {
	@observable private _isExpanded: boolean = JSON.parse(
		localStorage.getItem("legendExpanded") || "true"
	) as boolean;

	get isExpanded(): boolean {
		return this._isExpanded;
	}

	constructor(@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore) {
		makeAutoObservable(this);
	}

	public toggleLegend = (): void => {
		this._isExpanded = !this._isExpanded;
		localStorage.setItem("legendExpanded", JSON.stringify(this._isExpanded));
	};
}
