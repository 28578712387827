import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IModalTutorialController} from "views/components/modals/modal_tutorial/modal_tutorial.controller";
import {Button, IconButton, Modal, Stack, Typography, useMediaQuery} from "@mui/material";
import {CloseBlock, ModalContent, ModalImage, ModalInfo} from "views/components/modals/common";
import {ReactComponent as CloseIcon} from "assets/img/icons/close.svg";
import styled from "@emotion/styled";
import {Exist} from "views/components/exist/exist.component";
import {Link} from "react-router-dom";
import background from "assets/img/modal_shape_tutorial.svg";
import backgroundMobile from "assets/img/modal_shape_tutorial_mobile.svg";
import {useTheme} from "@emotion/react";

const StyledModalContent = styled(ModalContent)`
	display: flex;
	flex-direction: column;
`;

const StyledModalImage = styled(ModalImage)`
	position: relative;
	height: 460px;
	min-height: 0;
	background-image: url(${background});
	align-items: flex-end;

	${({theme}) => theme.breakpoints.down("md")} {
		height: 380px;
		background-image: url(${backgroundMobile});
	}
`;

const LogoWrapper = styled.div`
	width: 100%;
	height: 100%;

	img {
		height: 100%;
		width: 100%;
		object-fit: contain;
	}

	${({theme}) => theme.breakpoints.down("md")} {
		margin-bottom: -20px;
	}
`;

const StyledModalInfo = styled(ModalInfo)`
	text-align: center;
	min-height: 285px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
	position: relative;
	z-index: 1;

	button {
		max-width: 300px;
	}

	${({theme}) => theme.breakpoints.up("md")} {
		min-height: 225px;
	}
`;

const Buttons = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 8px;
`;

export const ModalTutorial: React.FC = observer(() => {
	const {i18n, isOpen, currentScreen, isStepOneFinalScreen, isFinalScreen, close, onNext} =
		useViewController<IModalTutorialController>(Bindings.ModalTutorialController);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	const src = isMobile ? currentScreen.imageMobile : currentScreen.image;

	return (
		<Modal open={isOpen} onClose={close}>
			<StyledModalContent>
				<StyledModalImage>
					<CloseBlock>
						<IconButton sx={{minWidth: 0}} onClick={close}>
							<CloseIcon />
						</IconButton>
					</CloseBlock>

					<LogoWrapper>
						<img src={src} alt={currentScreen.title} />
					</LogoWrapper>
				</StyledModalImage>

				<StyledModalInfo>
					<Stack spacing={1}>
						<Typography variant="h4">{i18n.t(currentScreen.title)}</Typography>
						<Typography>{i18n.t(currentScreen.description)}</Typography>
					</Stack>
					<Exist when={!isStepOneFinalScreen && !isFinalScreen}>
						<Button fullWidth variant="contained" onClick={onNext}>
							{i18n.t("tutorial.step_one.next", "Next")}
						</Button>
					</Exist>
					<Exist when={isStepOneFinalScreen}>
						<Buttons>
							<Button fullWidth variant="contained" onClick={close}>
								{i18n.t("tutorial.step_one.get_started", "Get Started")}
							</Button>
							<Button fullWidth variant="outlined" color="secondary" onClick={onNext}>
								{i18n.t("tutorial.step_one.learn_more", "Learn More")}
							</Button>
						</Buttons>
					</Exist>
					<Exist when={isFinalScreen}>
						<Buttons>
							<Button fullWidth sx={{px: 0}} variant="contained" onClick={close}>
								{i18n.t("tutorial.step_one.get_started", "Get Started")}
							</Button>
							<Button
								fullWidth
								sx={{px: 0}}
								variant="outlined"
								color="secondary"
								onClick={close}
								component={Link}
								to="/help/game-guidelines">
								{i18n.t("tutorial.step_one.game_guidelines", "Game Guidelines")}
							</Button>
						</Buttons>
					</Exist>
				</StyledModalInfo>
			</StyledModalContent>
		</Modal>
	);
});
