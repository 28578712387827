import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {SortOrder} from "data/enums";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";

export interface IPlayerPoolSquadsController extends ViewController {
	readonly i18n: ILocalizationStore;
	get squads(): ISquad[];
	get selectedStatLabel(): string;
	get order(): SortOrder;
	get isGridView(): boolean;
	get isSortedByCap(): boolean;
	get isSeasonStarted(): boolean;
	sortSquads: () => void;
	sortSquadsByCap: () => void;
}

@injectable()
export class PlayerPoolSquadsController implements IPlayerPoolSquadsController {
	get squads(): ISquad[] {
		return this._squadsStore.getFilteredSquads(this._teamStore.team.squadSelections);
	}

	get selectedStatLabel(): string {
		return this._teamBuilderStore.selectedStatLabel;
	}

	get order(): SortOrder {
		return this._teamBuilderStore.order;
	}

	get isGridView(): boolean {
		return this._teamBuilderStore.isPoolGridView;
	}

	get isSortedByCap(): boolean {
		return this._teamBuilderStore.isPoolSortedByCap;
	}

	get isSeasonStarted(): boolean {
		return this._roundsStore.isSeasonStarted;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	public sortSquads = () => {
		if (this._teamBuilderStore.isPoolSortedByCap) {
			this._teamBuilderStore.isPoolSortedByCap = false;
			return;
		}
		this._teamBuilderStore.sortPlayerPool();
	};

	public sortSquadsByCap = () => {
		if (!this._teamBuilderStore.isPoolSortedByCap) {
			this._teamBuilderStore.isPoolSortedByCap = true;
			return;
		}
		this._teamBuilderStore.sortPlayerPool();
	};
}
