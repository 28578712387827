import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";

export const Input = styled(TextField)`
	background: transparent;
	color: #a3a3a3;
	border-radius: 4px;
	text-align: left;

	.MuiInputLabel-root,
	.MuiInputLabel-root.Mui-focused {
		color: #a3a3a3;
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: var(--primaryColor);
	}
`;

Input.defaultProps = {
	variant: "filled",
	fullWidth: true,
};
