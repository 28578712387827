import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type ISnackbarController} from "views/components/snackbar/snackbar.controller";
import {Alert, IconButton, Snackbar as MuiSnackbar} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

export const Snackbar: React.FC = observer(() => {
	const {open, messageInfo, handleClose, handleExited} = useViewController<ISnackbarController>(
		Bindings.SnackbarController
	);

	return (
		<MuiSnackbar
			key={messageInfo ? messageInfo.key : undefined}
			anchorOrigin={{vertical: "top", horizontal: "center"}}
			open={open}
			autoHideDuration={6000}
			onClose={handleClose}
			TransitionProps={{onExited: handleExited}}>
			<Alert
				onClose={handleClose}
				icon={false}
				severity="success"
				variant="filled"
				sx={{width: "100%"}}
				action={
					<IconButton
						aria-label="close"
						color="inherit"
						sx={{p: 0.5}}
						onClick={handleClose}>
						<CancelIcon />
					</IconButton>
				}>
				{messageInfo ? messageInfo.message : undefined}
			</Alert>
		</MuiSnackbar>
	);
});
