import {makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {
	IPoolFilters,
	ISelectOption,
	ITeamBuilderStore,
} from "data/stores/team_builder/team_builder.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import {SelectChangeEvent} from "@mui/material";
import {chain, get, isArray, sortBy} from "lodash";
import type {ICompetition, ICompetitionsStore} from "data/stores/competitions/competitions.store";
import type {IUserStore} from "data/stores/user/user.store";

export interface IPlayerPoolFiltersController extends ViewController {
	readonly i18n: ILocalizationStore;

	get expanded(): boolean;
	get filters(): IPoolFilters;
	get squads(): ISquad[];
	get competitions(): ICompetition[];
	get statsOptions(): ISelectOption[];
	get positionOptions(): ISelectOption[];
	get isResetDisabled(): boolean;

	updateFilter: (e: SelectChangeEvent | SelectChangeEvent<unknown>) => void;
	renderPositionValue: (value: unknown) => string;
	renderCompetitionValue: (value: unknown) => string;
	renderSquadValue: (value: unknown) => string;
	resetFilters: () => void;
}

@injectable()
export class PlayerPoolFiltersController implements IPlayerPoolFiltersController {
	@observable private _filterSquadsDisposer?: ReturnType<typeof reaction>;

	constructor(
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.CompetitionsStore) private _competitionsStore: ICompetitionsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get expanded(): boolean {
		return this._teamBuilderStore.isPoolFiltersVisible;
	}

	get squads(): ISquad[] {
		if (this.filters.competition.filter((val) => val !== "null").length > 0) {
			return chain(this._squadsStore.list)
				.filter((squad) =>
					this.filters.competition.includes(squad.competitionId.toString())
				)
				.sortBy("name")
				.value();
		}
		return sortBy(this._squadsStore.list, "name");
	}

	get competitions(): ICompetition[] {
		return this._competitionsStore.list;
	}

	get statsOptions() {
		return this._teamBuilderStore.statsOptions;
	}

	get positionOptions() {
		return this._teamBuilderStore.positionOptions;
	}

	get filters(): IPoolFilters {
		return this._teamBuilderStore.filters;
	}

	get isResetDisabled(): boolean {
		return this._teamBuilderStore.selectedFiltersLength === 0;
	}

	init(): void {
		this._filterSquadsDisposer = reaction(
			() => this._userStore.user,
			() => {
				if (this._userStore.user) {
					const competition = this._squadsStore.getSquadById(
						this._userStore.user.teamSupportedId
					);
					const competitionId = get(competition, "competitionId", null);
					this._teamBuilderStore.filters = {
						...this._teamBuilderStore.filters,
						competition: [String(competitionId)],
						squad: ["null"],
					};
				} else {
					this._teamBuilderStore.resetFilters();
				}
			},
			{fireImmediately: true}
		);
	}

	dispose(): void {
		this._filterSquadsDisposer?.();
	}

	public updateFilter = (e: SelectChangeEvent | SelectChangeEvent<unknown>) => {
		this._teamBuilderStore.updateFilter(e);
	};

	public renderPositionValue = (value: unknown) => {
		const selected = value as string[];
		if (!selected || selected.length === 0 || selected[0] === "null") {
			return this.i18n.t("player_pool.position_filter.all");
		}

		const positions = this.positionOptions
			.filter((option) => selected.includes(option.val))
			.map((option) => this.i18n.t(option.label));
		return isArray(selected) ? positions.join(", ") : selected;
	};

	public renderCompetitionValue = (value: unknown) => {
		const selected = value as string[];
		if (!selected || selected.length === 0 || selected[0] === "null") {
			return this.i18n.t("player_pool.league_filter.all");
		}

		const competitions = this.competitions
			.filter((competition) => selected.includes(competition.id.toString()))
			.map((competition) => competition.name);
		return isArray(selected) ? competitions.join(", ") : selected;
	};

	public renderSquadValue = (value: unknown) => {
		const selected = value as string[];
		if (!selected || selected.length === 0 || selected[0] === "null") {
			return this.i18n.t("player_pool.club_filter.all");
		}

		const squads = this.squads
			.filter((squad) => selected.includes(squad.id.toString()))
			.map((squad) => squad.name);
		return isArray(selected) ? squads.join(", ") : selected;
	};

	public resetFilters = () => {
		this._teamBuilderStore.resetFilters();
	};
}
