import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {PlayerPosition} from "data/enums";
import type {IPlayer, IPlayerStats, IPlayersStore} from "data/stores/players/players.store";

interface IStatOption {
	val: string;
	label: string;
	position: PlayerPosition[];
}

interface IParam {
	player: IPlayer;
}

export interface IPlayerProfileFantasyStatsController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get playerStats(): IPlayerStats | undefined;
	get playerStatsArray(): IStatOption[];
}

@injectable()
export class PlayerProfileFantasyStatsController implements IPlayerProfileFantasyStatsController {
	@observable private _player: IPlayer | undefined;

	private _statsOptions = [
		{
			val: "appearances",
			label: "player_profile.stat.appearances",
			position: [
				PlayerPosition.GK,
				PlayerPosition.DEF,
				PlayerPosition.MID,
				PlayerPosition.FWD,
			],
		},
		{
			val: "cleanSheets",
			label: "player_profile.stat.clean_sheets",
			position: [PlayerPosition.GK, PlayerPosition.DEF],
		},
		{
			val: "goalsConceded",
			label: "player_profile.stat.goals_conceded",
			position: [PlayerPosition.GK, PlayerPosition.DEF],
		},
		{val: "saves", label: "player_profile.stat.saves", position: [PlayerPosition.GK]},
		{val: "tackles", label: "player_profile.stat.tackles", position: [PlayerPosition.DEF]},
		{
			val: "clearances",
			label: "player_profile.stat.clearances",
			position: [PlayerPosition.DEF],
		},
		{val: "blocks", label: "player_profile.stat.blocks", position: [PlayerPosition.DEF]},
		{
			val: "goalsScored",
			label: "player_profile.stat.goals",
			position: [PlayerPosition.MID, PlayerPosition.FWD],
		},
		{
			val: "assists",
			label: "player_profile.stat.assists",
			position: [PlayerPosition.MID, PlayerPosition.FWD],
		},
		{
			val: "interceptions",
			label: "player_profile.stat.interceptions",
			position: [PlayerPosition.MID],
		},
		{val: "hatTricks", label: "player_profile.stat.hat_tricks", position: [PlayerPosition.FWD]},
		{
			val: "keyPasses",
			label: "player_profile.stat.key_passes",
			position: [PlayerPosition.MID, PlayerPosition.FWD],
		},
		{
			val: "shotsOnTarget",
			label: "player_profile.stat.shots_on_target",
			position: [PlayerPosition.MID, PlayerPosition.FWD],
		},
	];

	get playerStats(): IPlayerStats | undefined {
		if (!this._player) {
			return;
		}
		return this._playersStore.getPlayerStatsById(this._player.id);
	}

	get playerStatsArray(): IStatOption[] {
		if (!this._player) {
			return [];
		}
		const position = this._player.position;
		return this._statsOptions.filter((option) => option.position.includes(position));
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore
	) {
		makeAutoObservable(this);
	}

	init(param: IParam): void {
		this._player = param.player;
	}

	onChange(param: IParam): void {
		this._player = param.player;
	}
}
