import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISquad} from "data/stores/squads/squads.store";
import type {IGame, IRoundsStore} from "data/stores/rounds/rounds.store";
import {isEmpty} from "lodash";
import {GameStatus} from "data/enums";

interface IParam {
	squad: ISquad;
}

export interface ISquadProfileResultsController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get results(): IGame[];
	get fixtures(): IGame[];
	get isLoading(): boolean;
}

@injectable()
export class SquadProfileResultsController implements ISquadProfileResultsController {
	@observable private _squad: ISquad | undefined;

	get results(): IGame[] {
		if (!this._squad) {
			return [];
		}

		const squadId = this._squad.id;
		return this._roundsStore.games
			.filter(
				({awayId, homeId, status}) =>
					status !== GameStatus.Scheduled && [awayId, homeId].includes(squadId)
			)
			.toReversed();
	}

	get fixtures(): IGame[] {
		if (!this._squad) {
			return [];
		}

		const squadId = this._squad.id;
		return this._roundsStore.scheduledGames.filter(({awayId, homeId}) =>
			[awayId, homeId].includes(squadId)
		);
	}

	get isLoading(): boolean {
		return isEmpty(this.results) && isEmpty(this.fixtures);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private readonly _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	init(param: IParam): void {
		this._squad = param.squad;
	}

	onChange(param: IParam): void {
		this._squad = param.squad;
	}
}
