import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";

export interface IModalTeamSavedController extends ViewController {
	i18n: ILocalizationStore;
	get isOpen(): boolean;
	get roundId(): number | undefined;
	get isSeasonStarted(): boolean;
	close: () => void;
}

@injectable()
export class ModalTeamSavedController implements IModalTeamSavedController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private readonly _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.TEAM_SAVED;
	}

	get roundId(): number | undefined {
		return this._roundsStore.currentRound?.id;
	}

	get isSeasonStarted(): boolean {
		return this._roundsStore.isSeasonStarted;
	}

	close = () => {
		this._modalsStore.hideModal();
	};
}
