import {action, makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {ViewController} from "data/types/structure";
import type {ILineupPlayers, ITeamStore} from "data/stores/team/team.store";
import {
	CreateTeamSteps,
	ModalType,
	PlayerPitchStat,
	PlayerPoolType,
	PlayerPosition,
	RoundStatus,
} from "data/enums";
import type {ISelectOption, ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {SelectChangeEvent} from "@mui/material";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IModalsStore} from "data/stores/modals/modals.store";

export interface ILineupController extends ViewController {
	get i18n(): ILocalizationStore;
	get isLoading(): boolean;
	get lineupPlayers(): ILineupPlayers;
	get lineupSquads(): number[];
	get hasTeam(): boolean;
	get showSquads(): boolean;
	get pitchStatsOptions(): ISelectOption[];
	get selectedPitchStatOption(): string;
	get showBoosterButton(): boolean;
	get showStatFilter(): boolean;
	get isBoosterActive(): boolean;
	get isBoosterButtonDisabled(): boolean;
	get boosterButtonTooltip(): string;
	setSelectedFieldPosition: (e: React.SyntheticEvent<HTMLDivElement>) => void;
	setSquadPool: () => void;
	updateStatToShow: (e: SelectChangeEvent<unknown>) => void;
	openBoostersModal: () => void;
}

@injectable()
export class LineupController implements ILineupController {
	get pitchStatsOptions(): ISelectOption[] {
		if (!this._roundsStore.isSeasonStarted) {
			return this._teamBuilderStore.pitchStatsOptions.filter((option) => option.preseason);
		}
		return this._teamBuilderStore.pitchStatsOptions;
	}

	get selectedPitchStatOption(): string {
		return this._teamBuilderStore.selectedPitchStatOption;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._roundsStore.isRoundLoading;
	}

	get lineupPlayers(): ILineupPlayers {
		return this._teamStore.lineupPlayers;
	}

	get lineupSquads() {
		return this._teamStore.lineupSquads;
	}

	get hasTeam(): boolean {
		return this._teamStore.isTeamCreated;
	}

	get showSquads(): boolean {
		return (
			this.hasTeam ||
			[CreateTeamSteps.PickTeam, CreateTeamSteps.PickSquads].includes(
				this._teamStore.teamCreationStep.step
			)
		);
	}

	private get isComplete() {
		return this._roundsStore.selectedRound?.status === RoundStatus.Complete;
	}

	get showBoosterButton() {
		return this.hasTeam && !this.isComplete;
	}

	get showStatFilter() {
		return ![CreateTeamSteps.PickCaptain, CreateTeamSteps.PickViceCaptain].includes(
			this._teamStore.teamCreationStep.step
		);
	}

	get isBoosterActive() {
		return this._teamStore.team.maxCaptainEnabled;
	}

	private get isMaxCaptainUsed() {
		return this._teamStore.team.maxCaptainUsed && !this._teamStore.team.maxCaptainEnabled;
	}

	get isBoosterButtonDisabled() {
		return this.isComplete || this._teamStore.isTeamPlayerLocked || this.isMaxCaptainUsed;
	}

	get boosterButtonTooltip() {
		if (this.isMaxCaptainUsed) {
			return this.i18n.t("booster.tool_tip.used", "Max captain used");
		}
		if (this._teamStore.isTeamPlayerLocked) {
			return this.i18n.t("booster.tool_tip.locked", "Player is locked");
		}
		return "";
	}

	@action
	setSelectedFieldPosition = (e: React.SyntheticEvent<HTMLDivElement>) => {
		const {position} = e.currentTarget.dataset;
		this._teamBuilderStore.setSelectedFieldPosition(position as PlayerPosition);
		if (!this._teamBuilderStore.isPlayerPoolVisible) {
			this._teamBuilderStore.openPlayerPool();
		}
	};

	@action
	setSquadPool = () => {
		this._teamBuilderStore.playerPoolType = PlayerPoolType.Squad;
		if (!this._teamBuilderStore.isPlayerPoolVisible) {
			this._teamBuilderStore.openPlayerPool();
		}
	};

	@action
	updateStatToShow = (e: SelectChangeEvent<unknown>) => {
		const {value} = e.target;
		this._teamBuilderStore.selectedPitchStatOption = value as PlayerPitchStat;
	};

	@action
	openBoostersModal = () => {
		this._modalsStore.showModal(ModalType.BOOSTERS);
	};
}
