import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {type IPlayerProfileFantasyStatsController} from "views/components/modals/modal_player_profile/player_profile_fantasy_stats/player_profile_fantasy_stats.controller";
import {Bindings} from "data/constants/bindings";
import {Typography} from "@mui/material";
import {Preloader} from "views/components/preloader";
import {IPlayer} from "data/stores/players/players.store";
import {
	ModalProfileLoader,
	ModalProfileStat,
	ModalProfileStats,
} from "views/components/modals/modal_profile_styles";

interface IProps {
	player: IPlayer;
}

export const PlayerProfileFantasyStats: React.FC<IProps> = observer(({player}) => {
	const {i18n, playerStatsArray, playerStats} =
		useViewController<IPlayerProfileFantasyStatsController>(
			Bindings.PlayerProfileFantasyStatsController,
			{player}
		);

	if (!playerStats) {
		return (
			<ModalProfileLoader>
				<Preloader />
			</ModalProfileLoader>
		);
	}

	const {stats} = playerStats;

	return (
		<ModalProfileStats className={player.position}>
			{playerStatsArray.map((stat) => (
				<ModalProfileStat key={stat.val}>
					<Typography variant="body2" fontWeight={700}>
						{stats[stat.val as keyof typeof stats]}
					</Typography>
					<Typography variant="caption" fontSize={10}>
						{i18n.t(stat.label)}
					</Typography>
				</ModalProfileStat>
			))}
		</ModalProfileStats>
	);
});
