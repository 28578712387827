import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ITeamMessageStore} from "data/stores/team_message/team_message.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {PlayerPoolType, RoundStatus} from "data/enums";
import {some} from "lodash";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";

interface IParam {
	playerId: number;
}

export interface IPlayerProfileButtonsController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get player(): IPlayer | undefined;
	get isCaptain(): boolean;
	get isViceCaptain(): boolean;
	get isMaxCaptain(): boolean;
	get isInTeam(): boolean;
	get canAddToTeam(): boolean;
	get isDisabled(): boolean;
	get isCaptainDisabled(): boolean;
	get isViceCaptainDisabled(): boolean;
	get className(): string;
	addPlayer: () => void;
	removePlayer: () => void;
	selectCaptain: () => void;
	selectViceCaptain: () => void;
}

@injectable()
export class PlayerProfileButtonsController implements IPlayerProfileButtonsController {
	@observable private _playerId: number | null = null;

	get player(): IPlayer | undefined {
		if (!this._playerId) {
			return;
		}
		return this._playersStore.getPlayerById(this._playerId);
	}

	get isCaptain(): boolean {
		if (!this.player) {
			return false;
		}
		return (
			!this._teamStore.team.maxCaptainEnabled &&
			this._teamStore.team.captainId === this.player.id
		);
	}

	get isViceCaptain(): boolean {
		if (!this.player) {
			return false;
		}
		return (
			!this._teamStore.team.maxCaptainEnabled &&
			this._teamStore.team.viceCaptainId === this.player.id
		);
	}

	get isMaxCaptain(): boolean {
		if (!this.player) {
			return false;
		}
		return this._teamStore.getMaxCaptain(this._teamStore.team) === this.player.id;
	}

	get isInTeam() {
		if (!this.player) {
			return false;
		}

		return this._teamStore.lineupIDs.includes(this.player.id);
	}

	get canAddToTeam() {
		if (!this.player) {
			return false;
		}

		return this._teamStore.canAddPlayerToTeam(this.player);
	}

	get isComplete() {
		return this._roundsStore.selectedRound?.status === RoundStatus.Complete;
	}

	get isPlaying() {
		return this._roundsStore.selectedRound?.status === RoundStatus.Playing;
	}

	get isLocked() {
		if (!this.player) {
			return false;
		}
		return this.isPlaying && this.player.locked;
	}

	get isDisabled() {
		if (this.isComplete || this.isLocked) {
			return true;
		}

		if (this.isInTeam) {
			return false;
		}

		return some([!this.canAddToTeam, this._teamStore.isLineupFullFilled]);
	}

	get isCaptainLocked() {
		const captain = this._playersStore.getPlayerById(this._teamStore.team.captainId);
		return Boolean(captain?.locked);
	}

	get isCaptainDisabled() {
		if (this.isComplete || this.isLocked || this._teamStore.team.maxCaptainEnabled) {
			return true;
		}
		return this.isPlaying && this.isCaptainLocked;
	}

	get isViceCaptainLocked() {
		const viceCaptain = this._playersStore.getPlayerById(this._teamStore.team.viceCaptainId);
		return Boolean(viceCaptain?.locked);
	}

	get isViceCaptainDisabled() {
		if (this.isComplete || this.isLocked || this._teamStore.team.maxCaptainEnabled) {
			return true;
		}
		return this.isPlaying && this.isViceCaptainLocked;
	}

	get className(): string {
		if (this.isMaxCaptain) {
			return "max-captain";
		}
		if (this.isCaptain) {
			return "captain";
		}
		if (this.isViceCaptain) {
			return "vice-captain";
		}
		return "";
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.TeamMessageStore) private _teamMessageStore: ITeamMessageStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore
	) {
		makeAutoObservable(this);
	}

	init(param: IParam): void {
		this._playerId = param.playerId;
	}

	@action addPlayer = (): void => {
		if (!this.player) {
			return;
		}

		this._teamStore.addToTeam(this.player.id);
		this._teamMessageStore.setMessageToShow(
			this.i18n.t(
				"player_pool.message.player_added",
				"{{name}} has been added to your team",
				{name: this.player.displayName}
			)
		);
		this.close();
	};

	@action removePlayer = (): void => {
		if (!this.player) {
			return;
		}

		this._teamStore.removeFromTeam(this.player.id);
		this._teamBuilderStore.playerPoolType = PlayerPoolType.Player;
		this._teamBuilderStore.setSelectedFieldPosition(this.player.position);
		this.close();
	};

	@action selectCaptain = () => {
		if (!this.player) {
			return;
		}
		if (this.isViceCaptain) {
			this._teamStore.selectViceCaptain(0);
		}
		this._teamStore.selectCaptain(this.player.id);
		this.close();
	};

	@action selectViceCaptain = () => {
		if (!this.player) {
			return;
		}
		if (this.isCaptain) {
			this._teamStore.selectCaptain(0);
		}
		this._teamStore.selectViceCaptain(this.player.id);
		this.close();
	};

	close(): void {
		this._modalsStore.hideModal();
	}
}
