import React, {Fragment} from "react";
import {Header} from "views/components/header/header.component";
import {Footer} from "views/components/footer/footer.component";
import {Outlet} from "react-router-dom";
import {NativeAppInstallPrompt} from "views/components/native_app_install_prompt/native_app_install_prompt.component";

export const Layout: React.FC = () => {
	return (
		<Fragment>
			<Header />
			<NativeAppInstallPrompt />
			<Outlet />
			<Footer />
		</Fragment>
	);
};
