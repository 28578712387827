import {action, makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamMessageStore} from "data/stores/team_message/team_message.store";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import {ModalType, PlayerPitchStat, RoundStatus} from "data/enums";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {extractErrorMessage} from "data/utils";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ILiveScoresStore} from "data/stores/live_scores/live_scores.store";
import {delay} from "lodash";
import type {IUserStore} from "data/stores/user/user.store";

export interface ITeamMessageBarController extends ViewController {
	readonly i18n: ILocalizationStore;
	get message(): string | null;
	get selectedRound(): IRound | undefined;
	get hasPreviousRound(): boolean;
	get hasNextRound(): boolean;
	onTimerComplete: () => void;
	nextRound(): void;
	previousRound(): void;
}

@injectable()
export class TeamMessageBarController implements ITeamMessageBarController {
	get message() {
		return this._teamMessageStore.message;
	}

	get rounds() {
		if (!this._roundsStore.currentRound) {
			return this._roundsStore.completedAndActiveRounds;
		}
		const currentRound = this._roundsStore.currentRound;
		return this._roundsStore.completedAndActiveRounds
			.filter(
				(round) =>
					round.id !== currentRound.id && round.id >= this._teamStore.team.startRoundId
			)
			.concat(currentRound);
	}

	get selectedRound() {
		return this._roundsStore.selectedRound;
	}

	get selectedRoundIndex() {
		return this.rounds.findIndex((round) => round.id === this.selectedRound?.id);
	}

	get hasPreviousRound() {
		return this.selectedRoundIndex > 0 && this._userStore.isAuthorized;
	}

	get hasNextRound() {
		return this.selectedRoundIndex < this.rounds.length - 1 && this._userStore.isAuthorized;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.TeamMessageStore) private _teamMessageStore: ITeamMessageStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.LiveScoresStore) private _liveScoresStore: ILiveScoresStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	public onTimerComplete = () => {
		delay(() => {
			void this._roundsStore.fetchRounds();
		}, 5000);
	};

	@action private onSuccess = () => {
		this._roundsStore.isRoundLoading = false;
		if (this.selectedRound?.status === RoundStatus.Complete) {
			this._teamBuilderStore.selectedPitchStatOption = PlayerPitchStat.RoundPoints;
		} else {
			this._teamBuilderStore.selectedPitchStatOption = PlayerPitchStat.NextFixture;
		}
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._roundsStore.isRoundLoading = false;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action private updateRound(roundId: number) {
		this._roundsStore.selectedRoundId = roundId;
		this._roundsStore.isRoundLoading = true;
		Promise.all([
			this._teamStore.fetchTeamByRound(roundId),
			this._liveScoresStore.fetchLiveScores(roundId),
		])
			.then(this.onSuccess)
			.catch(this.onError);
	}

	@action public nextRound = () => {
		const round = this.rounds[this.selectedRoundIndex + 1];
		if (round) {
			this.updateRound(round.id);
		}
	};

	@action public previousRound = () => {
		const round = this.rounds[this.selectedRoundIndex - 1];
		if (round) {
			this.updateRound(round.id);
		}
	};
}
