import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ITeamMessageStore} from "data/stores/team_message/team_message.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {some} from "lodash";
import {PlayerPoolType, RoundStatus} from "data/enums";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";

interface IParam {
	squadId: number;
}

export interface ISquadProfileButtonsController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get isInTeam(): boolean;
	get canAddToTeam(): boolean;
	get isDisabled(): boolean;
	addSquad: () => void;
	removeSquad: () => void;
}

@injectable()
export class SquadProfileButtonsController implements ISquadProfileButtonsController {
	@observable private _squadId: number | null = null;

	get squad(): ISquad | undefined {
		if (!this._squadId) {
			return;
		}
		return this._squadsStore.getSquadById(this._squadId);
	}

	get isInTeam() {
		if (!this.squad) {
			return false;
		}

		return this._teamStore.lineupSquads.includes(this.squad.id);
	}

	get canAddToTeam() {
		if (!this.squad) {
			return false;
		}

		return this._teamStore.canAddSquadToTeam(this.squad);
	}

	get isComplete() {
		return this._roundsStore.selectedRound?.status === RoundStatus.Complete;
	}

	get isPlaying() {
		return this._roundsStore.selectedRound?.status === RoundStatus.Playing;
	}

	get isLocked() {
		if (!this.squad) {
			return false;
		}
		return this.isPlaying && this.squad.locked;
	}

	get isDisabled() {
		if (this.isComplete || this.isLocked) {
			return true;
		}

		if (this.isInTeam) {
			return false;
		}

		return some([!this.canAddToTeam, this._teamStore.isSquadsFullFilled]);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.TeamMessageStore) private _teamMessageStore: ITeamMessageStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore
	) {
		makeAutoObservable(this);
	}

	init(param: IParam): void {
		this._squadId = param.squadId;
	}

	@action addSquad = (): void => {
		if (!this.squad) {
			return;
		}

		this._teamStore.addSquadToTeam(this.squad.id);
		this._teamMessageStore.setMessageToShow(
			this.i18n.t("player_pool.message.club_added", "{{name}} have been added to your team", {
				name: this.squad.name,
			})
		);
		this.close();
	};

	@action removeSquad = (): void => {
		if (!this.squad) {
			return;
		}

		this._teamStore.removeSquadFromTeam(this.squad.id);
		this._teamBuilderStore.playerPoolType = PlayerPoolType.Squad;
		this.close();
	};

	close(): void {
		this._modalsStore.hideModal();
	}
}
