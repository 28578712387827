import React from "react";
import {Box} from "@mui/material";
import {NavLink, createSearchParams, useLocation} from "react-router-dom";
import {useViewController} from "data/services/locator";
import {INavigationController} from "views/components/header/navigation.controller";
import {Bindings} from "data/constants/bindings";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import {Exist} from "views/components/exist/exist.component";
import InfoIcon from "@mui/icons-material/Info";

const MenuListItem = styled(NavLink)`
	display: block;
	text-align: center;
	min-width: 94px;
	padding: 14px 16px;
	font-size: 14px;
	font-weight: 700;
	border-bottom: 2px solid transparent;
	transition: all 0.2s ease-in-out;

	&.active,
	&:hover {
		color: ${({theme}) => theme.palette.primary.main};
		border-color: ${({theme}) => theme.palette.secondary.main};
	}
`;

const TutorialListItem = styled(NavLink)`
	display: block;
	text-align: center;
	min-width: 94px;
	padding: 0 16px;
	font-size: 14px;
	font-weight: 700;
	transition: all 0.2s ease-in-out;
	margin-left: auto;
	display: flex;
	align-items: center;
	gap: 8px;

	&:hover {
		color: ${({theme}) => theme.palette.primary.main};
	}
`;

export const DesktopNavigation: React.FC = observer(() => {
	const {i18n, isAuthorized, isSeasonStarted, openTutorial} =
		useViewController<INavigationController>(Bindings.NavigationController);
	const location = useLocation();
	const leaguesLinkClass = ["/rankings", "/leagues", "/league"].some((path) =>
		location.pathname.startsWith(path)
	)
		? "active"
		: "";

	return (
		<Box display="flex">
			<Exist when={isAuthorized && isSeasonStarted}>
				<MenuListItem to="/">
					{i18n.t("navigation.option.dashboard", "Dashboard")}
				</MenuListItem>
			</Exist>
			<MenuListItem to="/team">{i18n.t("navigation.option.team", "My Team")}</MenuListItem>
			<Exist when={isAuthorized}>
				<MenuListItem to="/leagues" className={leaguesLinkClass}>
					{i18n.t("navigation.option.leagues", "Leagues")}
				</MenuListItem>
			</Exist>
			<MenuListItem to="/prizes">{i18n.t("navigation.option.prizes", "Prizes")}</MenuListItem>
			<MenuListItem to="/help">{i18n.t("navigation.option.help", "Help")}</MenuListItem>
			<TutorialListItem
				to={{pathname: "/team", search: createSearchParams({tutorial: "true"}).toString()}}
				onClick={openTutorial}>
				{i18n.t("navigation.option.tutorial", "Tutorial")}
				<InfoIcon />
			</TutorialListItem>
		</Box>
	);
});
