import {type ILineupPlayers} from "data/stores/team/team.store";
import {PlayerPosition} from "data/enums";
import {filter, find, identity, size, toInteger} from "lodash";
import {FORMATION_LIST} from "data/constants";

const {GK, DEF, MID, FWD} = PlayerPosition;
const FORMATION_POSITION = [GK, DEF, MID, FWD];

export const getAvailableFormation = (lineup: ILineupPlayers, position: PlayerPosition) => {
	const formationAllocation = FORMATION_POSITION.map((linePosition) => {
		const newPlayerPosition = linePosition === position ? 1 : 0;
		return size(filter(lineup[linePosition], identity)) + newPlayerPosition;
	});

	return find(FORMATION_LIST, (formationStr) =>
		formationStr
			.split("-")
			.map(toInteger)
			.every((maxPlayers, index) => formationAllocation[index] <= maxPlayers)
	);
};

export const hasEmptySpot = (lineup: ILineupPlayers, position: PlayerPosition) =>
	lineup[position].some((id) => !id);
