import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type ISquadProfileFantasyStatsController} from "views/components/modals/modal_squad_profile/squad_profile_fantasy_stats/squad_profile_fantasy_stats.controller";
import {ISquad} from "data/stores/squads/squads.store";
import {Preloader} from "views/components/preloader";
import {Typography} from "@mui/material";
import {
	ModalProfileLoader,
	ModalProfileStat,
	ModalProfileStats,
} from "views/components/modals/modal_profile_styles";

interface IProps {
	squad: ISquad;
}

export const SquadProfileFantasyStats: React.FC<IProps> = observer(({squad}) => {
	const {i18n, squadStatsArray, squadStats} =
		useViewController<ISquadProfileFantasyStatsController>(
			Bindings.SquadProfileFantasyStatsController,
			{squad}
		);

	if (!squadStats) {
		return (
			<ModalProfileLoader>
				<Preloader />
			</ModalProfileLoader>
		);
	}

	const {stats} = squadStats;

	return (
		<ModalProfileStats>
			{squadStatsArray.map((stat) => (
				<ModalProfileStat key={stat.val}>
					<Typography variant="body2">{stats[stat.val as keyof typeof stats]}</Typography>
					<Typography variant="caption" fontSize={10}>
						{i18n.t(stat.label)}
					</Typography>
				</ModalProfileStat>
			))}
		</ModalProfileStats>
	);
});
