import {action, makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";

export interface ISnackbarMessage {
	message: string;
	key: number;
}

export interface ISnackbarStore {
	get snackPack(): ISnackbarMessage[];
	set snackPack(value: ISnackbarMessage[]);
	get open(): boolean;
	set open(value: boolean);
	get messageInfo(): ISnackbarMessage | undefined;
	set messageInfo(value: ISnackbarMessage | undefined);

	enqueueSnackbar(message: string): void;
}

@injectable()
export class SnackbarStore implements ISnackbarStore {
	@observable private _snackPack: ISnackbarMessage[] = [];
	@observable private _open = false;
	@observable private _messageInfo: ISnackbarMessage | undefined;

	get snackPack() {
		return this._snackPack;
	}

	set snackPack(value: ISnackbarMessage[]) {
		this._snackPack = value;
	}

	get open() {
		return this._open;
	}

	set open(value: boolean) {
		this._open = value;
	}

	get messageInfo() {
		return this._messageInfo;
	}

	set messageInfo(value: ISnackbarMessage | undefined) {
		this._messageInfo = value;
	}

	constructor() {
		makeAutoObservable(this);
	}

	@action enqueueSnackbar(message: string) {
		const key = new Date().getTime();
		this.snackPack = [
			...this.snackPack,
			{
				message,
				key,
			},
		];
	}
}
