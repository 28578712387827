import {action, autorun, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ISnackbarMessage, ISnackbarStore} from "data/stores/snackbar/snackbar.store";

export interface ISnackbarController extends ViewController {
	get snackPack(): ISnackbarMessage[];
	get open(): boolean;
	get messageInfo(): ISnackbarMessage | undefined;

	handleClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
	handleExited: () => void;
}

@injectable()
export class SnackbarController implements ISnackbarController {
	@observable private _snackbarDisposer?: ReturnType<typeof autorun>;

	get snackPack() {
		return this._snackbarStore.snackPack;
	}

	get open() {
		return this._snackbarStore.open;
	}

	get messageInfo() {
		return this._snackbarStore.messageInfo;
	}

	constructor(@inject(Bindings.SnackbarStore) private _snackbarStore: ISnackbarStore) {
		makeAutoObservable(this);
	}

	@action init(param: void): void {
		this._snackbarDisposer = autorun(() => {
			if (this.snackPack.length && !this.messageInfo) {
				// Set a new snack when we don't have an active one
				this._snackbarStore.messageInfo = {...this.snackPack[0]};
				this._snackbarStore.snackPack = this.snackPack.slice(1);
				this._snackbarStore.open = true;
			} else if (this.snackPack.length && this.messageInfo && this.open) {
				// Close an active snack when a new one is added
				this._snackbarStore.open = false;
			}
		});
	}

	dispose() {
		this._snackbarDisposer?.();
	}

	@action handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === "clickaway") {
			return;
		}
		this._snackbarStore.open = false;
	};

	@action handleExited = () => {
		this._snackbarStore.messageInfo = undefined;
	};
}
