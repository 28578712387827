import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type ISquadProfileButtonsController} from "views/components/modals/modal_squad_profile/squad_profile_buttons/squad_profile_buttons.controller";
import {Exist} from "views/components/exist/exist.component";
import {Button} from "@mui/material";
import {ModalProfileButtons} from "views/components/modals/modal_profile_styles";

interface IProps {
	squadId: number;
}

export const SquadProfileButtons: React.FC<IProps> = observer(({squadId}) => {
	const {i18n, isInTeam, isDisabled, addSquad, removeSquad} =
		useViewController<ISquadProfileButtonsController>(Bindings.SquadProfileButtonsController, {
			squadId,
		});

	return (
		<ModalProfileButtons>
			<Exist when={isInTeam}>
				<Button
					variant="contained"
					color="primary"
					fullWidth
					onClick={removeSquad}
					disabled={isDisabled}>
					{i18n.t("squad_profile.buttons.remove", "Remove Club")}
				</Button>
			</Exist>
			<Exist when={!isInTeam}>
				<Button
					variant="contained"
					color="primary"
					fullWidth
					onClick={addSquad}
					disabled={isDisabled}>
					{i18n.t("squad_profile.buttons.add", "Add Club")}
				</Button>
			</Exist>
		</ModalProfileButtons>
	);
});
