import {PlayerPosition} from "data/enums";

export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const CONTENT_JSON_URL = process.env.REACT_APP_CONTENT_JSON_URL || "";
export const BASE_URL = process.env.REACT_APP_BASE_URL || "/";
export const SENTRY_DSN_URL = process.env.REACT_APP_SENTRY_DSN_URL;
export const SHARE_URL = process.env.REACT_APP_SHARE_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const SSO_LOGIN_URL = process.env.REACT_APP_SSO_LOGIN_URL || "";
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY || "";

export const EMAIL_REGEXP = "\\S+@\\S+\\.\\S+";
export const PASSWORD_PATTERN =
	"^((?=.*?\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[\\-!@#$%^&*\\(\\)_+\\|~=`\\{\\}\\[\\]:\";'<>?,.\\/])).{8,}$";

export const PASSWORD_REQUIREMENTS =
	"Password must include 8 characters, including 1 upper case character, 1 number and 1 special character";

export const FACEBOOK_ID = process.env.REACT_APP_FACEBOOK_APP_ID || false;

export const PLAYER_POSITIONS = [
	PlayerPosition.GK,
	PlayerPosition.DEF,
	PlayerPosition.MID,
	PlayerPosition.FWD,
] as const;

export const SPECIAL_SYMBOLS = {
	ä: "a",
	Ä: "A",
	ö: "o",
	Ö: "O",
	ü: "u",
	Ü: "U",
	á: "a",
	í: "i",
	é: "e",
	å: "a",
	ß: "b",
};

export const FORMATION_LIST = ["1-2-2-2", "1-2-3-1", "1-3-2-1"] as const;
