import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IMatchStats, ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import {Bindings} from "data/constants/bindings";
import type {IGame, IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalPayload, IModalsStore} from "data/stores/modals/modals.store";
import type {ILiveScoresStore} from "data/stores/live_scores/live_scores.store";
import {chain} from "lodash";
import {GameStatus} from "data/enums";

interface IMatchStat {
	val: () => number;
	label: string;
}

interface IParam {
	gameId: number;
	squadId: number;
}

export interface ISquadProfileMatchController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get stats(): IMatchStat[];
	get game(): IGame | undefined;
	get isScheduled(): boolean;
	get homeSquad(): ISquad | undefined;
	get awaySquad(): ISquad | undefined;
	get isExpanded(): boolean;
	get hasStats(): boolean;
	get matchStats(): IMatchStats | undefined;
	toggleDropdown: () => void;
}

@injectable()
export class SquadProfileMatchController implements ISquadProfileMatchController {
	@observable private _gameId: number | undefined;
	@observable private _squadId: number | undefined;
	@observable private _isExpanded = false;

	private _statsOptions: IMatchStat[] = [
		{
			val: () => (this.matchStats ? this.matchStats.win * 5 : 0),
			label: "squad_profile.results.win",
		},
		{
			val: () => (this.matchStats ? this.matchStats.draw * 3 : 0),
			label: "squad_profile.results.draw",
		},
		{
			val: () => (this.matchStats ? this.matchStats.awayWin * 2 : 0),
			label: "squad_profile.results.away_win",
		},
		{
			val: () => (this.matchStats ? this.matchStats.cleanSheet * 2 : 0),
			label: "squad_profile.results.clean_sheets",
		},
		{
			val: () => (this.matchStats && this.matchStats.goalsScored >= 2 ? 2 : 0),
			label: "squad_profile.results.two_plus_goals",
		},
	];

	get stats(): IMatchStat[] {
		return this._statsOptions.filter((stat) => stat.val() !== 0);
	}

	get game(): IGame | undefined {
		if (!this._gameId) {
			return;
		}
		return this._roundsStore.getGameById(this._gameId);
	}

	get isScheduled(): boolean {
		return this.game?.status === GameStatus.Scheduled;
	}

	get homeSquad(): ISquad | undefined {
		if (!this.game) {
			return;
		}
		return this._squadsStore.getSquadById(this.game.homeId);
	}

	get awaySquad(): ISquad | undefined {
		if (!this.game) {
			return;
		}
		return this._squadsStore.getSquadById(this.game.awayId);
	}

	get isExpanded(): boolean {
		return this._isExpanded;
	}

	get hasStats(): boolean {
		return this.stats.length > 0;
	}

	get matchStats(): IMatchStats | undefined {
		if (!this._gameId || !this._squadId) {
			return;
		}

		const roundId = this._roundsStore.currentRound?.id;
		if (!roundId) {
			return;
		}

		const liveStats = this._liveScoresStore.getSquadScoresByRound(roundId, this._squadId);
		const liveMatch = liveStats.find((stat) => stat.gameId === this._gameId);

		if (liveMatch) {
			return liveMatch;
		}

		const squadStats = this._squadsStore.getSquadStatsById(this._squadId);
		return chain(squadStats)
			.get("results", [] as IMatchStats[])
			.find((result) => result.gameId === this._gameId)
			.value();
	}

	protected get modalData(): IModalPayload | null {
		return this._modalsStore.modalContent;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LiveScoresStore) private _liveScoresStore: ILiveScoresStore
	) {
		makeAutoObservable(this);
	}

	init(param: IParam): void {
		this._gameId = param.gameId;
		this._squadId = param.squadId;
		if (this.modalData?.matchId === this._gameId) {
			this._isExpanded = this.stats.length > 0;
		}
	}

	toggleDropdown = (): void => {
		this._isExpanded = !this._isExpanded;
	};
}
