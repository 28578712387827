import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type INativeAppInstallPromptController} from "views/components/native_app_install_prompt/native_app_install_prompt.controller";
import {Box, Button, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import appIcon from "assets/img/logos/efl_badge.svg";

export const NativeAppInstallPrompt: React.FC = observer(() => {
	const {i18n, isVisible, hide, promptToInstall} =
		useViewController<INativeAppInstallPromptController>(
			Bindings.NativeAppInstallPromptController
		);

	if (!isVisible) {
		return null;
	}

	return (
		<Box
			p={1}
			display={{xs: "flex", md: "none"}}
			alignItems="center"
			justifyContent="space-between"
			gap={3}>
			<Box display="flex" alignItems="center" gap={1}>
				<IconButton onClick={hide}>
					<CloseIcon />
				</IconButton>

				<Box
					height={48}
					width={48}
					borderRadius={2}
					bgcolor="primary.main"
					display="flex"
					alignItems="center"
					justifyContent="center">
					<img src={appIcon} alt="App icon" width="32" />
				</Box>

				<Box flex={1}>
					<Typography variant="body2" fontWeight={700}>
						{i18n.t("app_prompt.install.title", "Fantasy EFL")}
					</Typography>
					<Typography variant="body2">
						{i18n.t(
							"app_prompt.install.description",
							"Install the Fantasy EFL app to get the best experience."
						)}
					</Typography>
				</Box>
			</Box>
			<Button variant="contained" onClick={promptToInstall}>
				{i18n.t("app_prompt.install.button", "Install")}
			</Button>
		</Box>
	);
});
