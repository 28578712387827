import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {PlayerPosition, PlayerStatus} from "data/enums";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {chain, forEach, get} from "lodash";
import {SPECIAL_SYMBOLS} from "data/constants";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";

export interface IPlayer {
	averagePoints: number;
	competitionId: number;
	displayName: string;
	firstName: string;
	id: number;
	lastName: string;
	locked: boolean;
	percentSelected: number;
	position: PlayerPosition;
	roundPoints: number | null;
	squadId: number;
	status: PlayerStatus;
	totalPoints: number;
}

export interface IStats {
	appearances: number;
	goalsScored: number;
	hatTricks: number;
	assists: number;
	saves: number;
	cleanSheets: number;
	goalsConceded: number;
	clearances: number;
	blocks: number;
	tackles: number;
	interceptions: number;
	keyPasses: number;
	shotsOnTarget: number;
}

export interface IMatchStats {
	gameId: number;
	squadId: number;
	roundId: number;
	points: number;
	minutesPlayed: number;
	goalsScored: number;
	hatTricks: number;
	assists: number;
	penaltyMisses: number;
	redCards: number;
	yellowCards: number;
	saves: number;
	penaltySaves: number;
	cleanSheet: number;
	goalsConceded: number;
	clearances: number;
	blocks: number;
	tackles: number;
	interceptions: number;
	keyPasses: number;
	shotsOnTarget: number;
}

export interface IPlayerStats {
	stats: IStats;
	results: IMatchStats[];
}

export interface IPlayersStore {
	get getIsLoading(): boolean;
	get list(): IPlayer[];
	get filteredPlayers(): IPlayer[];
	getPlayerById(playerId: number): IPlayer | undefined;
	getPlayerStatsById(playerId: number): IPlayerStats | undefined;
	fetchPlayers(): Promise<void>;
	fetchPlayerStats(playerId: number): Promise<void>;
}

@injectable()
export class PlayersStore implements IPlayersStore {
	@observable private _isLoading: boolean = false;
	@observable private _list: IPlayer[] = [];
	@observable private _playerStats: Record<number, IPlayerStats> = {};

	constructor(
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get list() {
		return this._list;
	}

	get filteredPlayers() {
		const {search, squad, position, competition, stat} = this._teamBuilderStore.filters;
		const players = this.list;

		return chain(players)
			.filter((player) => {
				return (
					this.isInName(search, player) &&
					this.isInSquad(squad, player) &&
					this.isInPosition(position, player) &&
					this.isInCompetition(competition, player) &&
					this.isPlayingInRound(player)
				);
			})
			.orderBy(
				[(player) => player[stat] ?? 0, "lastName", "firstName"],
				[this._teamBuilderStore.order]
			)
			.value();
	}

	get getIsLoading(): boolean {
		return this._isLoading;
	}

	getPlayerById(playerId: number): IPlayer | undefined {
		return this._list.find((player) => player.id === playerId);
	}

	getPlayerStatsById(playerId: number): IPlayerStats | undefined {
		return this._playerStats[playerId];
	}

	private isInSquad(squads: string[], player: IPlayer) {
		if (!squads.filter((val) => val !== "null").length) {
			return true;
		}

		return squads.includes(player.squadId.toString());
	}

	private isInName(searchValue: string, player: IPlayer): boolean {
		if (!searchValue.length) {
			return true;
		}
		let fullName = `${player.firstName} ${player.lastName}`.toLowerCase();
		let enteredName = searchValue.toLowerCase();

		forEach(SPECIAL_SYMBOLS, (item, key) => {
			fullName = fullName.replace(key, item);
			enteredName = enteredName.replace(key, item);
		});

		return fullName.includes(enteredName);
	}

	private isInPosition(positions: (PlayerPosition | "null")[], player: IPlayer) {
		if (!positions.filter((val) => val !== "null").length) {
			return true;
		}
		return positions.includes(player.position);
	}

	private isInCompetition(competitions: string[], player: IPlayer) {
		if (!competitions.filter((val) => val !== "null").length) {
			return true;
		}

		return competitions.includes(player.competitionId.toString());
	}

	private isPlayingInRound(player: IPlayer) {
		const roundId = get(this._roundsStore.currentRound, "id", 0);
		return this._roundsStore.isSquadPlayingInRound(player.squadId, roundId);
	}

	@action
	async fetchPlayers() {
		const {data} = await this._jsonProvider.players();

		runInAction(() => {
			this._list = data;
		});
	}

	@action
	async fetchPlayerStats(playerId: number) {
		const {data} = await this._jsonProvider.playerStats(playerId);

		if (!Array.isArray(data.results)) {
			throw new Error("Error while loading player info");
		}

		runInAction(() => {
			this._playerStats[playerId] = data;
		});
	}
}
