import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ITeamCreateStep, ITeamStore} from "data/stores/team/team.store";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {CreateTeamSteps, ModalType} from "data/enums";

export interface ITeamCreateStepsController extends ViewController {
	readonly i18n: ILocalizationStore;
	get teamCreationStep(): ITeamCreateStep;
	get stepsHidden(): boolean;
	get captainStepsHidden(): boolean;
	get className(): string;
	hideSteps: () => void;
	openTutorial: () => void;
}

@injectable()
export class TeamCreateStepsController implements ITeamCreateStepsController {
	@observable private _stepsHidden = Boolean(
		JSON.parse(localStorage.getItem("teamCreationStepsHidden") || "false")
	);
	@observable private _captainStepsHidden = Boolean(
		JSON.parse(localStorage.getItem("captainStepsHidden") || "false")
	);

	get stepsHidden() {
		return this._stepsHidden;
	}

	get captainStepsHidden() {
		return this._captainStepsHidden;
	}

	get teamCreationStep() {
		return this._teamStore.teamCreationStep;
	}

	private get isCaptainStep() {
		return [CreateTeamSteps.PickCaptain, CreateTeamSteps.PickViceCaptain].includes(
			this.teamCreationStep.step
		);
	}

	get className() {
		const isHidden = this.isCaptainStep ? this._captainStepsHidden : this._stepsHidden;
		return isHidden ? "hide" : "";
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	hideSteps = () => {
		if (this.isCaptainStep) {
			this._captainStepsHidden = true;
			localStorage.setItem("captainStepsHidden", "true");
		} else {
			this._stepsHidden = true;
			localStorage.setItem("teamCreationStepsHidden", "true");
		}
	};

	openTutorial = () => {
		this._modalsStore.showModal(ModalType.TUTORIAL);
	};
}
