import {action, computed, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ModalType, RequestState} from "data/enums";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import {chain, debounce, identity, isEqual, sortBy} from "lodash";
import {extractErrorMessage} from "data/utils";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {ChangeEvent, ReactNode} from "react";
import {IRegistrationPayload} from "data/providers/api/user.api.provider";
import {SelectChangeEvent} from "@mui/material";

interface IParam {
	pathname: string;
}

interface IForm extends HTMLFormElement {
	teamName: HTMLInputElement;
	teamSupportedId: HTMLInputElement;
	terms: HTMLInputElement;
	marketingOptIn: HTMLInputElement;
}

interface IFormValue {
	teamName: string;
	teamSupportedId: number | "";
	terms: boolean;
	marketingOptIn: boolean;
}

const defaultForm: IFormValue = {
	teamName: "",
	teamSupportedId: "",
	terms: false,
	marketingOptIn: false,
};

export interface IModalRegistrationController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;

	get isOpen(): boolean;
	get formValue(): IFormValue;
	get error(): string;
	get teamNameError(): string;
	get squads(): ISquad[];
	get isDisabled(): boolean;
	get isLoading(): boolean;
	get isCheckingTeamName(): boolean;
	get isTeamNameAvailable(): boolean;

	close: () => void;
	register: () => void;
	handleFormChange: (event: ChangeEvent<IForm>) => void;
	teamNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	supportedTeamChange: (event: SelectChangeEvent<unknown>, _: ReactNode) => void;
}

@injectable()
export class ModalRegistrationController implements IModalRegistrationController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _requestStateTeamName = RequestState.IDLE;
	@observable private _formValue: IFormValue = defaultForm;
	@observable private _error = "";
	@observable private _teamNameError = "";
	@observable private _isTeamNameAvailable = false;
	private _pathname: string = "";

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.SquadsStore) public _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return (
			this._modalsStore.modal === ModalType.REGISTRATION &&
			!this._userStore.isAuthorized &&
			!this._pathname.includes("/help")
		);
	}

	get formValue(): IFormValue {
		return this._formValue;
	}

	get error() {
		return this._error;
	}

	get teamNameError() {
		return this._teamNameError;
	}

	get isTeamNameAvailable(): boolean {
		return this._isTeamNameAvailable;
	}

	get squads(): ISquad[] {
		return sortBy(this._squadsStore.list, "name");
	}

	get isLoading(): boolean {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isCheckingTeamName(): boolean {
		return isEqual(this._requestStateTeamName, RequestState.PENDING);
	}

	private get isTeamNameValid() {
		return this._formValue.teamName.length >= 3 && this._formValue.teamName.length <= 40;
	}

	@computed private get isFormValid() {
		return (
			!this.teamNameError &&
			chain(this._formValue).omit("marketingOptIn").values().every(identity).value()
		);
	}

	get isDisabled(): boolean {
		return this.isLoading || this.isCheckingTeamName || !this.isFormValid;
	}

	init(param: IParam): void {
		this._pathname = param.pathname;
	}

	@action handleFormChange = (event: ChangeEvent<IForm>) => {
		const {terms, marketingOptIn} = event.currentTarget;

		this._formValue.terms = terms.checked;
		this._formValue.marketingOptIn = marketingOptIn.checked;
	};

	@action teamNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this._formValue.teamName = event.target.value;
		this._teamNameError = "";
		this._requestStateTeamName = RequestState.PENDING;
		this.checkTeamName();
	};

	@action supportedTeamChange = (event: SelectChangeEvent<unknown>, _: ReactNode) => {
		this._formValue.teamSupportedId = Number(event.target.value);
	};

	@action private checkTeamName = debounce(() => {
		if (!this.isTeamNameValid) {
			this._requestStateTeamName = RequestState.ERROR;
			this._teamNameError = this.i18n.t(
				"terms.modal.team_name_error_invalid",
				"Team name must be between 3 and 40 characters long"
			);
			return;
		}

		void this._userStore
			.checkTeamName(this._formValue.teamName)
			.then(() => {
				this._requestStateTeamName = RequestState.SUCCESS;
				this._isTeamNameAvailable = true;
			})
			.catch((error: AxiosError<IApiResponse>) => {
				this._requestStateTeamName = RequestState.ERROR;
				this._isTeamNameAvailable = false;
				this._teamNameError = extractErrorMessage(error);
			});
	}, 1000);

	public close = () => {
		this._modalsStore.hideModal();
	};

	private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
		this._modalsStore.hideModal();
	};

	private onError = (error: AxiosError<IApiResponse>) => {
		this._error = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};

	public register = () => {
		this._requestState = RequestState.PENDING;

		if (!this.isFormValid || !this._userStore.token) {
			this._requestState = RequestState.ERROR;
			this._error = this.i18n.t(
				"terms.modal.registration_error_invalid",
				"Token is missing or form is invalid"
			);
			return;
		}

		void this._userStore
			.register({...this._formValue, token: this._userStore.token} as IRegistrationPayload)
			.then(this.onSuccess)
			.catch(this.onError);
	};
}
