import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ITeam, ITeamStore} from "data/stores/team/team.store";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {CreateTeamSteps, ModalType, RequestState, RoundStatus} from "data/enums";
import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import {isEqual} from "lodash";

export interface ITeamInfoController extends ViewController {
	readonly i18n: ILocalizationStore;
	get team(): ITeam;
	get roundNumber(): number | undefined;
	get isLoading(): boolean;
	get isScheduled(): boolean;
	get isComplete(): boolean;
	get isRankingsVisible(): boolean;
	get isSaveTeamDisabled(): boolean;
	get isSaveTeamVisible(): boolean;
	get isClearTeamVisible(): boolean;
	get roundPoints(): number | null;
	get overallPoints(): number | null;
	saveTeam: () => void;
	clearLineup: () => void;
}

@injectable()
export class TeamInfoController implements ITeamInfoController {
	@observable private _requestState = RequestState.IDLE;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get team() {
		return this._teamStore.team;
	}

	get roundNumber() {
		return this._roundsStore.selectedRound?.id;
	}

	get isScheduled() {
		return this._roundsStore.selectedRound?.status === RoundStatus.Scheduled;
	}

	get isComplete() {
		return this._roundsStore.selectedRound?.status === RoundStatus.Complete;
	}

	get isRankingsVisible() {
		return this._teamStore.isTeamLive && !this.isScheduled;
	}

	get isSaveTeamDisabled() {
		return this.isLoading || !this._teamStore.isTeamChanged || !this._teamStore.isTeamComplete;
	}

	get isSaveTeamVisible() {
		return !this.isComplete;
	}

	get isClearTeamVisible() {
		return !this._teamStore.isTeamLive || this.isScheduled;
	}

	get roundPoints() {
		if (!this._teamStore.isTeamLive || this.isComplete) {
			return this.team.roundPoints;
		}
		return this._teamStore.liveTeamPoints;
	}

	get overallPoints() {
		if (!this._teamStore.isTeamLive || this.isComplete) {
			return this.team.overallPoints;
		}
		return this._teamStore.liveTotalPoints;
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
		this._teamStore.selectedStep = CreateTeamSteps.UpdateTeam;
		this._modalsStore.showModal(ModalType.TEAM_SAVED);
	};

	@action saveTeam = async () => {
		if (this._userStore.isAuthorized) {
			this._requestState = RequestState.PENDING;
			await this._teamStore.saveTeam().then(this.onSuccess).catch(this.onError);
		} else {
			this._modalsStore.showModal(ModalType.SAVE_TEAM);
		}
	};

	@action clearLineup = () => {
		this._teamStore.clearLineup();
	};
}
