import {makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import step0 from "assets/img/tutorial/desktop/step0.png";
import step1 from "assets/img/tutorial/desktop/step1.png";
import step2 from "assets/img/tutorial/desktop/step2.png";
import step3 from "assets/img/tutorial/desktop/step3.png";
import step4 from "assets/img/tutorial/desktop/step4.png";
import step5 from "assets/img/tutorial/desktop/step5.png";
import step6 from "assets/img/tutorial/desktop/step6.png";
import step8 from "assets/img/tutorial/desktop/step8.png";
import step9 from "assets/img/tutorial/desktop/step9.png";
import step11 from "assets/img/tutorial/desktop/step11.png";
import step12 from "assets/img/tutorial/desktop/step12.png";
import step13 from "assets/img/tutorial/desktop/step13.png";
import step0Mobile from "assets/img/tutorial/mobile/step0.png";
import step1Mobile from "assets/img/tutorial/mobile/step1.png";
import step2Mobile from "assets/img/tutorial/mobile/step2.png";
import step3Mobile from "assets/img/tutorial/mobile/step3.png";
import step4Mobile from "assets/img/tutorial/mobile/step4.png";
import step5Mobile from "assets/img/tutorial/mobile/step5.png";
import step6Mobile from "assets/img/tutorial/mobile/step6.png";
import step8Mobile from "assets/img/tutorial/mobile/step8.png";
import step9Mobile from "assets/img/tutorial/mobile/step9.png";
import step11Mobile from "assets/img/tutorial/mobile/step11.png";
import step12Mobile from "assets/img/tutorial/mobile/step12.png";
import step13Mobile from "assets/img/tutorial/mobile/step13.png";

interface IScreen {
	image: string;
	imageMobile: string;
	title: string;
	description: string;
}

export interface IModalTutorialController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isOpen(): boolean;
	get screens(): IScreen[];
	get currentScreen(): IScreen;
	get isStepOneFinalScreen(): boolean;
	get isFinalScreen(): boolean;
	close: () => void;
	onNext: () => void;
}

@injectable()
export class ModalTutorialController implements IModalTutorialController {
	@observable private _activeScreenIndex: number = 0;
	@observable private _tutorialDisposer?: ReturnType<typeof reaction>;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.TUTORIAL;
	}

	get screens(): IScreen[] {
		return [
			{
				image: step0,
				imageMobile: step0Mobile,
				title: this.i18n.t("tutorial.step_one.welcome_header"),
				description: this.i18n.t("tutorial.step_one.welcome_copy"),
			},
			{
				image: step1,
				imageMobile: step1Mobile,
				title: this.i18n.t("tutorial.step_one.players_header"),
				description: this.i18n.t("tutorial.step_one.players_copy"),
			},
			{
				image: step2,
				imageMobile: step2Mobile,
				title: this.i18n.t("tutorial.step_one.club_header"),
				description: this.i18n.t("tutorial.step_one.club_copy"),
			},
			{
				image: step3,
				imageMobile: step3Mobile,
				title: this.i18n.t("tutorial.step_one.captain_header"),
				description: this.i18n.t("tutorial.step_one.captain_copy"),
			},
			{
				image: step4,
				imageMobile: step4Mobile,
				title: this.i18n.t("tutorial.step_one.formation_header"),
				description: this.i18n.t("tutorial.step_one.formation_copy"),
			},
			{
				image: step5,
				imageMobile: step5Mobile,
				title: this.i18n.t("tutorial.step_one.new_team_header"),
				description: this.i18n.t("tutorial.step_one.new_team_copy"),
			},
			{
				image: step6,
				imageMobile: step6Mobile,
				title: this.i18n.t("tutorial.step_one.leaderboard_header"),
				description: this.i18n.t("tutorial.step_one.leaderboard_copy"),
			},
			{
				image: step5,
				imageMobile: step5Mobile,
				title: this.i18n.t("tutorial.step_one.more_header"),
				description: this.i18n.t("tutorial.step_one.more_copy"),
			},
			{
				image: step8,
				imageMobile: step8Mobile,
				title: this.i18n.t("tutorial.step_two.all_players_header"),
				description: this.i18n.t("tutorial.step_two.all_players_copy"),
			},
			{
				image: step9,
				imageMobile: step9Mobile,
				title: this.i18n.t("tutorial.step_two.goalkeepers_header"),
				description: this.i18n.t("tutorial.step_two.goalkeepers_copy"),
			},
			{
				image: step9,
				imageMobile: step9Mobile,
				title: this.i18n.t("tutorial.step_two.defenders_header"),
				description: this.i18n.t("tutorial.step_two.defenders_copy"),
			},
			{
				image: step11,
				imageMobile: step11Mobile,
				title: this.i18n.t("tutorial.step_two.midfielders_header"),
				description: this.i18n.t("tutorial.step_two.midfielders_copy"),
			},
			{
				image: step12,
				imageMobile: step12Mobile,
				title: this.i18n.t("tutorial.step_two.forwards_header"),
				description: this.i18n.t("tutorial.step_two.forwards_copy"),
			},
			{
				image: step13,
				imageMobile: step13Mobile,
				title: this.i18n.t("tutorial.step_two.club_header"),
				description: this.i18n.t("tutorial.step_two.club_copy"),
			},
			{
				image: step8,
				imageMobile: step8Mobile,
				title: this.i18n.t("tutorial.step_two.lockout_header"),
				description: this.i18n.t("tutorial.step_two.lockout_copy"),
			},
			{
				image: step0,
				imageMobile: step0Mobile,
				title: this.i18n.t("tutorial.step_two.close_header"),
				description: this.i18n.t("tutorial.step_two.close_copy"),
			},
		];
	}

	get currentScreen(): IScreen {
		return this.screens[this._activeScreenIndex] || this.screens[0];
	}

	get isStepOneFinalScreen(): boolean {
		return this._activeScreenIndex === 7;
	}

	get isFinalScreen(): boolean {
		return this._activeScreenIndex === this.screens.length - 1;
	}

	close = () => {
		this._modalsStore.hideModal();
	};

	onNext = () => {
		const nextIndex = this._activeScreenIndex + 1;
		if (this.screens[nextIndex]) {
			this._activeScreenIndex = nextIndex;
		}
	};

	init(): void {
		this._tutorialDisposer = reaction(
			() => this.isOpen,
			(isOpen) => {
				if (isOpen) {
					this._activeScreenIndex = 0;
				}
			}
		);
	}

	dispose() {
		this._tutorialDisposer?.();
	}
}
