import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";

export enum HowToScoreTab {
	AllPlayers,
	ByPosition,
	ClubScoring,
}

export interface IScoreItem {
	key: string;
	name: string;
	description?: {
		key: string;
		name: string;
	};
	value: number;
}

export interface IHowToScoreController extends ViewController {
	readonly i18n: ILocalizationStore;
	get activeTab(): HowToScoreTab;
	get scoreItems(): Record<string, IScoreItem[]>;
	handleChangeTab: (event: React.SyntheticEvent, newValue: HowToScoreTab) => void;
}

const goalsKey = "score.banner.goals";
const goalsName = "Goal Scored";

@injectable()
export class HowToScoreController implements IHowToScoreController {
	private _activeTab: HowToScoreTab = HowToScoreTab.AllPlayers;
	private _scoreItems: Record<string, IScoreItem[]> = {
		allPlayers: [
			{
				key: "score.banner.appearance",
				name: "Appearance",
				description: {
					key: "score.banner.under_60_minutes",
					name: "(0-59 minutes)",
				},
				value: 1,
			},
			{
				key: "score.banner.appearance",
				name: "Appearance",
				description: {
					key: "score.banner.60_minutes",
					name: "(60+ minutes)",
				},
				value: 1,
			},
			{
				key: "score.banner.assist",
				name: "Assist",
				value: 3,
			},
			{
				key: "score.banner.hattrick",
				name: "Hat-Trick (3 or more goals)",
				value: 5,
			},
			{
				key: "score.banner.missed_penalty",
				name: "Missed Penalty",
				value: -3,
			},
			{
				key: "score.banner.yellow_card",
				name: "Yellow Card",
				value: -1,
			},
			{
				key: "score.banner.red_card",
				name: "Red Card",
				value: -3,
			},
		],
		forwards: [
			{
				key: goalsKey,
				name: goalsName,
				value: 5,
			},
		],
		midForward: [
			{
				key: "score.banner.key_passes",
				name: "Every Key Pass",
				value: 2,
			},
			{
				key: "score.banner.shots_on_target",
				name: "Every 2 Shots On Target",
				value: 1,
			},
		],
		midfielders: [
			{
				key: "score.banner.interceptions",
				name: "Every Interception",
				value: 2,
			},
			{
				key: goalsKey,
				name: goalsName,
				value: 6,
			},
		],
		defenders: [
			{
				key: "score.banner.clearances",
				name: "Every 3 Clearances",
				value: 1,
			},
			{
				key: "score.banner.blocks",
				name: "Every 2 Blocks",
				value: 1,
			},
			{
				key: "score.banner.tackles",
				name: "Every 2 Successful Tackles",
				value: 1,
			},
		],
		gkDef: [
			{
				key: "score.banner.clean_sheet",
				name: "Clean Sheet",
				description: {
					key: "score.banner.60_minutes",
					name: "(60+ minutes)",
				},
				value: 5,
			},
			{
				key: goalsKey,
				name: goalsName,
				value: 7,
			},
			{
				key: "score.banner.goals_conceded",
				name: "Every 2 goals conceded",
				value: -1,
			},
		],
		goalkeepers: [
			{
				key: "score.banner.saves",
				name: "Every 3 Saves",
				value: 2,
			},
			{
				key: "score.banner.penalty_save",
				name: "Penalty Saved",
				value: 3,
			},
		],
		clubScoring: [
			{
				key: "club.scoring.win",
				name: "Win",
				value: 5,
			},
			{
				key: "club.scoring.draw",
				name: "Draw",
				value: 3,
			},
			{
				key: "club.scoring.away_win",
				name: "Away Win",
				value: 2,
			},
			{
				key: "club.scoring.clean_sheet",
				name: "Clean Sheet",
				value: 2,
			},
			{
				key: "club.scoring.two_goals",
				name: "2+ Goals Scored",
				value: 2,
			},
		],
	};

	get activeTab() {
		return this._activeTab;
	}

	get scoreItems() {
		return this._scoreItems;
	}

	constructor(@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore) {
		makeAutoObservable(this);
	}

	public handleChangeTab = (event: React.SyntheticEvent, newValue: HowToScoreTab) => {
		this._activeTab = newValue;
	};
}
