import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import {useLocation} from "react-router";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";

interface IParams {
	location: ReturnType<typeof useLocation>;
}

export interface INavigationController extends ViewController<IParams | void> {
	readonly i18n: ILocalizationStore;

	get drawerState(): boolean;
	get leaguesState(): boolean;
	get isAuthorized(): boolean;
	get isSeasonStarted(): boolean;

	toggleDrawer: () => void;
	toggleLeagues: () => void;
	openTutorial: () => void;
	logout: () => void;
}

@injectable()
export class NavigationController implements INavigationController {
	@observable _drawerState: boolean = false;
	@observable _leaguesState: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) readonly _userStore: IUserStore,
		@inject(Bindings.ModalsStore) readonly _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) readonly _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	get drawerState(): boolean {
		return this._drawerState;
	}

	get leaguesState(): boolean {
		return this._leaguesState;
	}

	get isSeasonStarted() {
		return this._roundsStore.isSeasonStarted;
	}

	toggleDrawer = () => {
		this._drawerState = !this._drawerState;
	};

	toggleLeagues = () => {
		this._leaguesState = !this._leaguesState;
	};

	openTutorial = () => {
		this._drawerState = false;
	};

	logout = () => {
		void this._userStore.logout();
	};

	onChange(param: IParams) {
		this._drawerState = false;
	}
}
