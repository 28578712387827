import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";

export interface IRegistrationPayload {
	token: string;
	teamName: string;
	teamSupportedId: number;
	terms: boolean;
	marketingOptIn: boolean;
}

export interface IPreRegistrationPayload {
	firstName: string;
	email: string;
	phone: string;
}

export interface IUpdateUserPayload {
	teamName?: string;
	emailsEnabled?: boolean;
	pushesEnabled?: boolean;
}

export interface ITeamName {
	teamName: string;
}

export type TUserResponse = IApiResponse<{user: IUser}>;

export interface IUserApiProvider {
	register: (params: IRegistrationPayload) => Promise<AxiosResponse<TUserResponse>>;
	preRegister: (params: IPreRegistrationPayload) => Promise<AxiosResponse<TUserResponse>>;
	update: (params: IUpdateUserPayload) => Promise<AxiosResponse<TUserResponse>>;
	user: () => Promise<AxiosResponse<TUserResponse>>;
	checkTeamName: (params: ITeamName) => Promise<AxiosResponse>;
	deactivateAccount: () => Promise<AxiosResponse>;
}

@injectable()
export class UserApiProvider implements IUserApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	checkTeamName = (params: ITeamName) => this._http.post<void>("user/check-team-name", params);

	deactivateAccount = () => this._http.post<void>("user/deactivate-account");

	register = (params: IRegistrationPayload) =>
		this._http.post<TUserResponse>("user/register", params);

	preRegister = (params: IPreRegistrationPayload) =>
		this._http.post<TUserResponse>("user/preregister", params);

	update = (params: IUpdateUserPayload) => this._http.post<TUserResponse>("user/update", params);

	user = () => this._http.get<TUserResponse>("user");
}
