import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {ICompetition, type ICompetitionsStore} from "data/stores/competitions/competitions.store";
import {type ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {isEmpty} from "lodash";

export interface IPlayerPoolLeagueFilterController extends ViewController {
	readonly i18n: ILocalizationStore;
	get competitions(): ICompetition[];

	setCompetition: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	isActive: (competitionId: number) => boolean;
}

@injectable()
export class PlayerPoolLeagueFilterController implements IPlayerPoolLeagueFilterController {
	get competitions(): ICompetition[] {
		return this._competitionsStore.list;
	}

	private get filterCompetitions(): string[] {
		return this._teamBuilderStore.filters.competition;
	}

	private get isFilterCompetitionsEmpty(): boolean {
		return isEmpty(this.filterCompetitions) || this.filterCompetitions.includes("null");
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.CompetitionsStore) private _competitionsStore: ICompetitionsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore
	) {
		makeAutoObservable(this);
	}

	public setCompetition = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		if (this.isFilterCompetitionsEmpty) {
			const competitionIds = this.competitions.map(({id}) => id.toString());
			this._teamBuilderStore.filters.competition = competitionIds;
		}
		this._teamBuilderStore.setCompetition(e);
	};

	public isActive = (competitionId: number) => {
		return (
			this.isFilterCompetitionsEmpty ||
			this.filterCompetitions.includes(competitionId.toString())
		);
	};
}
