import {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {ILineup, IOtherUserTeam, ITeam} from "data/stores/team/team.store";
import {inject, injectable} from "inversify";

interface ITeamPayload {
	roundId: number;
}

interface ITeamCreatePayload {
	formation: string;
	lineup: ILineup;
	captainId: number;
	viceCaptainId: number;
	maxCaptainEnabled?: boolean;
	modalShown?: boolean;
}

type TTeamResponse = IApiResponse<{team: ITeam | null}>;

interface IOtherUserTeamPayload {
	userId: number;
	roundId: number;
}

type TOtherUserTeamResponse = IApiResponse<{team: IOtherUserTeam | null}>;

export interface ITeamApiProvider {
	team: () => Promise<AxiosResponse<TTeamResponse>>;
	teamByRound: (params: ITeamPayload) => Promise<AxiosResponse<TTeamResponse>>;
	saveTeam: (params: ITeamCreatePayload) => Promise<AxiosResponse<TTeamResponse>>;
	otherUserTeam: (
		params: IOtherUserTeamPayload
	) => Promise<AxiosResponse<TOtherUserTeamResponse>>;
}

@injectable()
export class TeamApiProvider implements ITeamApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	team = () => this._http.get<TTeamResponse>("fantasy/team");
	teamByRound = ({roundId}: ITeamPayload) =>
		this._http.get<TTeamResponse>(`fantasy/team/${roundId}`);
	saveTeam = (params: ITeamCreatePayload) =>
		this._http.post<TTeamResponse>("fantasy/team", params);
	otherUserTeam = ({userId, roundId}: IOtherUserTeamPayload) =>
		this._http.get<TOtherUserTeamResponse>(`fantasy/other-user-team/${userId}/${roundId}`);
}
