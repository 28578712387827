import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {ModalType, RequestState} from "data/enums";
import {isEqual} from "lodash";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";

export interface ILeaguesSearchController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isLoading(): boolean;
	get searchValue(): string;
	onSearchLeagueForJoinChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class LeaguesSearchController implements ILeaguesSearchController {
	@observable searchTimer: ReturnType<typeof setTimeout> | undefined;
	@observable private _searchValue: string = "";
	@observable _requestState = RequestState.IDLE;

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get searchValue() {
		return this._searchValue;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action public onSearchLeagueForJoinChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		const {name, value} = event.currentTarget;

		if (!name) {
			return;
		}
		this._searchValue = value;

		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {
			this._leaguesStore
				.fetchLeaguesForJoin({
					search: this._searchValue,
				})
				.catch(this.onError);
		}, 500);
	};
}
