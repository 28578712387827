import {makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";

export interface ITeamMessageStore {
	get message(): string | null;
	setMessageToShow(message: string): void;
}

@injectable()
export class TeamMessageStore implements ITeamMessageStore {
	@observable private _message: string | null = null;
	@observable private _timeout?: ReturnType<typeof setTimeout> | null = null;

	get message() {
		return this._message;
	}

	constructor() {
		makeAutoObservable(this);
	}

	setMessageToShow(message: string) {
		this._message = message;

		if (this._timeout) {
			clearTimeout(this._timeout);
		}

		this._timeout = setTimeout(() => {
			this._message = null;
		}, 3000);
	}
}
