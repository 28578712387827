import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ISquad} from "data/stores/squads/squads.store";
import {ISquadProfileResultsController} from "views/components/modals/modal_squad_profile/squad_profile_results/squad_profile_results.controller";
import {
	ModalProfileResults,
	ModalProfileResultsScrollGradient,
} from "views/components/modals/modal_profile_styles";
import {SquadProfileMatch} from "./squad_profile_match/squad_profile_match.component";
import {Exist} from "views/components/exist/exist.component";
import {isEmpty} from "lodash";
import {Box, Typography} from "@mui/material";
import {Preloader} from "views/components/preloader";

interface IProps {
	squad: ISquad;
}

export const SquadProfileResults: React.FC<IProps> = observer(({squad}) => {
	const {i18n, results, fixtures, isLoading} = useViewController<ISquadProfileResultsController>(
		Bindings.SquadProfileResultsController,
		{
			squad,
		}
	);

	return (
		<Box position="relative" overflow="hidden">
			<ModalProfileResults>
				<Exist when={isLoading}>
					<Preloader />
				</Exist>
				<Exist when={!isEmpty(results)}>
					<Exist when={!isEmpty(fixtures)}>
						<Typography variant="caption" fontWeight={700}>
							{i18n.t("player_profile.matches.results", "Results")}
						</Typography>
					</Exist>
					{results.map((game, index) => (
						<SquadProfileMatch key={index} gameId={game.id} squadId={squad.id} />
					))}
				</Exist>
				<Exist when={!isEmpty(fixtures)}>
					<Exist when={!isEmpty(results)}>
						<Typography variant="caption" fontWeight={700}>
							{i18n.t("player_profile.matches.upcoming", "Fixtures")}
						</Typography>
					</Exist>
					{fixtures.map((game, index) => (
						<SquadProfileMatch key={index} gameId={game.id} squadId={squad.id} />
					))}
				</Exist>
			</ModalProfileResults>
			<ModalProfileResultsScrollGradient />
		</Box>
	);
});
