import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IMatchStats, IPlayersStore} from "data/stores/players/players.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import {Bindings} from "data/constants/bindings";
import type {IGame, IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {GameStatus, PlayerPosition} from "data/enums";
import type {IModalPayload, IModalsStore} from "data/stores/modals/modals.store";
import type {ILiveScoresStore} from "data/stores/live_scores/live_scores.store";
import {chain} from "lodash";

interface IMatchStat {
	stat: keyof IMatchStats;
	val: () => number;
	label: string;
	position: PlayerPosition[];
}

interface IParam {
	gameId: number;
	playerId: number;
	position: PlayerPosition;
}

export interface IPlayerProfileMatchController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get stats(): IMatchStat[];
	get game(): IGame | undefined;
	get isScheduled(): boolean;
	get homeSquad(): ISquad | undefined;
	get awaySquad(): ISquad | undefined;
	get isExpanded(): boolean;
	get hasStats(): boolean;
	get matchStats(): IMatchStats | undefined;
	toggleDropdown: () => void;
}

@injectable()
export class PlayerProfileMatchController implements IPlayerProfileMatchController {
	@observable private _gameId: number | undefined;
	@observable private _playerId: number | undefined;
	@observable private _position: PlayerPosition | null = null;
	@observable private _isExpanded = false;

	private _statsOptions: IMatchStat[] = [
		{
			stat: "minutesPlayed",
			val: () => {
				if (!this.matchStats || this.matchStats.minutesPlayed === 0) {
					return 0;
				}
				if (this.matchStats.minutesPlayed < 60) {
					return 1;
				}
				return 2;
			},
			label: "player_profile.results.minutes_played",
			position: [
				PlayerPosition.GK,
				PlayerPosition.DEF,
				PlayerPosition.MID,
				PlayerPosition.FWD,
			],
		},
		{
			stat: "assists",
			val: () => (this.matchStats ? this.matchStats.assists * 3 : 0),
			label: "player_profile.results.assists",
			position: [
				PlayerPosition.GK,
				PlayerPosition.DEF,
				PlayerPosition.MID,
				PlayerPosition.FWD,
			],
		},
		{
			stat: "goalsScored",
			val: () => {
				if (!this.matchStats || !this._position) {
					return 0;
				}
				if (this._position === PlayerPosition.FWD) {
					return this.matchStats.goalsScored * 5;
				}
				if (this._position === PlayerPosition.MID) {
					return this.matchStats.goalsScored * 6;
				}
				return this.matchStats.goalsScored * 7;
			},
			label: "player_profile.results.goals",
			position: [
				PlayerPosition.GK,
				PlayerPosition.DEF,
				PlayerPosition.MID,
				PlayerPosition.FWD,
			],
		},
		{
			stat: "hatTricks",
			val: () => (this.matchStats ? this.matchStats.hatTricks * 5 : 0),
			label: "player_profile.results.hat_tricks",
			position: [
				PlayerPosition.GK,
				PlayerPosition.DEF,
				PlayerPosition.MID,
				PlayerPosition.FWD,
			],
		},
		{
			stat: "penaltyMisses",
			val: () => (this.matchStats ? this.matchStats.penaltyMisses * -3 : 0),
			label: "player_profile.results.penalty_misses",
			position: [
				PlayerPosition.GK,
				PlayerPosition.DEF,
				PlayerPosition.MID,
				PlayerPosition.FWD,
			],
		},
		{
			stat: "yellowCards",
			val: () => (this.matchStats ? this.matchStats.yellowCards * -1 : 0),
			label: "player_profile.results.yellow_cards",
			position: [
				PlayerPosition.GK,
				PlayerPosition.DEF,
				PlayerPosition.MID,
				PlayerPosition.FWD,
			],
		},
		{
			stat: "redCards",
			val: () => (this.matchStats ? this.matchStats.redCards * -3 : 0),
			label: "player_profile.results.red_cards",
			position: [
				PlayerPosition.GK,
				PlayerPosition.DEF,
				PlayerPosition.MID,
				PlayerPosition.FWD,
			],
		},
		{
			stat: "saves",
			val: () => (this.matchStats ? Math.floor(this.matchStats.saves / 3) * 2 : 0),
			label: "player_profile.results.saves",
			position: [PlayerPosition.GK],
		},
		{
			stat: "penaltySaves",
			val: () => (this.matchStats ? this.matchStats.penaltySaves * 3 : 0),
			label: "player_profile.results.penalty_saves",
			position: [PlayerPosition.GK],
		},
		{
			stat: "cleanSheet",
			val: () => {
				if (!this.matchStats) {
					return 0;
				}
				return this.matchStats.minutesPlayed >= 60 ? this.matchStats.cleanSheet * 5 : 0;
			},
			label: "player_profile.results.clean_sheets",
			position: [PlayerPosition.GK, PlayerPosition.DEF],
		},
		{
			stat: "goalsConceded",
			val: () => (this.matchStats ? Math.floor(this.matchStats.goalsConceded / 2) * -1 : 0),
			label: "player_profile.results.goals_conceded",
			position: [PlayerPosition.GK, PlayerPosition.DEF],
		},
		{
			stat: "clearances",
			val: () => (this.matchStats ? Math.floor(this.matchStats.clearances / 3) : 0),
			label: "player_profile.results.clearances",
			position: [PlayerPosition.DEF],
		},
		{
			stat: "blocks",
			val: () => (this.matchStats ? Math.floor(this.matchStats.blocks / 2) : 0),
			label: "player_profile.results.blocks",
			position: [PlayerPosition.DEF],
		},
		{
			stat: "tackles",
			val: () => (this.matchStats ? Math.floor(this.matchStats.tackles / 2) : 0),
			label: "player_profile.results.tackles",
			position: [PlayerPosition.DEF],
		},
		{
			stat: "interceptions",
			val: () => (this.matchStats ? this.matchStats.interceptions * 2 : 0),
			label: "player_profile.results.interceptions",
			position: [PlayerPosition.MID],
		},
		{
			stat: "keyPasses",
			val: () => (this.matchStats ? Math.floor(this.matchStats.keyPasses / 2) : 0),
			label: "player_profile.results.key_passes",
			position: [PlayerPosition.MID, PlayerPosition.FWD],
		},
		{
			stat: "shotsOnTarget",
			val: () => (this.matchStats ? Math.floor(this.matchStats.shotsOnTarget / 2) : 0),
			label: "player_profile.results.shots_on_target",
			position: [PlayerPosition.MID, PlayerPosition.FWD],
		},
	];

	get stats(): IMatchStat[] {
		if (!this._position) {
			return [];
		}
		const position = this._position;
		return this._statsOptions.filter(
			(stat) => stat.val() !== 0 && stat.position.includes(position)
		);
	}

	get game(): IGame | undefined {
		if (!this._gameId) {
			return;
		}
		return this._roundsStore.getGameById(this._gameId);
	}

	get isScheduled(): boolean {
		return this.game?.status === GameStatus.Scheduled;
	}

	get homeSquad(): ISquad | undefined {
		if (!this.game) {
			return;
		}
		return this._squadsStore.getSquadById(this.game.homeId);
	}

	get awaySquad(): ISquad | undefined {
		if (!this.game) {
			return;
		}
		return this._squadsStore.getSquadById(this.game.awayId);
	}

	get isExpanded(): boolean {
		return this._isExpanded;
	}

	get hasStats(): boolean {
		return this.stats.length > 0;
	}

	get matchStats(): IMatchStats | undefined {
		if (!this._gameId || !this._playerId) {
			return;
		}

		const roundId = this._roundsStore.currentRound?.id;
		if (!roundId) {
			return;
		}

		const liveStats = this._liveScoresStore.getPlayerScoresByRound(roundId, this._playerId);
		const liveMatch = liveStats.find((stat) => stat.gameId === this._gameId);

		if (liveMatch) {
			return liveMatch;
		}

		const playerStats = this._playersStore.getPlayerStatsById(this._playerId);
		return chain(playerStats)
			.get("results", [] as IMatchStats[])
			.find((result) => result.gameId === this._gameId)
			.value();
	}

	protected get modalData(): IModalPayload | null {
		return this._modalsStore.modalContent;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.LiveScoresStore) private _liveScoresStore: ILiveScoresStore
	) {
		makeAutoObservable(this);
	}

	init(param: IParam): void {
		this._gameId = param.gameId;
		this._playerId = param.playerId;
		this._position = param.position;
		if (this.modalData?.matchId === this._gameId) {
			this._isExpanded = this.stats.length > 0;
		}
	}

	toggleDropdown = (): void => {
		this._isExpanded = !this._isExpanded;
	};
}
