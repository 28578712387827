import React, {Fragment} from "react";
import {ReactComponent as ChampionshipIcon} from "assets/img/icons/championship.svg";
import {ReactComponent as LeagueOneIcon} from "assets/img/icons/league_one.svg";
import {ReactComponent as LeagueTwoIcon} from "assets/img/icons/league_two.svg";
import championshipLogo from "assets/img/logos/championship.svg";
import leagueOneLogo from "assets/img/logos/league_one.svg";
import leagueTwoLogo from "assets/img/logos/league_two.svg";

interface IProps {
	id: number;
	size?: number;
	className?: string;
}

export const LeagueIcon: React.FC<IProps> = ({id, className, size}) => (
	<Fragment>
		{
			{
				10: <ChampionshipIcon className={className} height={size} width={size} />,
				11: <LeagueOneIcon className={className} height={size} width={size} />,
				12: <LeagueTwoIcon className={className} height={size} width={size} />,
			}[id]
		}
	</Fragment>
);

export const LeagueIconLarge: React.FC<IProps> = ({id, className, size}) => (
	<Fragment>
		{
			{
				10: (
					<img
						className={className}
						src={championshipLogo}
						alt="Championship"
						width={size}
					/>
				),
				11: <img className={className} src={leagueOneLogo} alt="League One" width={size} />,
				12: <img className={className} src={leagueTwoLogo} alt="League Two" width={size} />,
			}[id]
		}
	</Fragment>
);
