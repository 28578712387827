import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ISquad, ISquadStats, ISquadsStore} from "data/stores/squads/squads.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";

interface IStatOption {
	val: string;
	label: string;
}

interface IParam {
	squad: ISquad;
}

export interface ISquadProfileFantasyStatsController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get squadStats(): ISquadStats | undefined;
	get squadStatsArray(): IStatOption[];
}

@injectable()
export class SquadProfileFantasyStatsController implements ISquadProfileFantasyStatsController {
	@observable private _squad: ISquad | undefined;

	private _statsOptions = [
		{
			val: "goalsScored",
			label: "squad_profile.stat.goals",
		},
		{
			val: "goalsConceded",
			label: "squad_profile.stat.goals_conceded",
		},
		{
			val: "cleanSheets",
			label: "squad_profile.stat.clean_sheets",
		},
		{
			val: "twoGoalsGames",
			label: "squad_profile.stat.two_plus_goals",
		},
	];

	get squadStats(): ISquadStats | undefined {
		if (!this._squad) {
			return;
		}
		return this._squadsStore.getSquadStatsById(this._squad.id);
	}

	get squadStatsArray(): IStatOption[] {
		return this._statsOptions;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	init(param: IParam): void {
		this._squad = param.squad;
	}

	onChange(param: IParam): void {
		this._squad = param.squad;
	}
}
