import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";

interface IBeforeInstallPromptEvent extends Event {
	readonly platforms: string[];
	readonly userChoice: Promise<{
		outcome: "accepted" | "dismissed";
		platform: string;
	}>;
	prompt(): Promise<void>;
}

export interface INativeAppInstallPromptController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isVisible(): boolean;
	hide: () => void;
	promptToInstall: () => void;
}

@injectable()
export class NativeAppInstallPromptController implements INativeAppInstallPromptController {
	@observable private _isVisible = false;
	private prompt: IBeforeInstallPromptEvent | null = null;

	get isVisible(): boolean {
		return this._isVisible;
	}

	constructor(@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore) {
		makeAutoObservable(this);
	}

	public hide = (): void => {
		this._isVisible = false;
	};

	public promptToInstall = (): void => {
		// hide our user interface that shows our A2HS button
		this._isVisible = false;

		// Show the prompt
		if (this.prompt) {
			void this.prompt.prompt();

			// Wait for the user to respond to the prompt
			void this.prompt.userChoice.then((choice) => {
				if (choice.outcome === "accepted") {
					console.log("User accepted the A2HS prompt");
				} else {
					console.log("User dismissed the A2HS prompt");
				}
				this.prompt = null;
			});
		}
	};

	private ready = (e: IBeforeInstallPromptEvent): void => {
		// Prevent Chrome 67 and earlier from automatically showing the prompt
		e.preventDefault();

		// Stash the event so it can be triggered later.
		this.prompt = e;

		// Update UI notify the user they can add to home screen
		this._isVisible = true;
	};

	init(param: void): void {
		window.addEventListener("beforeinstallprompt", this.ready as EventListener);
	}

	dispose(): void {
		window.removeEventListener("beforeinstallprompt", this.ready as EventListener);
	}
}
