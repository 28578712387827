import React, {Fragment, PropsWithChildren} from "react";
import {PagePreloader} from "views/components/preloader";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import type {ISessionController} from "views/components/session/session.controller";
import {useSearchParams} from "react-router-dom";

export const Session: React.FC<PropsWithChildren> = observer(({children}) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const {isSessionChecked} = useViewController<ISessionController>(Bindings.SessionController, {
		searchParams,
		setSearchParams,
	});
	return isSessionChecked ? <Fragment>{children}</Fragment> : <PagePreloader />;
});
