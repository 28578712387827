import React, {lazy} from "react";
import {Route} from "react-router-dom";

import {retryFailLoad} from "data/utils";
import {NotAuthOnlyRoute, PrivateRoute} from "views/components/route";
import {Layout} from "views/components/layout/layout.component";

const Backdoor = lazy(retryFailLoad(() => import("views/pages/backdoor/backdoor.page")));
const MyAccount = lazy(retryFailLoad(() => import("views/pages/my_account/my_account.page")));
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const Prizes = lazy(retryFailLoad(() => import("views/pages/prizes/prizes.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));
const ComponentsReview = lazy(
	retryFailLoad(() => import("views/pages/components_review/components_review.page"))
);

const MyLeagues = lazy(retryFailLoad(() => import("views/pages/my_leagues/my_leagues.page")));
const JoinLeagues = lazy(retryFailLoad(() => import("views/pages/join_leagues/join_leagues.page")));
const JoinLeagueByCode = lazy(
	retryFailLoad(() => import("views/pages/join_league_by_code/join_league_by_code.page"))
);
const CreateLeague = lazy(
	retryFailLoad(() => import("views/pages/create_league/create_league.page"))
);
const League = lazy(retryFailLoad(() => import("views/pages/league/league.page")));
const LeagueSettings = lazy(
	retryFailLoad(() => import("views/pages/league_settings/league_settings.page"))
);
const LeagueManage = lazy(
	retryFailLoad(() => import("views/pages/league_manage/league_manage.page"))
);
const LeagueAbout = lazy(retryFailLoad(() => import("views/pages/league_about/league_about.page")));
const LeagueTable = lazy(retryFailLoad(() => import("views/pages/league_table/league_table.page")));
const Team = lazy(retryFailLoad(() => import("views/pages/team/team.page")));
const Rankings = lazy(retryFailLoad(() => import("views/pages/rankings/rankings.page")));
const Dashboard = lazy(retryFailLoad(() => import("views/pages/dashboard/dashboard.page")));

export const RootRoutes = (
	<Route element={<Layout />}>
		<Route path="/team" element={<Team />} />
		<Route path="/league/join/:code" element={<JoinLeagueByCode />} />
		<Route element={<NotAuthOnlyRoute />}>
			{process.env.REACT_APP_SENTRY_ENV !== "production" && (
				<Route path={"/backdoor"} element={<Backdoor />} />
			)}
		</Route>
		<Route element={<PrivateRoute />}>
			<Route path="/" element={<Dashboard />} />
			<Route path="/account" element={<MyAccount />} />
			<Route path="/rankings" element={<Rankings />} />
			<Route path="/leagues" element={<MyLeagues />} />
			<Route path="/leagues/join" element={<JoinLeagues />}>
				<Route index path=":code" element={<JoinLeagues />} />
			</Route>
			<Route path="/leagues/create" element={<CreateLeague />} />
			<Route path="/league/:leagueId" element={<League />}>
				<Route path="manage" element={<LeagueManage />} />
				<Route path="settings" element={<LeagueSettings />} />
				<Route path="about" element={<LeagueAbout />} />
				<Route path="ladder" element={<LeagueTable />} />
			</Route>
		</Route>
		<Route path="components" element={<ComponentsReview />} />
		<Route path="prizes" element={<Prizes />} />
		<Route path="help/*" element={<Help />} />
		<Route path="*" element={<NotFound />} />
	</Route>
);

export default RootRoutes;
