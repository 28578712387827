import React from "react";
import {observer} from "mobx-react";
import {Box, Button, IconButton, Modal, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import type {IModalErrorController} from "views/components/modals/modal_error/modal_error.controller";
import {Bindings} from "data/constants/bindings";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import {Exist} from "views/components/exist/exist.component";

const ModalContent = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	outline: none;
	max-width: 600px;
	width: 100%;
	background: #fff;
	padding: 20px;
	border-radius: 8px;
	text-align: center;

	@media screen and (max-width: 640px) {
		max-width: unset;
		left: 24px;
		right: 24px;
		width: auto;
		transform: translateY(-50%);
	}

	button {
		max-width: 300px;
	}
`;

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const Icon = styled.div`
	width: 64px;
	margin: auto;
	margin-bottom: 8px;

	svg {
		width: 100%;
		height: auto;
	}
`;

export const ModalError: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalErrorController>(
		Bindings.ModalErrorController
	);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<CloseBlock>
					<IconButton sx={{minWidth: 0}} onClick={close}>
						<CloseIcon />
					</IconButton>
				</CloseBlock>
				<Box maxWidth={360} margin="auto">
					<Icon>
						<ErrorIcon color="secondary" />
					</Icon>
					<Typography variant="h4" mb={1}>
						{i18n.t(modalContent?.title ?? "modal.error.title", "Error")}
					</Typography>
					<Exist when={modalContent?.message}>
						<Typography mb={2}>{modalContent?.message}</Typography>
					</Exist>
					<Button fullWidth variant="contained" onClick={close}>
						{i18n.t("modal.error.button", "OK")}
					</Button>
				</Box>
			</ModalContent>
		</Modal>
	);
});
