import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {Bindings} from "data/constants/bindings";
import {PlayerPoolType} from "data/enums";

export interface IPlayerPoolControlsController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isPlayerPoolType(): boolean;
	get selectedFiltersLength(): number;
	togglePlayerPoolType: () => void;
	toggleFilters: () => void;
}

@injectable()
export class PlayerPoolControlsController implements IPlayerPoolControlsController {
	get isPlayerPoolType(): boolean {
		return this._teamBuilderStore.playerPoolType === PlayerPoolType.Player;
	}

	get selectedFiltersLength(): number {
		return this._teamBuilderStore.selectedFiltersLength;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore
	) {
		makeAutoObservable(this);
	}

	public togglePlayerPoolType = () => {
		this._teamBuilderStore.playerPoolType = this.isPlayerPoolType
			? PlayerPoolType.Squad
			: PlayerPoolType.Player;
	};

	public toggleFilters = () => {
		this._teamBuilderStore.toggleFilters();
	};
}
