import React from "react";
import {CircularProgress} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

interface IProps {
	isValid: boolean;
	isInvalid: boolean;
	isLoading: boolean;
}

export const InputIcon: React.FC<IProps> = ({isValid, isInvalid, isLoading}) => {
	if (isLoading) {
		return <CircularProgress size={20} />;
	}

	if (isInvalid) {
		return <ErrorIcon color="error" />;
	}

	if (isValid) {
		return <CheckCircleIcon color="success" />;
	}

	return null;
};
