import {action, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalPayload, IModalsStore} from "data/stores/modals/modals.store";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {ModalType, PlayerPosition, ProfileTab} from "data/enums";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {ICompetition, ICompetitionsStore} from "data/stores/competitions/competitions.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import {isNumber} from "lodash";

export interface IModalPlayerProfileController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isOpen(): boolean;
	get player(): IPlayer | undefined;
	get position(): string;
	get squad(): ISquad | undefined;
	get competition(): ICompetition | undefined;
	get nextFixtures(): string[];
	get isCaptain(): boolean;
	get isViceCaptain(): boolean;
	get isMaxCaptain(): boolean;
	get className(): string;
	get activeTab(): ProfileTab;
	get isPreseason(): boolean;
	get hideButtons(): boolean;

	handleChangeTab: (event: React.SyntheticEvent, newValue: ProfileTab) => void;
	close: () => void;
}

@injectable()
export class ModalPlayerProfileController implements IModalPlayerProfileController {
	@observable private _activeTab = ProfileTab.Overview;
	@observable private _fetchStatsDisposer?: ReturnType<typeof reaction>;
	@observable private _openMatchDisposer?: ReturnType<typeof reaction>;
	@observable private _isNarrowScreen: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.PlayersStore) private readonly _playersStore: IPlayersStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.CompetitionsStore) private _competitionsStore: ICompetitionsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.PLAYER_PROFILE;
	}

	get player(): IPlayer | undefined {
		if (!this.modalData?.playerId) {
			return;
		}
		return this._playersStore.getPlayerById(this.modalData.playerId as number);
	}

	get position(): string {
		if (!this.player) {
			return "";
		}
		return {
			[PlayerPosition.GK]: this.i18n.t("player_profile.position.goalkeeper", "Goalkeeper"),
			[PlayerPosition.DEF]: this.i18n.t("player_profile.position.defender", "Defender"),
			[PlayerPosition.MID]: this.i18n.t("player_profile.position.midfielder", "Midfielder"),
			[PlayerPosition.FWD]: this.i18n.t("player_profile.position.forward", "Forward"),
		}[this.player.position];
	}

	get squad(): ISquad | undefined {
		if (!this.player) {
			return;
		}
		return this._squadsStore.getSquadById(this.player.squadId);
	}

	get competition(): ICompetition | undefined {
		if (!this.squad) {
			return;
		}
		return this._competitionsStore.getCompetitionById(this.squad.competitionId);
	}

	get nextFixtures(): string[] {
		if (!this.player) {
			return [];
		}
		const games = this._roundsStore.getNextFixtures(this.player.squadId, 3);
		return games.map((game) => {
			const location = game.homeId === this.player?.squadId ? "(H)" : "(A)";
			const opponentId = game.homeId === this.player?.squadId ? game.awayId : game.homeId;
			const opponent = this._squadsStore.getSquadById(opponentId);

			if (!opponent) {
				return "-";
			}

			const name = this._isNarrowScreen ? opponent.abbreviation : opponent.name;
			return `${name} ${location}`;
		});
	}

	get isCaptain(): boolean {
		if (!this.player) {
			return false;
		}
		return (
			!this._teamStore.team.maxCaptainEnabled &&
			this._teamStore.team.captainId === this.player.id
		);
	}

	get isViceCaptain(): boolean {
		if (!this.player) {
			return false;
		}
		return (
			!this._teamStore.team.maxCaptainEnabled &&
			this._teamStore.team.viceCaptainId === this.player.id
		);
	}

	get isMaxCaptain() {
		if (!this.player) {
			return false;
		}
		return this._teamStore.getMaxCaptain(this._teamStore.team) === this.player.id;
	}

	get className(): string {
		if (this.isMaxCaptain) {
			return "max-captain";
		}
		if (this.isCaptain) {
			return "captain";
		}
		if (this.isViceCaptain) {
			return "vice-captain";
		}
		return "";
	}

	get activeTab(): ProfileTab {
		return this._activeTab;
	}

	get isPreseason(): boolean {
		return !this._roundsStore.isSeasonStarted;
	}

	protected get modalData(): IModalPayload | null {
		return this._modalsStore.modalContent;
	}

	get hideButtons(): boolean {
		return (this.modalData?.hideButtons as boolean) ?? false;
	}

	private get narrowScreenWatcher() {
		return window.matchMedia("(max-width: 960px)");
	}

	@action private updateNarrowScreenFlag = () => {
		this._isNarrowScreen = this.narrowScreenWatcher.matches;
	};

	public handleChangeTab = (event: React.SyntheticEvent, newValue: ProfileTab) => {
		this._activeTab = newValue;
	};

	public close = () => {
		this._modalsStore.hideModal();
	};

	private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	init(): void {
		this.updateNarrowScreenFlag();
		this.narrowScreenWatcher.addEventListener("change", this.updateNarrowScreenFlag);

		this._openMatchDisposer = reaction(
			() => this.modalData,
			() => {
				if (!this.modalData) {
					return;
				}
				const matchId = this.modalData.matchId;
				if (isNumber(matchId)) {
					this._activeTab = ProfileTab.Results;
				} else {
					this._activeTab = ProfileTab.Overview;
				}
			}
		);

		this._fetchStatsDisposer = reaction(
			() => this.modalData,
			() => {
				if (!this.modalData) {
					return;
				}
				const playerId = this.modalData.playerId;
				if (isNumber(playerId)) {
					void this._playersStore.fetchPlayerStats(playerId).catch(this.onError);
				}
			}
		);
	}

	dispose() {
		this.narrowScreenWatcher.removeEventListener("change", this.updateNarrowScreenFlag);
		this._fetchStatsDisposer?.();
		this._openMatchDisposer?.();
	}
}
