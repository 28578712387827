import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILeague} from "data/stores/leagues/leagues.store";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {LeaguePrivacy, LeagueStatus, LeagueType, RankStatus} from "data/enums";
import {isNumber} from "lodash";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";

interface IParam {
	league: ILeague;
}

export interface ILeagueCardController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get isScoring(): boolean;
	get rankStatus(): RankStatus | null;
	get linkTab(): string;
	get squad(): ISquad | undefined;
	get label(): string;
}

@injectable()
export class LeagueCardController implements ILeagueCardController {
	@observable private _league: ILeague | null = null;

	get isScoring() {
		if (!this._league) {
			return false;
		}
		return this._league.status !== LeagueStatus.SCHEDULED;
	}

	get linkTab() {
		return this.isScoring ? "ladder" : "about";
	}

	get rankStatus() {
		if (!this._league) {
			return null;
		}

		const {rank, prevRank} = this._league;

		if (!isNumber(rank) || !isNumber(prevRank)) {
			return null;
		}

		if (rank === prevRank) {
			return RankStatus.SAME;
		}

		return prevRank > rank ? RankStatus.UP : RankStatus.DOWN;
	}

	get squad() {
		if (!this._league || !this._league.squadId) {
			return;
		}

		return this._squadsStore.getSquadById(this._league.squadId);
	}

	get label() {
		if (!this._league) {
			return "";
		}

		if (this._league.class === LeagueType.CLUB) {
			return this.i18n.t("league.label.club", "Club");
		}

		if (this._league.class === LeagueType.CELEBRITY) {
			return this.i18n.t("league.type.celebrity", "Celebrity");
		}

		if (this._league.privacy === LeaguePrivacy.PUBLIC) {
			return this.i18n.t("join_leagues.privacy.public", "Public");
		}

		return this.i18n.t("join_leagues.privacy.private", "Private");
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	@action init(param: IParam): void {
		this._league = param.league;
	}

	@action onChange(param: IParam): void {
		this._league = param.league;
	}
}
