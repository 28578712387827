import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {
	type IMyAccountController,
	MyAccountController,
} from "views/pages/my_account/my_account.controller";
import {API_URL, CONTENT_JSON_URL, JSON_URL} from "data/constants";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {RoundsStore, type IRoundsStore} from "data/stores/rounds/rounds.store";
import {PlayersStore, type IPlayersStore} from "data/stores/players/players.store";
import {SquadsStore, type ISquadsStore} from "data/stores/squads/squads.store";
import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secret_gate/secret_gate.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {
	type ILiveScoreController,
	LiveScoreController,
} from "views/controllers/live_score/live_score.controller";
import {ChecksumStore, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type IUserApiProvider, UserApiProvider} from "data/providers/api/user.api.provider";
import {
	type IPasswordApiProvider,
	PasswordApiProvider,
} from "data/providers/api/password.api.provider";
import {
	type ISessionController,
	SessionController,
} from "views/components/session/session.controller";
import {
	BootstrapController,
	type IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";
import {
	type ILeaguesApiProvider,
	LeaguesApiProvider,
} from "data/providers/api/leagues.api.provider";
import {type ILeaguesStore, LeaguesStore} from "data/stores/leagues/leagues.store";
import {
	type IMyLeaguesController,
	MyLeaguesController,
} from "views/pages/my_leagues/my_leagues.controller";
import {
	type IJoinLeaguesController,
	JoinLeaguesController,
} from "views/pages/join_leagues/join_leagues.controller";
import {
	type ICreateLeagueController,
	CreateLeagueController,
} from "views/pages/create_league/create_league.controller";
import {type ILeagueController, LeagueController} from "views/pages/league/league.controller";
import {
	type ILeagueInviteController,
	LeagueInviteController,
} from "views/pages/league_manage/league_manage.controller";
import {
	type ILeagueSettingsController,
	LeagueSettingsController,
} from "views/pages/league_settings/league_settings.controller";
import {
	type ILeagueAboutController,
	LeagueAboutController,
} from "views/pages/league_about/league_about.controller";
import {
	type ILeagueInviteFormController,
	LeagueInviteFormController,
} from "views/components/league_invite_form/league_invite_form.controller";
import {type IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {
	type IModalErrorController,
	ModalErrorController,
} from "views/components/modals/modal_error/modal_error.controller";
import {
	type IModalConfirmController,
	ModalConfirmController,
} from "views/components/modals/modal_confirm/modal_confirm.controller";
import {
	type ILocalizationController,
	LocalizationController,
} from "views/controllers/localization/localization.controller";
import {
	INavigationController,
	NavigationController,
} from "views/components/header/navigation.controller";
import {
	type ISnackbarController,
	SnackbarController,
} from "views/components/snackbar/snackbar.controller";
import {type ISnackbarStore, SnackbarStore} from "data/stores/snackbar/snackbar.store";
import {
	type ILeaguesSearchController,
	LeaguesSearchController,
} from "views/components/leagues_search/leagues_search.controller";
import {
	type ILeagueCardController,
	LeagueCardController,
} from "views/components/league_card/league_card.controller";
import {
	type IJoinLeaguesCardController,
	JoinLeaguesCardController,
} from "views/components/league_card/join_leagues_card/join_leagues_card.controller";
import {type ITeamController, TeamController} from "views/pages/team/team.controller";
import {type ITeamStore, TeamStore} from "data/stores/team/team.store";
import {
	type ILineupController,
	LineupController,
} from "views/components/team/lineup/lineup.controller";
import {
	type ITeamBuilderStore,
	TeamBuilderStore,
} from "data/stores/team_builder/team_builder.store";
import {
	type IPlayerPoolController,
	PlayerPoolController,
} from "views/components/team/player_pool/player_pool.controller";
import {
	type IPlayerPoolFiltersController,
	PlayerPoolFiltersController,
} from "views/components/team/player_pool/player_pool_players/player_pool_filters/player_pool_filters.controller";
import {
	type IPlayerPoolCardController,
	PlayerPoolCardController,
} from "views/components/team/player_pool/player_pool_players/player_pool_card/player_pool_card.controller";
import {type ITeamApiProvider, TeamApiProvider} from "data/providers/api/team.api.provider";
import {
	type IPlayerCardController,
	PlayerCardController,
} from "views/components/team/player_card/player_card.controller";
import {
	type ITeamCreateStepsController,
	TeamCreateStepsController,
} from "views/components/team/team_create_steps/team_create_steps.controller";
import {
	type IPlayerPoolSquadCardController,
	PlayerPoolSquadCardController,
} from "views/components/team/player_pool/player_pool_squads/player_pool_squad_card/player_pool_squad_card.controller";
import {
	type ISquadCardController,
	SquadCardController,
} from "views/components/team/squad_card/squad_card.controller";
import {
	type ITeamActionButtonsController,
	TeamActionButtonsController,
} from "views/components/team/team_action_buttons/team_action_buttons.controller";
import {
	type IPlayerPoolPlayersController,
	PlayerPoolPlayersController,
} from "views/components/team/player_pool/player_pool_players/player_pool_players.controller";
import {
	type IPlayerPoolSquadsController,
	PlayerPoolSquadsController,
} from "views/components/team/player_pool/player_pool_squads/player_pool_squads.controller";
import {
	type ICompetitionsStore,
	CompetitionsStore,
} from "data/stores/competitions/competitions.store";
import {
	type IPlayerPoolSearchController,
	PlayerPoolSearchController,
} from "views/components/team/player_pool/player_pool_search/player_pool_search.controller";
import {
	type ITeamMessageStore,
	TeamMessageStore,
} from "data/stores/team_message/team_message.store";
import {
	type ITeamMessageBarController,
	TeamMessageBarController,
} from "views/components/team/team_message_bar/team_message_bar.controller";
import {
	type IModalTeamSavedController,
	ModalTeamSavedController,
} from "views/components/modals/modal_team_saved/modal_team_saved.controller";
import {
	type IPlayerPoolSquadFiltersController,
	PlayerPoolSquadFiltersController,
} from "views/components/team/player_pool/player_pool_squads/player_pool_squad_filters/player_pool_squad_filters.controller";
import {
	type ILeagueNavigationController,
	LeagueNavigationController,
} from "views/components/league_navigation/league_navigation.controller";
import {
	type IModalPlayerProfileController,
	ModalPlayerProfileController,
} from "views/components/modals/modal_player_profile/modal_player_profile.controller";
import {
	type IPlayerProfileButtonsController,
	PlayerProfileButtonsController,
} from "views/components/modals/modal_player_profile/player_profile_buttons/player_profile_buttons.controller";
import {
	type IPlayerProfileOverviewController,
	PlayerProfileOverviewController,
} from "views/components/modals/modal_player_profile/player_profile_overview/player_profile_overview.controller";
import {
	type IPlayerProfileFantasyStatsController,
	PlayerProfileFantasyStatsController,
} from "views/components/modals/modal_player_profile/player_profile_fantasy_stats/player_profile_fantasy_stats.controller";
import {
	type IPlayerProfileMatchController,
	PlayerProfileMatchController,
} from "views/components/modals/modal_player_profile/player_profile_results/player_profile_match/player_profile_match.controller";
import {
	type IPlayerProfileResultsController,
	PlayerProfileResultsController,
} from "views/components/modals/modal_player_profile/player_profile_results/player_profile_results.controller";
import {
	type IBackdoorController,
	BackdoorController,
} from "views/pages/backdoor/backdoor.controller";
import {
	type IModalRegistrationController,
	ModalRegistrationController,
} from "views/components/modals/modal_registration/modal_registration.controller";
import {
	type IModalLeagueJoinController,
	ModalLeagueJoinController,
} from "views/components/modals/modal_league_join/modal_league_join.controller";
import {
	type ITeamInfoController,
	TeamInfoController,
} from "views/components/team/team_info/team_info.controller";
import {
	type IRankingsApiProvider,
	RankingsApiProvider,
} from "data/providers/api/rankings.api.provider";
import {type IRankingsStore, RankingsStore} from "data/stores/rankings/rankings.store";
import {
	type ILeagueTableController,
	LeagueTableController,
} from "views/pages/league_table/league_table.controller";
import {
	type IRankingsRowController,
	RankingsRowController,
} from "views/components/rankings_row/rankings_row.controller";
import {
	type IOtherUserTeamController,
	OtherUserTeamController,
} from "views/components/team/other_user_team/other_user_team.controller";
import {
	type IOtherUserPlayerCardController,
	OtherUserPlayerCardController,
} from "views/components/team/other_user_player_card/other_user_player_card.controller";
import {
	type IOtherUserSquadCardController,
	OtherUserSquadCardController,
} from "views/components/team/other_user_squad_card/other_user_squad_card.controller";
import {
	type IModalSaveTeamController,
	ModalSaveTeamController,
} from "views/components/modals/modal_save_team/modal_save_team.controller";
import {
	type IModalSquadProfileController,
	ModalSquadProfileController,
} from "views/components/modals/modal_squad_profile/modal_squad_profile.controller";
import {
	type ISquadProfileOverviewController,
	SquadProfileOverviewController,
} from "views/components/modals/modal_squad_profile/squad_profile_overview/squad_profile_overview.controller";
import {
	type ISquadProfileFantasyStatsController,
	SquadProfileFantasyStatsController,
} from "views/components/modals/modal_squad_profile/squad_profile_fantasy_stats/squad_profile_fantasy_stats.controller";
import {
	type ISquadProfileButtonsController,
	SquadProfileButtonsController,
} from "views/components/modals/modal_squad_profile/squad_profile_buttons/squad_profile_buttons.controller";
import {
	type ISquadProfileResultsController,
	SquadProfileResultsController,
} from "views/components/modals/modal_squad_profile/squad_profile_results/squad_profile_results.controller";
import {
	type ISquadProfileMatchController,
	SquadProfileMatchController,
} from "views/components/modals/modal_squad_profile/squad_profile_results/squad_profile_match/squad_profile_match.controller";
import {type ITimerController, TimerController} from "views/components/timer/timer.controller";
import {
	type IHowToScoreController,
	HowToScoreController,
} from "views/components/how_to_score/how_to_score.controller";
import {
	type IRankingsController,
	RankingsController,
} from "views/pages/rankings/rankings.controller";
import {
	type IModalTutorialController,
	ModalTutorialController,
} from "views/components/modals/modal_tutorial/modal_tutorial.controller";
import {type IPrizesController, PrizesController} from "views/pages/prizes/prizes.controller";
import {type ILiveScoresStore, LiveScoresStore} from "data/stores/live_scores/live_scores.store";
import {type ILandingController, LandingController} from "views/pages/landing/landing.controller";
import {type ILegendController, LegendController} from "views/components/legend/legend.controller";
import {
	type IPlayerPoolControlsController,
	PlayerPoolControlsController,
} from "views/components/team/player_pool/player_pool_controls/player_pool_controls.controller";
import {
	type IPlayerPoolLeagueFilterController,
	PlayerPoolLeagueFilterController,
} from "views/components/team/player_pool/player_pool_league_filter/player_pool_league_filter.controller";
import {
	type IDashboardController,
	DashboardController,
} from "views/pages/dashboard/dashboard.controller";
import {
	type IFixturesController,
	FixturesController,
} from "views/components/fixtures/fixtures.controller";
import {
	type IFixtureController,
	FixtureController,
} from "views/components/fixtures/fixture/fixture.controller";
import {
	type ILeagueFilterController,
	LeagueFilterController,
} from "views/components/fixtures/league_filter/league_filter.controller";
import {
	type IModalCaptainSelectController,
	ModalCaptainSelectController,
} from "views/components/modals/modal_captain_select/modal_captain_select.controller";
import {
	type IModalBoostersController,
	ModalBoostersController,
} from "views/components/modals/modal_boosters/modal_boosters.controller";
import {
	type IModalClubCapController,
	ModalClubCapController,
} from "views/components/modals/modal_club_cap/modal_club_cap.controller";
import {
	type IOverallRankingsCardController,
	OverallRankingsCardController,
} from "views/components/league_card/overall_rankings_card/overall_rankings_card.controller";
import {
	type INativeAppInstallPromptController,
	NativeAppInstallPromptController,
} from "views/components/native_app_install_prompt/native_app_install_prompt.controller";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IUserApiProvider>(Bindings.UserApiProvider).to(UserApiProvider);
	bind<IPasswordApiProvider>(Bindings.PasswordApiProvider).to(PasswordApiProvider);
	bind<ILeaguesApiProvider>(Bindings.LeaguesApiProvider).to(LeaguesApiProvider);
	bind<ITeamApiProvider>(Bindings.TeamApiProvider).to(TeamApiProvider);
	bind<IRankingsApiProvider>(Bindings.RankingsApiProvider).to(RankingsApiProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IRoundsStore>(Bindings.RoundsStore).to(RoundsStore).inSingletonScope();
	bind<IPlayersStore>(Bindings.PlayersStore).to(PlayersStore).inSingletonScope();
	bind<ISquadsStore>(Bindings.SquadsStore).to(SquadsStore).inSingletonScope();

	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore);
	bind<IStaticContentStore>(Bindings.StaticContentStore).to(StaticContentStore);

	bind<IChecksumStore>(Bindings.ChecksumStore).to(ChecksumStore);
	bind<ILeaguesStore>(Bindings.LeaguesStore).to(LeaguesStore).inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<ISnackbarStore>(Bindings.SnackbarStore).to(SnackbarStore).inSingletonScope();
	bind<ITeamStore>(Bindings.TeamStore).to(TeamStore).inSingletonScope();
	bind<ITeamBuilderStore>(Bindings.TeamBuilderStore).to(TeamBuilderStore).inSingletonScope();
	bind<ICompetitionsStore>(Bindings.CompetitionsStore).to(CompetitionsStore).inSingletonScope();
	bind<ITeamMessageStore>(Bindings.TeamMessageStore).to(TeamMessageStore).inSingletonScope();
	bind<IRankingsStore>(Bindings.RankingsStore).to(RankingsStore).inSingletonScope();
	bind<ILiveScoresStore>(Bindings.LiveScoresStore).to(LiveScoresStore).inSingletonScope();
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<IMyAccountController>(Bindings.MyAccountController).to(MyAccountController);
	bind<ISecretGateController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ILiveScoreController>(Bindings.LiveScoreController).to(LiveScoreController);
	bind<ISessionController>(Bindings.SessionController).to(SessionController);
	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);
	bind<IMyLeaguesController>(Bindings.MyLeaguesController).to(MyLeaguesController);
	bind<IJoinLeaguesController>(Bindings.JoinLeaguesController).to(JoinLeaguesController);
	bind<ICreateLeagueController>(Bindings.CreateLeagueController).to(CreateLeagueController);
	bind<ILeagueController>(Bindings.LeagueController).to(LeagueController);
	bind<ILeagueInviteController>(Bindings.LeagueInviteController).to(LeagueInviteController);
	bind<ILeagueSettingsController>(Bindings.LeagueSettingsController).to(LeagueSettingsController);
	bind<ILeagueAboutController>(Bindings.LeagueAboutController).to(LeagueAboutController);
	bind<ILeagueInviteFormController>(Bindings.LeagueInviteFormController).to(
		LeagueInviteFormController
	);
	bind<IModalErrorController>(Bindings.ModalErrorController).to(ModalErrorController);
	bind<IModalConfirmController>(Bindings.ModalConfirmController).to(ModalConfirmController);
	bind<ILocalizationController>(Bindings.LocalizationController).to(LocalizationController);
	bind<INavigationController>(Bindings.NavigationController).to(NavigationController);
	bind<ISnackbarController>(Bindings.SnackbarController).to(SnackbarController);
	bind<ILeaguesSearchController>(Bindings.LeaguesSearchController).to(LeaguesSearchController);
	bind<ILeagueCardController>(Bindings.LeagueCardController).to(LeagueCardController);
	bind<IJoinLeaguesCardController>(Bindings.JoinLeaguesCardController).to(
		JoinLeaguesCardController
	);
	bind<ITeamController>(Bindings.TeamController).to(TeamController);
	bind<ILineupController>(Bindings.LineupController).to(LineupController);
	bind<IPlayerPoolController>(Bindings.PlayerPoolController).to(PlayerPoolController);
	bind<IPlayerPoolFiltersController>(Bindings.PlayerPoolFiltersController).to(
		PlayerPoolFiltersController
	);
	bind<IPlayerPoolCardController>(Bindings.PlayerPoolCardController).to(PlayerPoolCardController);
	bind<IPlayerCardController>(Bindings.PlayerCardController).to(PlayerCardController);
	bind<ITeamCreateStepsController>(Bindings.TeamCreateStepsController).to(
		TeamCreateStepsController
	);
	bind<IPlayerPoolSquadCardController>(Bindings.PlayerPoolSquadCardController).to(
		PlayerPoolSquadCardController
	);
	bind<ISquadCardController>(Bindings.SquadCardController).to(SquadCardController);
	bind<ITeamActionButtonsController>(Bindings.TeamActionButtonsController).to(
		TeamActionButtonsController
	);
	bind<IPlayerPoolPlayersController>(Bindings.PlayerPoolPlayersController).to(
		PlayerPoolPlayersController
	);
	bind<IPlayerPoolSquadsController>(Bindings.PlayerPoolSquadsController).to(
		PlayerPoolSquadsController
	);
	bind<IPlayerPoolSearchController>(Bindings.PlayerPoolSearchController).to(
		PlayerPoolSearchController
	);
	bind<ITeamMessageBarController>(Bindings.TeamMessageBarController).to(TeamMessageBarController);
	bind<IModalTeamSavedController>(Bindings.ModalTeamSavedController).to(ModalTeamSavedController);
	bind<IPlayerPoolSquadFiltersController>(Bindings.PlayerPoolSquadFiltersController).to(
		PlayerPoolSquadFiltersController
	);
	bind<ILeagueNavigationController>(Bindings.LeagueNavigationController).to(
		LeagueNavigationController
	);
	bind<IModalPlayerProfileController>(Bindings.ModalPlayerProfileController).to(
		ModalPlayerProfileController
	);
	bind<IPlayerProfileButtonsController>(Bindings.PlayerProfileButtonsController).to(
		PlayerProfileButtonsController
	);
	bind<IPlayerProfileOverviewController>(Bindings.PlayerProfileOverviewController).to(
		PlayerProfileOverviewController
	);
	bind<IPlayerProfileFantasyStatsController>(Bindings.PlayerProfileFantasyStatsController).to(
		PlayerProfileFantasyStatsController
	);
	bind<IPlayerProfileResultsController>(Bindings.PlayerProfileResultsController).to(
		PlayerProfileResultsController
	);
	bind<IPlayerProfileMatchController>(Bindings.PlayerProfileMatchController).to(
		PlayerProfileMatchController
	);
	bind<IBackdoorController>(Bindings.BackdoorController).to(BackdoorController);
	bind<IModalRegistrationController>(Bindings.ModalRegistrationController).to(
		ModalRegistrationController
	);
	bind<IModalLeagueJoinController>(Bindings.ModalLeagueJoinController).to(
		ModalLeagueJoinController
	);
	bind<ITeamInfoController>(Bindings.TeamInfoController).to(TeamInfoController);
	bind<ILeagueTableController>(Bindings.LeagueTableController).to(LeagueTableController);
	bind<IRankingsRowController>(Bindings.RankingsRowController).to(RankingsRowController);
	bind<IOtherUserTeamController>(Bindings.OtherUserTeamController).to(OtherUserTeamController);
	bind<IOtherUserPlayerCardController>(Bindings.OtherUserPlayerCardController).to(
		OtherUserPlayerCardController
	);
	bind<IOtherUserSquadCardController>(Bindings.OtherUserSquadCardController).to(
		OtherUserSquadCardController
	);
	bind<IModalSaveTeamController>(Bindings.ModalSaveTeamController).to(ModalSaveTeamController);
	bind<IModalSquadProfileController>(Bindings.ModalSquadProfileController).to(
		ModalSquadProfileController
	);
	bind<ISquadProfileOverviewController>(Bindings.SquadProfileOverviewController).to(
		SquadProfileOverviewController
	);
	bind<ISquadProfileFantasyStatsController>(Bindings.SquadProfileFantasyStatsController).to(
		SquadProfileFantasyStatsController
	);
	bind<ISquadProfileButtonsController>(Bindings.SquadProfileButtonsController).to(
		SquadProfileButtonsController
	);
	bind<ISquadProfileResultsController>(Bindings.SquadProfileResultsController).to(
		SquadProfileResultsController
	);
	bind<ISquadProfileMatchController>(Bindings.SquadProfileMatchController).to(
		SquadProfileMatchController
	);
	bind<ITimerController>(Bindings.TimerController).to(TimerController);
	bind<IHowToScoreController>(Bindings.HowToScoreController).to(HowToScoreController);
	bind<IRankingsController>(Bindings.RankingsController).to(RankingsController);
	bind<IModalTutorialController>(Bindings.ModalTutorialController).to(ModalTutorialController);
	bind<IPrizesController>(Bindings.PrizesController).to(PrizesController);
	bind<ILandingController>(Bindings.LandingController).to(LandingController);
	bind<ILegendController>(Bindings.LegendController).to(LegendController);
	bind<IPlayerPoolControlsController>(Bindings.PlayerPoolControlsController).to(
		PlayerPoolControlsController
	);
	bind<IPlayerPoolLeagueFilterController>(Bindings.PlayerPoolLeagueFilterController).to(
		PlayerPoolLeagueFilterController
	);
	bind<IDashboardController>(Bindings.DashboardController).to(DashboardController);
	bind<IFixturesController>(Bindings.FixturesController).to(FixturesController);
	bind<IFixtureController>(Bindings.FixtureController).to(FixtureController);
	bind<ILeagueFilterController>(Bindings.LeagueFilterController).to(LeagueFilterController);
	bind<IModalCaptainSelectController>(Bindings.ModalCaptainSelectController).to(
		ModalCaptainSelectController
	);
	bind<IModalBoostersController>(Bindings.ModalBoostersController).to(ModalBoostersController);
	bind<IModalClubCapController>(Bindings.ModalClubCapController).to(ModalClubCapController);
	bind<IOverallRankingsCardController>(Bindings.OverallRankingsCardController).to(
		OverallRankingsCardController
	);
	bind<INativeAppInstallPromptController>(Bindings.NativeAppInstallPromptController).to(
		NativeAppInstallPromptController
	);
});
