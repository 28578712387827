import {makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IPlayersStore} from "data/stores/players/players.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";
import {CreateTeamSteps, ModalType, PlayerPoolStat} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {ITeam, ITeamStore} from "data/stores/team/team.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {ICompetitionsStore} from "data/stores/competitions/competitions.store";
import {SetURLSearchParams, useNavigate} from "react-router-dom";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import type {ILiveScoresStore} from "data/stores/live_scores/live_scores.store";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ISnackbarStore} from "data/stores/snackbar/snackbar.store";

interface IParam {
	searchParams: URLSearchParams;
	setSearchParams: SetURLSearchParams;
	navigate: ReturnType<typeof useNavigate>;
}

export interface ITeamController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get isLoaded(): boolean;
	get isTeamCreated(): boolean;
	get isCaptainSelect(): boolean;
	get isBlocking(): boolean;
}

@injectable()
export class TeamController implements ITeamController {
	@observable private _navigate?: ReturnType<typeof useNavigate>;
	@observable private _isLoaded: boolean = false;
	@observable private _saveTeamDisposer?: ReturnType<typeof reaction>;

	get isLoaded() {
		return this._isLoaded;
	}

	get isTeamCreated() {
		return this._teamStore.isTeamCreated;
	}

	get isCaptainSelect() {
		return (
			this._teamStore.selectedStep === CreateTeamSteps.PickCaptain ||
			this._teamStore.selectedStep === CreateTeamSteps.PickViceCaptain
		);
	}

	get isBlocking() {
		if (!this._isLoaded) {
			return false;
		}

		return this._teamStore.isBlockingNav;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.CompetitionsStore) private _competitionsStore: ICompetitionsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.LiveScoresStore) private _liveScoresStore: ILiveScoresStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.SnackbarStore) private _snackbarStore: ISnackbarStore
	) {
		makeAutoObservable(this);
	}

	private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	private onSuccess = () => {
		this._teamStore.selectedStep = CreateTeamSteps.UpdateTeam;

		const savedLeagueCode = localStorage.getItem("leagueToJoinCode");
		if (savedLeagueCode) {
			localStorage.removeItem("leagueToJoinCode");
			void this._leaguesStore
				.joinToLeague({code: savedLeagueCode})
				.then(this.onSuccessJoined)
				.catch(this.onError);
		} else {
			this._modalsStore.showModal(ModalType.TEAM_SAVED);
		}
	};

	private onSuccessJoined = () => {
		const league = this._leaguesStore.leagueToJoin;
		if (this._navigate && league) {
			this._snackbarStore.enqueueSnackbar(
				this.i18n.t(
					"league_join.notification.success",
					`Congratulations you have joined ${league.name}!`,
					{name: league.name}
				)
			);
			this._navigate(`/league/${league.id}/ladder`);
		}
	};

	async init({searchParams, setSearchParams, navigate}: IParam) {
		this._navigate = navigate;

		await Promise.all([
			this._roundsStore.fetchRounds(),
			this._squadsStore.fetchSquads(),
			this._playersStore.fetchPlayers(),
			this._competitionsStore.fetchCompetitions(),
		]).catch(this.onError);

		if (this._roundsStore.selectedRound) {
			await this._liveScoresStore
				.fetchLiveScores(this._roundsStore.selectedRound.id)
				.catch(this.onError);
		}

		if (this._roundsStore.isSeasonStarted) {
			this._teamBuilderStore.filters = {
				...this._teamBuilderStore.filters,
				stat: PlayerPoolStat.TotalPoints,
			};
		}

		if (this._userStore.isAuthorized) {
			await this._teamStore.fetchTeam().catch(this.onError);
		}

		const storedTeam = JSON.parse(localStorage.getItem("team") || "null") as ITeam | null;
		if (storedTeam) {
			this._teamStore.team = storedTeam;
		}

		this.checkShowTutorial(searchParams, setSearchParams);

		this.checkShowJoinLeague(searchParams, setSearchParams);

		this.checkPlayerToAdd(searchParams, setSearchParams);

		this.checkClubCapReached();

		this._saveTeamDisposer = reaction(
			() => this._userStore.isAuthorized,
			() => {
				if (this._userStore.isAuthorized && storedTeam) {
					localStorage.removeItem("team");
					this._teamStore.saveTeam().then(this.onSuccess).catch(this.onError);
				}
			},
			{fireImmediately: true}
		);

		runInAction(() => {
			this._isLoaded = true;
		});
	}

	onChange({searchParams, setSearchParams}: IParam): void {
		this.checkShowTutorial(searchParams, setSearchParams);
	}

	dispose(): void {
		this._saveTeamDisposer?.();
	}

	private checkShowTutorial = (
		searchParams: URLSearchParams,
		setSearchParams: SetURLSearchParams
	) => {
		const showTutorial = searchParams.get("tutorial") === "true";
		const leagueCode = searchParams.get("leagueCode");

		searchParams.delete("tutorial");
		setSearchParams(searchParams);

		if (showTutorial) {
			this._modalsStore.showModal(ModalType.TUTORIAL);
			return;
		}

		const isUserViewedTeamTutorial = JSON.parse(
			localStorage.getItem("isUserViewedTeamTutorial") || "false"
		) as boolean;

		if (!leagueCode && !isUserViewedTeamTutorial && !this.isTeamCreated) {
			this._modalsStore.showModal(ModalType.TUTORIAL);
			localStorage.setItem("isUserViewedTeamTutorial", "true");
		}
	};

	private checkShowJoinLeague = (
		searchParams: URLSearchParams,
		setSearchParams: SetURLSearchParams
	) => {
		const leagueCode = searchParams.get("leagueCode");

		searchParams.delete("leagueCode");
		setSearchParams(searchParams);

		if (leagueCode) {
			this._modalsStore.showModal(ModalType.LEAGUE_JOIN, {leagueCode});
			return;
		}

		const savedLeagueCode = localStorage.getItem("leagueToJoinCode");
		if (this.isTeamCreated && savedLeagueCode) {
			localStorage.removeItem("leagueToJoinCode");
			void this._leaguesStore
				.joinToLeague({code: savedLeagueCode})
				.then(this.onSuccessJoined)
				.catch(this.onError);
		}
	};

	private checkPlayerToAdd(searchParams: URLSearchParams, setSearchParams: SetURLSearchParams) {
		const playerId = searchParams.get("playerId");
		const intPlayerId = Number(playerId) || 0;

		const player = this._playersStore.getPlayerById(intPlayerId);
		if (!player) {
			return;
		}
		if (this._teamStore.isTeamCreated) {
			const message = `If you want to add ${player.displayName} to your team, remove someone from your team first!`;
			this._modalsStore.showModal(ModalType.ERROR, {message});
		} else if (this._teamStore.lineupIDs.includes(player.id)) {
			const message = `${player.displayName} is already in your team!`;
			this._modalsStore.showModal(ModalType.ERROR, {message});
		} else {
			this._teamStore.addToTeam(intPlayerId);
		}

		searchParams.delete("playerId");
		setSearchParams(searchParams);
	}

	private checkClubCapReached() {
		if (!this._teamStore.team.clubCapacityModal) {
			return;
		}

		if (this._teamStore.team.clubCapacityModal.length) {
			this._modalsStore.showModal(ModalType.CLUB_CAP);
		}
	}
}
