import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import {Bindings} from "data/constants/bindings";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {GameStatus, ModalType, PlayerPitchStat, RoundStatus} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {chain, isNull, isNumber} from "lodash";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ILiveScoresStore} from "data/stores/live_scores/live_scores.store";

interface IParam {
	squadId: number;
}

export interface ISquadCardController extends ViewController<IParam> {
	get squad(): ISquad | undefined;
	get isRoundComplete(): boolean;
	get isLocked(): boolean;
	get className(): string;
	get nextOpponents(): string;
	get displayStat(): string | number;

	handleClick: (e: React.SyntheticEvent<HTMLDivElement>) => void;
}

@injectable()
export class SquadCardController implements ISquadCardController {
	@observable private _squadId = 0;

	get squad() {
		return this._squadsStore.getSquadById(this._squadId);
	}

	get isRoundComplete() {
		return this._roundsStore.selectedRound?.status === RoundStatus.Complete;
	}

	get isRoundPlaying() {
		return this._roundsStore.selectedRound?.status === RoundStatus.Playing;
	}

	get isPlaying() {
		if (!this.squad) {
			return false;
		}
		return this.isRoundPlaying && this._roundsStore.isSquadPlaying(this.squad.id);
	}

	get isLocked() {
		if (!this.squad) {
			return false;
		}
		return this.isRoundPlaying && this.squad.locked;
	}

	get className() {
		if (this.isPlaying) {
			return "playing";
		}
		return "";
	}

	get nextOpponents() {
		if (!this._squadId) {
			return "-";
		}

		const roundOpponents = this._squadsStore.getRoundOpponents(this._squadId);

		if (roundOpponents.length === 1) {
			const homeOrAway = roundOpponents[0].game.awayId === this._squadId ? "(A)" : "(H)";
			return `vs ${roundOpponents[0].squad.abbreviation} ${homeOrAway}`;
		}

		const opponentAbbreviations = chain(roundOpponents)
			.map(({squad}) => squad.abbreviation)
			.join(", ")
			.value();

		return opponentAbbreviations ? `vs ${opponentAbbreviations}` : "-";
	}

	private getDisplayValue(value: number | null, suffix: string = "") {
		return isNumber(value) ? `${value}${suffix}` : "-";
	}

	private getRoundPoints(suffix: string = "") {
		if (!this._roundsStore.selectedRound) {
			return "-";
		}
		const points = this._teamStore.getSquadRoundPoints(
			this._squadId,
			this._roundsStore.selectedRound.id
		);
		return isNull(points) ? "-" : `${points}${suffix}`;
	}

	private getLivePoints(suffix: string = "") {
		if (!this._roundsStore.selectedRound || !this._squadId) {
			return "-";
		}

		const selectedRound = this._roundsStore.selectedRound;
		const roundOpponents = this._squadsStore.getRoundOpponents(this._squadId);

		if (!roundOpponents.map(({game}) => game.status).includes(GameStatus.Scheduled)) {
			return this.getRoundPoints(suffix);
		}

		return chain(roundOpponents)
			.map(({squad, game}) => {
				if (game.status === GameStatus.Scheduled) {
					return squad.abbreviation;
				}
				const points = this._teamStore.getSquadRoundPoints(
					this._squadId,
					selectedRound.id,
					game.id
				);
				return isNull(points) ? "-" : points;
			})
			.join(", ")
			.value();
	}

	get displayStat() {
		if (!this.squad) {
			return "";
		}

		if (this.isLocked) {
			return this.getLivePoints(" Pts");
		}

		if (this._teamBuilderStore.selectedPitchStatOption === PlayerPitchStat.NextFixture) {
			return this.nextOpponents;
		}

		const suffix =
			this._teamBuilderStore.selectedPitchStatOption === PlayerPitchStat.PercentSelected
				? "%"
				: " Pts";

		if (this._teamBuilderStore.selectedPitchStatOption === PlayerPitchStat.RoundPoints) {
			return this.getRoundPoints(suffix);
		}

		const value = this.squad[this._teamBuilderStore.selectedPitchStatOption];
		return this.getDisplayValue(value, suffix);
	}

	private get match() {
		if (!this._squadId) {
			return;
		}
		const match = this._roundsStore.getRoundFixture(this._squadId);
		if (!match) {
			return;
		}
		const isRoundPoints =
			this._teamBuilderStore.selectedPitchStatOption === PlayerPitchStat.RoundPoints;
		const isMatchComplete = match.status === GameStatus.Complete;
		const isMatchPlaying = match.status === GameStatus.Playing;
		const isMatchAvailable = isMatchComplete || isMatchPlaying;
		if ((isRoundPoints && isMatchAvailable) || isMatchPlaying) {
			return match;
		}
	}

	constructor(
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.LiveScoresStore) private _liveScoresStore: ILiveScoresStore
	) {
		makeAutoObservable(this);
	}

	@action handleClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
		if (!this._squadId) {
			return;
		}
		if (this.match) {
			this._modalsStore.showModal(ModalType.SQUAD_PROFILE, {
				squadId: this._squadId,
				matchId: this.match.id,
			});
			return;
		}
		this._modalsStore.showModal(ModalType.SQUAD_PROFILE, {squadId: this._squadId});
	};

	init(param: IParam): void {
		this._squadId = param.squadId;
	}

	onChange(param: IParam): void {
		this._squadId = param.squadId;
	}
}
