import React, {Fragment} from "react";
import {ReactComponent as PlayingIcon} from "assets/img/icons/playing.svg";
import {ReactComponent as InjuredIcon} from "assets/img/icons/injured.svg";
import {ReactComponent as SuspendedIcon} from "assets/img/icons/suspended.svg";
import {PlayerStatus} from "data/enums";

interface IProps {
	status: PlayerStatus;
	size?: number;
	className?: string;
}

export const PlayerStatusIcon: React.FC<IProps> = ({status, className, size}) => (
	<Fragment>
		{
			{
				[PlayerStatus.Playing]: (
					<PlayingIcon className={className} height={size} width={size} />
				),
				[PlayerStatus.Injured]: (
					<InjuredIcon className={className} height={size} width={size} />
				),
				[PlayerStatus.Suspended]: (
					<SuspendedIcon className={className} height={size} width={size} />
				),
				[PlayerStatus.Eliminated]: (
					<SuspendedIcon className={className} height={size} width={size} />
				),
			}[status]
		}
	</Fragment>
);
