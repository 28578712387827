import React from "react";
import {observer} from "mobx-react";
import logo from "assets/img/logos/efl.svg";
import styled from "@emotion/styled";
import {useMediaQuery, Container, Box, IconButton} from "@mui/material";
import {Exist} from "views/components/exist/exist.component";
import {NavLink} from "react-router-dom";
import {SecretGateController} from "views/controllers/secret_gate/secret_gate.controller";
import {DrawerNavigation} from "views/components/header/drawer_navigation/drawer_navigation.component";
import {DesktopNavigation} from "views/components/header/desktop_navigation/desktop_navigation.component";
import {css, useTheme} from "@emotion/react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {Bindings} from "data/constants/bindings";
import {useViewController} from "data/services/locator";
import {INavigationController} from "./navigation.controller";
import LoginIcon from "@mui/icons-material/Login";
import {getLoginUrl} from "data/utils";

const HeaderWrapper = styled(Box)`
	background: #fff;

	${({theme}) => theme.breakpoints.up("md")} {
		border-bottom: 1px solid #dedee1;
	}
`;

const linkStyles = css`
	display: block;
	text-align: center;
	min-width: 94px;
	padding: 0 16px;
	font-size: 14px;
	font-weight: 700;
	transition: all 0.2s ease-in-out;
	margin-left: auto;
	display: flex;
	align-items: center;
	gap: 8px;
`;

const LoginLink = styled.a`
	${linkStyles}
	&.active,
	&:hover {
		color: ${({theme}) => theme.palette.primary.main};
	}
`;

const AccountLink = styled(NavLink)`
	${linkStyles}
	&.active,
	&:hover {
		color: ${({theme}) => theme.palette.primary.main};
	}
`;

export const Header: React.FC = observer(() => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	const {i18n, isAuthorized} = useViewController<INavigationController>(
		Bindings.NavigationController
	);

	return (
		<HeaderWrapper>
			<Box p="8px 0">
				<Container>
					<Box display="flex" alignItems="center" justifyContent="space-between">
						<NavLink to={"/"}>
							<img src={logo} alt="EFL" width="200" />
						</NavLink>

						<Exist when={SecretGateController.IS_SECRET_PASSED}>
							<Exist when={!isMobile}>
								<Exist when={!isAuthorized}>
									<LoginLink href={getLoginUrl("/team")}>
										{i18n.t("navigation.option.login", "Login")}
										<AccountCircleIcon />
									</LoginLink>
								</Exist>
								<Exist when={isAuthorized}>
									<AccountLink to="/account">
										{i18n.t("navigation.option.account", "My Account")}
										<AccountCircleIcon />
									</AccountLink>
								</Exist>
							</Exist>

							<Exist when={isMobile}>
								<Box display="flex">
									<Exist when={!isAuthorized}>
										<IconButton
											sx={{color: "primary.dark"}}
											href={getLoginUrl("/team")}>
											<LoginIcon />
										</IconButton>
									</Exist>
									<DrawerNavigation />
								</Box>
							</Exist>
						</Exist>
					</Box>
				</Container>
			</Box>

			<Exist when={SecretGateController.IS_SECRET_PASSED}>
				<Exist when={!isMobile}>
					<Container>
						<DesktopNavigation />
					</Container>
				</Exist>
			</Exist>
		</HeaderWrapper>
	);
});
