import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IStaticContentStore, TArticle} from "data/stores/static_content/static_content.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {orderBy} from "lodash";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import {extractErrorMessage} from "data/utils";

export interface IPrizesController extends ViewController {
	readonly i18n: ILocalizationStore;
	get sections(): TArticle[];
}

@injectable()
export class PrizesController implements IPrizesController {
	get prizes() {
		return this._staticContentStore.sections.find((section) => section.name === "Prizes");
	}

	get contents() {
		return this._staticContentStore.articles;
	}

	get sections() {
		const sections =
			this.contents?.filter((article) => article.section_id === this.prizes?.id) ?? [];

		return orderBy(sections, ["position"], ["asc"]);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.StaticContentStore)
		private readonly _staticContentStore: IStaticContentStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	async init() {
		await this._staticContentStore.fetchStaticContent().catch(this.onError);
	}
}
