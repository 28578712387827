import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ChangeEvent, SyntheticEvent} from "react";
import {RequestState} from "data/enums";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {debounce, isEqual, some} from "lodash";
import {extractErrorMessage} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

interface IMyAccountFormElement extends HTMLFormElement {
	teamName: HTMLInputElement;
	emailsEnabled: HTMLInputElement;
	pushesEnabled: HTMLInputElement;
}

export interface IMyAccountController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isLoading(): boolean;
	get error(): Record<string, string> | null;
	get teamName(): string;
	get user(): IUser;
	get isUpdateDisabled(): boolean;
	get isCheckingTeamName(): boolean;
	get isTeamNameAvailable(): boolean;

	handleFormSubmit: (event: SyntheticEvent<IMyAccountFormElement>) => void;
	handleClearErrorOnChange: () => void;
	handleInputTeamNameValue: (event: ChangeEvent<HTMLInputElement>) => void;
	handleLogout: () => void;
}

@injectable()
export class MyAccountController implements IMyAccountController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _requestStateTeamName = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _isTeamNameAvailable = false;
	@observable private _teamName = "";
	@observable private _isFormChanged = false;

	get isUpdateDisabled() {
		return some([
			this.isLoading,
			this.isCheckingTeamName,
			!this._isFormChanged,
			this.error?.teamName,
		]);
	}

	get user() {
		return this._userStore.user!;
	}

	get teamName() {
		return this._teamName;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isTeamNameAvailable(): boolean {
		return this._isTeamNameAvailable;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isCheckingTeamName(): boolean {
		return isEqual(this._requestStateTeamName, RequestState.PENDING);
	}

	private get isTeamNameValid() {
		return this.teamName.length >= 3 && this.teamName.length <= 40;
	}

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
		this._teamName = this._userStore.user!.teamName;
	}

	@action handleInputTeamNameValue = (event: ChangeEvent<HTMLInputElement>) => {
		this._teamName = event.target.value.replace("@", "");
		this._requestStateTeamName = RequestState.PENDING;
		this.checkTeamName();
	};

	@action private checkTeamName = debounce(() => {
		if (!this.isTeamNameValid) {
			this._requestStateTeamName = RequestState.ERROR;
			this.reportError(
				this.i18n.t(
					"terms.modal.team_name_error_invalid",
					"Team name must be between 3 and 40 characters long"
				),
				"teamName"
			);
			return;
		}

		void this._userStore
			.checkTeamName(this.teamName)
			.then(() => {
				this._requestStateTeamName = RequestState.SUCCESS;
				this._isTeamNameAvailable = true;
			})
			.catch((error: AxiosError<IApiResponse>) => {
				this._requestStateTeamName = RequestState.ERROR;
				this._isTeamNameAvailable = false;
				this.reportError(extractErrorMessage(error), "teamName");
			});
	}, 1000);

	@action private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action public handleFormSubmit = (event: SyntheticEvent<IMyAccountFormElement>) => {
		event.preventDefault();

		const {teamName, emailsEnabled, pushesEnabled} = event.currentTarget;

		const validateList = [
			{field: teamName, error: "Please provide your team name", place: "teamName"},
		];

		const hasError = validateList.find(({field, error, place}) =>
			field.checkValidity() ? false : this.reportError(error, place)
		);

		if (hasError) {
			return;
		}

		const payload = {
			teamName: teamName.value,
			emailsEnabled: emailsEnabled.checked,
			pushesEnabled: pushesEnabled.checked,
		};

		this._requestState = RequestState.PENDING;
		this._userStore
			.update(payload)
			.then(this.onSuccess)
			.then(() =>
				runInAction(() => {
					this._isFormChanged = false;
				})
			)
			.catch(this.onError);
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};

	@action handleClearErrorOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._isFormChanged = true;
	};

	@action handleLogout = () => {
		this._requestState = RequestState.PENDING;
		void this._userStore.logout().then(this.onSuccess).catch(this.onError);
	};
}
