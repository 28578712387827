import React, {Fragment} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {
	Drawer,
	Box,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Container,
	Collapse,
} from "@mui/material";
import {NavLink, To, createSearchParams} from "react-router-dom";
import {CloseSharp, MenuSharp as MenuSharpIcon} from "@mui/icons-material";
import styled from "@emotion/styled";
import {INavigationController} from "views/components/header/navigation.controller";
import logo from "assets/img/logos/efl.svg";
import {useLocation} from "react-router";
import {Exist} from "views/components/exist/exist.component";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import {getLoginUrl} from "data/utils";

const MobileMenuHeader = styled(Box)`
	background: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 0;
`;

const MenuListItem = styled(ListItem)`
	background: #fff;
`;

MenuListItem.defaultProps = {
	disablePadding: true,
};

const SubMenuListItem = styled(ListItem)`
	background: ${({theme}) => theme.palette.primary.light};
`;

SubMenuListItem.defaultProps = {
	disablePadding: true,
};

interface IMenuListItemButtonProps {
	component?: React.ElementType;
	to?: To;
}

const MenuListItemButton = styled(ListItemButton)<IMenuListItemButtonProps>`
	padding: 12px 16px;
`;

MenuListItemButton.defaultProps = {
	LinkComponent: NavLink,
	component: NavLink,
};

const MenuListItemText = styled(ListItemText)``;

MenuListItemText.defaultProps = {
	primaryTypographyProps: {
		variant: "body2",
		fontWeight: 700,
	},
};

export const DrawerNavigation: React.FC = observer(() => {
	const location = useLocation();
	const {
		i18n,
		toggleDrawer,
		drawerState,
		toggleLeagues,
		leaguesState,
		isAuthorized,
		isSeasonStarted,
		logout,
		openTutorial,
	} = useViewController<INavigationController>(Bindings.NavigationController, {location});

	return (
		<Fragment>
			<IconButton sx={{color: "primary.dark"}} onClick={toggleDrawer}>
				<MenuSharpIcon />
			</IconButton>

			<Drawer
				onClose={toggleDrawer}
				anchor={"left"}
				open={drawerState}
				PaperProps={{
					sx: (theme) => ({
						background: theme.palette.neutral.light,
						display: "flex",
						flexDirection: "column",
						borderRadius: 0,
					}),
				}}>
				<Box
					sx={{
						width: "100vw",
						flex: 1,
						display: "flex",
						flexDirection: "column",
					}}>
					<MobileMenuHeader>
						<Container>
							<Box display="flex" alignItems="center" justifyContent="space-between">
								<NavLink to={"/"}>
									<img src={logo} alt="EFL" width="200" />
								</NavLink>

								<IconButton sx={{color: "primary.dark"}} onClick={toggleDrawer}>
									<CloseSharp />
								</IconButton>
							</Box>
						</Container>
					</MobileMenuHeader>

					<List
						disablePadding
						sx={{flex: 1, display: "flex", flexDirection: "column", gap: "1px"}}>
						<Exist when={isAuthorized && isSeasonStarted}>
							<MenuListItem>
								<MenuListItemButton to={"/"}>
									<MenuListItemText
										primary={i18n.t("navigation.option.dashboard", "Dashboard")}
									/>
								</MenuListItemButton>
							</MenuListItem>
						</Exist>
						<MenuListItem>
							<MenuListItemButton to={"/team"}>
								<MenuListItemText
									primary={i18n.t("navigation.option.team", "My Team")}
								/>
							</MenuListItemButton>
						</MenuListItem>
						<Exist when={isAuthorized}>
							<ListItem
								disablePadding
								sx={{
									transition: "all 0.2s ease-in-out",
									backgroundColor: leaguesState ? "primary.main" : "#FFF",
									color: leaguesState ? "#FFF" : "text.primary",
								}}>
								<ListItemButton sx={{p: "12px 16px"}} onClick={toggleLeagues}>
									<MenuListItemText
										primary={i18n.t("navigation.option.leagues", "Leagues")}
									/>
									{leaguesState ? <ExpandLess /> : <ExpandMore />}
								</ListItemButton>
							</ListItem>
							<Collapse in={leaguesState} timeout="auto" unmountOnExit>
								<List
									disablePadding
									sx={{display: "flex", flexDirection: "column", gap: "1px"}}>
									<SubMenuListItem>
										<MenuListItemButton
											to={"/leagues"}
											sx={{pl: "32px", color: "#FFF"}}>
											<MenuListItemText
												primary={i18n.t(
													"navigation.option.my_leagues",
													"My Leagues"
												)}
											/>
										</MenuListItemButton>
									</SubMenuListItem>
									<SubMenuListItem>
										<MenuListItemButton
											to={"/leagues/create"}
											sx={{pl: "32px", color: "#FFF"}}>
											<MenuListItemText
												primary={i18n.t(
													"navigation.option.create_league",
													"Create a League"
												)}
											/>
										</MenuListItemButton>
									</SubMenuListItem>
									<SubMenuListItem>
										<MenuListItemButton
											to={"/leagues/join"}
											sx={{pl: "32px", color: "#FFF"}}>
											<MenuListItemText
												primary={i18n.t(
													"navigation.option.join_league",
													"Join a League"
												)}
											/>
										</MenuListItemButton>
									</SubMenuListItem>
								</List>
							</Collapse>
						</Exist>
						<MenuListItem>
							<MenuListItemButton to={"/prizes"}>
								<MenuListItemText
									primary={i18n.t("navigation.option.prizes", "Prizes")}
								/>
							</MenuListItemButton>
						</MenuListItem>
						<MenuListItem>
							<MenuListItemButton to={"/help"}>
								<MenuListItemText
									primary={i18n.t("navigation.option.help", "Help")}
								/>
							</MenuListItemButton>
						</MenuListItem>
						<MenuListItem>
							<MenuListItemButton
								to={{
									pathname: "/team",
									search: createSearchParams({tutorial: "true"}).toString(),
								}}
								onClick={openTutorial}>
								<MenuListItemText
									primary={i18n.t("navigation.option.tutorial", "Tutorial")}
								/>
							</MenuListItemButton>
						</MenuListItem>
						<Exist when={isAuthorized}>
							<MenuListItem sx={{mt: "auto"}} secondaryAction={<AccountCircleIcon />}>
								<MenuListItemButton to={"/account"}>
									<MenuListItemText
										primary={i18n.t("navigation.option.account", "My Account")}
									/>
								</MenuListItemButton>
							</MenuListItem>
							<MenuListItem secondaryAction={<LogoutIcon />}>
								<ListItemButton sx={{p: "12px 16px"}} onClick={logout}>
									<MenuListItemText
										primary={i18n.t("navigation.option.logout", "Log Out")}
									/>
								</ListItemButton>
							</MenuListItem>
						</Exist>
						<Exist when={!isAuthorized}>
							<MenuListItem sx={{mt: "auto"}} secondaryAction={<LoginIcon />}>
								<ListItemButton sx={{p: "12px 16px"}} href={getLoginUrl("/team")}>
									<MenuListItemText
										primary={i18n.t("navigation.option.login", "Log In")}
									/>
								</ListItemButton>
							</MenuListItem>
						</Exist>
					</List>
				</Box>
			</Drawer>
		</Fragment>
	);
});
