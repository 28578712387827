import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type ISquadProfileOverviewController} from "views/components/modals/modal_squad_profile/squad_profile_overview/squad_profile_overview.controller";
import {Typography} from "@mui/material";
import {Exist} from "views/components/exist/exist.component";
import {ISquad} from "data/stores/squads/squads.store";
import {ModalProfileStat, ModalProfileStats} from "views/components/modals/modal_profile_styles";

interface IProps {
	squad: ISquad;
}

export const SquadProfileOverview: React.FC<IProps> = observer(({squad}) => {
	const {i18n, isPreseason} = useViewController<ISquadProfileOverviewController>(
		Bindings.SquadProfileOverviewController
	);

	return (
		<ModalProfileStats className={isPreseason ? "preseason" : ""}>
			<ModalProfileStat>
				<Typography variant="body2">{squad.percentSelected}</Typography>
				<Typography variant="caption" fontSize={10}>
					{i18n.t("squad_profile.stat.selected", "% Selected")}
				</Typography>
			</ModalProfileStat>
			<Exist when={!isPreseason}>
				<ModalProfileStat>
					<Typography variant="body2">{squad.roundPoints ?? "-"}</Typography>
					<Typography variant="caption" fontSize={10}>
						{i18n.t("squad_profile.stat.gameweek_points", "GW Points")}
					</Typography>
				</ModalProfileStat>
				<ModalProfileStat>
					<Typography variant="body2">{squad.averagePoints ?? "-"}</Typography>
					<Typography variant="caption" fontSize={10}>
						{i18n.t("squad_profile.stat.average_points", "Average Points")}
					</Typography>
				</ModalProfileStat>
				<ModalProfileStat>
					<Typography variant="body2">{squad.totalPoints ?? "-"}</Typography>
					<Typography variant="caption" fontSize={10}>
						{i18n.t("squad_profile.stat.total_points", "Total Points")}
					</Typography>
				</ModalProfileStat>
			</Exist>
		</ModalProfileStats>
	);
});
