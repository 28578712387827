import {action, computed, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {CreateTeamSteps, ModalType, PlayerPoolType, PlayerPosition} from "data/enums";
import {FORMATION_LIST} from "data/constants";
import {
	chain,
	defaultTo,
	every,
	fill,
	filter,
	get,
	gt,
	identity,
	indexOf,
	isEmpty,
	isNull,
	lte,
	merge,
	range,
	reduce,
	set,
	size,
	split,
} from "lodash";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import {Bindings} from "data/constants/bindings";
import {getAvailableFormation, hasEmptySpot} from "data/utils/lineup";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import type {ITeamApiProvider} from "data/providers/api/team.api.provider";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ILiveScoresStore} from "data/stores/live_scores/live_scores.store";
import type {IModalsStore} from "data/stores/modals/modals.store";

const {GK, DEF, MID, FWD} = PlayerPosition;

const EMPTY_FORMATIONS: ILineupPlayers = {
	[GK]: [],
	[DEF]: [],
	[MID]: [],
	[FWD]: [],
};

const EMPTY_FORMATIONS_KEYS = [GK, DEF, MID, FWD];

export type IFormation = (typeof FORMATION_LIST)[number];

const nextPosition = {
	[GK]: DEF,
	[DEF]: MID,
	[MID]: FWD,
	[FWD]: GK,
};

const createLineupFromFormation = (formation: IFormation) =>
	reduce(
		split(formation, "-"),
		(acc, arrayLength, index) => {
			const path = EMPTY_FORMATIONS_KEYS[index];
			set(acc, path, fill(range(0, Number(arrayLength)), 0));
			return acc;
		},
		{...EMPTY_FORMATIONS}
	);

export const FORMATIONS = new Map(
	FORMATION_LIST.map((formation) => [formation, createLineupFromFormation(formation)])
);

export type ILineupPlayers = Record<PlayerPosition, number[]>;

export interface ILineup {
	players: ILineupPlayers;
	squads: number[];
}

export interface IClubCapacityModal {
	oldSquadId: number;
	newSquadId: number;
}

export interface ITeam {
	id: number;
	name: string;
	lineup: ILineup;
	userId: number;
	roundId: number;
	formation: IFormation;
	startRoundId: number;
	captainId: number;
	viceCaptainId: number;
	squadSelections: Record<number, number>;
	maxCaptainEnabled: boolean;
	maxCaptainUsed: boolean;
	roundPoints: number | null;
	overallPoints: number | null;
	overallRank: number | null;
	clubCapacityModal: IClubCapacityModal[] | null;
}

export interface ITeamCreateStep {
	pageTitle: string;
	isButtonVisible: boolean;
	buttonText: string;
	withBackButton?: boolean;
	pageDescription: string;
	step: CreateTeamSteps;
}

export interface IOtherUserTeam {
	id: number;
	userId: number;
	roundId: number;
	lineup: ILineup;
	formation: IFormation;
	captainId: number;
	viceCaptainId: number;
	maxCaptainEnabled: boolean;
}

export interface ITeamStore {
	get team(): ITeam;
	set team(team: ITeam);
	get savedTeam(): ITeam | null;
	set savedTeam(team: ITeam);
	get lineupSquads(): number[];
	get lineupPlayers(): ILineupPlayers;
	get lineupIDs(): number[];
	get isTeamChanged(): boolean;
	get isTeamSaved(): boolean;
	get selectedStep(): CreateTeamSteps;
	set selectedStep(step: CreateTeamSteps);
	get isLineupFullFilled(): boolean;
	get isTeamCreated(): boolean;
	get teamCreationStep(): ITeamCreateStep;
	get isSquadsFullFilled(): boolean;
	get isTeamFullFilled(): boolean;
	get isTeamComplete(): boolean;
	get isTeamLive(): boolean;
	get liveTeamPoints(): number;
	get liveTotalPoints(): number;
	get otherUserTeam(): IOtherUserTeam;
	get isTeamPlayerLocked(): boolean;
	get isBlockingNav(): boolean;

	removeFromTeam: (id: number) => void;
	addToTeam: (id: number) => void;
	removeSquadFromTeam: (id: number) => void;
	addSquadToTeam: (id: number) => void;
	selectCaptain: (id: number) => void;
	selectViceCaptain: (id: number) => void;
	hasEmptySpot: (lineup: ILineupPlayers, position: PlayerPosition) => boolean;
	getAvailableFormation: (
		lineup: ILineupPlayers,
		position: PlayerPosition
	) => IFormation | undefined;
	canAddFromSquad: (squadId: number) => boolean;
	canAddPlayerToTeam: (player: IPlayer) => boolean;
	getSquadSelectedCount: (squadId: number) => number;
	isSquadCapReached: (squadId: number) => boolean;
	canAddSquadToTeam: (squad: ISquad) => boolean;
	getMaxCaptain: (team: ITeam | IOtherUserTeam) => number | undefined;
	getPlayerRoundPoints: (
		id: number,
		roundId: number,
		gameId?: number,
		team?: ITeam | IOtherUserTeam
	) => number | null;
	getSquadRoundPoints: (id: number, roundId: number, gameId?: number) => number | null;
	fetchTeam: () => Promise<void>;
	fetchTeamByRound: (roundId: number) => Promise<void>;
	saveTeam: () => Promise<void>;
	setMaxCaptain: (maxCaptainEnabled: boolean) => Promise<void>;
	clearLineup: () => void;
	clearTeam: () => void;
	fetchOtherUserTeam: (userId: number, roundId: number) => Promise<void>;
	setClubCapModal: () => void;
}

@injectable()
export class TeamStore implements ITeamStore {
	private _defaultLineup: ILineupPlayers = {
		[PlayerPosition.GK]: [0],
		[PlayerPosition.DEF]: [0, 0],
		[PlayerPosition.MID]: [0, 0],
		[PlayerPosition.FWD]: [0, 0],
	};
	private _defaultSquads: number[] = [0, 0];
	private _defaultTeam: ITeam = {
		id: 0,
		name: "",
		lineup: {
			players: this._defaultLineup,
			squads: this._defaultSquads,
		},
		userId: 0,
		roundId: 0,
		formation: FORMATION_LIST[0],
		startRoundId: 0,
		captainId: 0,
		viceCaptainId: 0,
		squadSelections: {},
		maxCaptainEnabled: false,
		maxCaptainUsed: false,
		roundPoints: null,
		overallPoints: null,
		overallRank: null,
		clubCapacityModal: null,
	};

	@observable private _team: ITeam = this._defaultTeam;
	@observable private _savedTeam: ITeam | null = null;

	@observable private _isTeamChanged: boolean = false;
	@observable private _isTeamSaved: boolean = false;
	@observable private _selectedStep: CreateTeamSteps = CreateTeamSteps.PickTeam;
	@observable private _firstAddedShouldBecomeCaptain = false;
	@observable private _firstAddedShouldBecomeViceCaptain = false;

	@observable private _otherUserTeam: IOtherUserTeam = {
		id: 0,
		userId: 0,
		roundId: 0,
		lineup: this._defaultTeam.lineup,
		formation: this._defaultTeam.formation,
		captainId: this._defaultTeam.captainId,
		viceCaptainId: this._defaultTeam.viceCaptainId,
		maxCaptainEnabled: this._defaultTeam.maxCaptainEnabled,
	};

	get isTeamChanged() {
		return this._isTeamChanged;
	}

	get isTeamSaved() {
		return this._isTeamSaved;
	}

	get selectedStep() {
		return this._selectedStep;
	}

	set selectedStep(step: CreateTeamSteps) {
		this._selectedStep = step;
	}

	get team() {
		return this._team;
	}

	set team(team: ITeam) {
		this._team = team;
	}

	get savedTeam() {
		return this._savedTeam;
	}

	set savedTeam(team: ITeam | null) {
		this._savedTeam = team;
	}

	get lineupPlayers() {
		return this._team.lineup.players || this._defaultLineup;
	}

	get lineupIDs(): number[] {
		return chain(this.lineupPlayers).values().flatten().value();
	}

	get lineupSquads() {
		return this._team.lineup.squads || this._defaultSquads;
	}

	@computed
	get teamCreationStep(): ITeamCreateStep {
		const pickTeam = {
			pageTitle: "team_creation.app_header.pick_team",
			isButtonVisible: true,
			buttonText: "team_creation.button.tutorial",
			pageDescription: "team_creation.message.team_rules",
			step: CreateTeamSteps.PickTeam,
		};
		const pickSquads = {
			pageTitle: "team_creation.app_header.pick_squads",
			isButtonVisible: true,
			buttonText: "team_creation.button.tutorial",
			pageDescription: "team_creation.message.squad_rules",
			step: CreateTeamSteps.PickSquads,
		};
		const pickCaptain = {
			pageTitle: "team_creation.app_header.pick_captain",
			isButtonVisible: false,
			buttonText: "",
			withBackButton: true,
			pageDescription: "team_creation.message.pick_captain",
			step: CreateTeamSteps.PickCaptain,
		};
		const pickViceCaptain = {
			pageTitle: "team_creation.app_header.pick_vice_captain",
			isButtonVisible: false,
			buttonText: "",
			withBackButton: false,
			pageDescription: "team_creation.message.pick_vice_captain",
			step: CreateTeamSteps.PickViceCaptain,
		};

		const updateTeam = {
			pageTitle: this.team.name || "",
			isButtonVisible: false,
			buttonText: "team_creation.button.confirm",
			withBackButton: false,
			pageDescription: "",
			step: CreateTeamSteps.UpdateTeam,
		};

		const steps = {
			pickTeam,
			pickSquads,
			pickCaptain,
			pickViceCaptain,
			updateTeam,
		};

		return steps[this.selectedStep];
	}

	@computed
	get isLineupFullFilled(): boolean {
		return !chain(this.team.lineup.players).values().flatten().value().includes(0);
	}

	@computed
	get isSquadsFullFilled(): boolean {
		return !this.team.lineup.squads.includes(0);
	}

	@computed
	get isTeamFullFilled(): boolean {
		return this.isLineupFullFilled && this.isSquadsFullFilled;
	}

	@computed
	get isTeamComplete(): boolean {
		return this.isTeamFullFilled && this.team.captainId !== 0 && this.team.viceCaptainId !== 0;
	}

	@computed
	get isTeamCreated() {
		return this.team.id !== 0;
	}

	@computed
	get isTeamLive() {
		if (!this.team.startRoundId || !this._roundsStore.currentRound) {
			return false;
		}
		if (!this._roundsStore.isSeasonStarted) {
			return false;
		}
		return this._roundsStore.currentRound.id >= this.team.startRoundId;
	}

	@computed
	get liveTeamPoints() {
		const roundId = this._roundsStore.selectedRound?.id;

		if (!roundId) {
			return 0;
		}

		const playerPoints = chain(this.lineupIDs)
			.map((id) => this.getPlayerRoundPoints(id, roundId, undefined, this._team))
			.reject(isNull)
			.sum()
			.value();

		const squadPoints = chain(this.lineupSquads)
			.map((id) => this.getSquadRoundPoints(id, roundId))
			.reject(isNull)
			.sum()
			.value();

		return playerPoints + squadPoints;
	}

	@computed
	get liveTotalPoints() {
		const overallPoints = defaultTo(this.team.overallPoints, 0);
		const livePoints = defaultTo(this.liveTeamPoints, 0);
		const roundPoints = defaultTo(this.team.roundPoints, 0);
		const difference = livePoints - roundPoints;
		return overallPoints + difference;
	}

	get otherUserTeam() {
		return this._otherUserTeam;
	}

	get isTeamPlayerLocked(): boolean {
		return chain(this.lineupIDs)
			.map((id) => this._playersStore.getPlayerById(id))
			.compact()
			.map((player) => player.locked)
			.includes(true)
			.value();
	}

	get isTeamEmpty() {
		return chain(this.lineupIDs)
			.concat(this.lineupSquads)
			.every((id) => id === 0)
			.value();
	}

	get isBlockingNav() {
		if (!this.team.id && !this.isTeamComplete) {
			return !this.isTeamEmpty;
		}

		return this.isTeamChanged;
	}

	constructor(
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.TeamApiProvider) private _teamApiProvider: ITeamApiProvider,
		@inject(Bindings.LocalizationStore) public _i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.LiveScoresStore) private _liveScores: ILiveScoresStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action setTeamChanged() {
		this._isTeamChanged = true;
		this._isTeamSaved = false;
	}

	changeFormation(payload: IFormation) {
		const formation = FORMATIONS.get(payload);

		if (!formation) {
			return;
		}

		const lineup = this.lineupPlayers;

		const outPosition: PlayerPosition[] = [];
		const inPosition: PlayerPosition[] = [];

		const newLineup = reduce(
			formation,
			(acc, newLine, key) => {
				const position = key as PlayerPosition;
				const currentLine = lineup[position];

				const currentLineWithPlayers = filter(currentLine, identity);

				const emptyLineSize = size(newLine);
				const currentLineSize = size(currentLine);
				const playersLineSize = size(currentLineWithPlayers);

				const availablePositionsDiff = emptyLineSize - currentLineSize;
				const filledPositionsDiff = playersLineSize - emptyLineSize;

				if (gt(availablePositionsDiff, 0)) {
					const posRange = range(0, availablePositionsDiff);
					const positions = fill(posRange, position);
					inPosition.push(...positions);
				}

				if (lte(filledPositionsDiff, 0)) {
					acc = {
						...acc,
						[position]: merge([], newLine, currentLineWithPlayers),
					};
				} else {
					const posRange = range(0, filledPositionsDiff);
					const positions = fill(posRange, position);
					outPosition.push(...positions);
				}

				return acc;
			},
			EMPTY_FORMATIONS
		);

		const isRequireReplace = [isEmpty(outPosition), isEmpty(inPosition)].every((val) => !val);

		if (isRequireReplace) {
			const getTransferText = (positions: PlayerPosition[]) => {
				const position = positions[0];
				const positionsSize = size(positions);
				const isPositionsTheSame = positions.every((value) => value === positions[0]);

				return positionsSize > 1 && isPositionsTheSame
					? `${positionsSize} ${position}`
					: `a ${positions.map((pos) => pos).join(" and ")}`;
			};

			const errorMessage = [
				"In order to change to this formation you must replace ",
				getTransferText(outPosition),
				" for ",
				getTransferText(inPosition),
				"<p>For more information on making formation changes visit our <a href='/help/guidelines' target='_blank'>Game Guidelines</a></p>",
			].join("");
			console.log(errorMessage);

			throw new Error(errorMessage);
		}

		runInAction(() => {
			this._team.lineup.players = newLineup;
			this._team.formation = payload;
		});
	}

	findAndReplaceInLineup(id: number, replaceFrom: number, replaceTo: number) {
		const player = this._playersStore.getPlayerById(id);
		const lineup = this.lineupPlayers;

		if (!player) {
			throw new Error("Picked player not found!");
		}

		const {position} = player;
		const isAddPlayerAction = !replaceFrom;

		if (isAddPlayerAction) {
			if (!this.hasEmptySpot(lineup, position)) {
				const newFormation = this.getAvailableFormation(lineup, position);

				if (newFormation) {
					this.changeFormation(newFormation);
				} else {
					throw new Error("Can't define allowed formation.");
				}
			}

			if (this._firstAddedShouldBecomeCaptain) {
				this.selectCaptain(id);
			} else if (this._firstAddedShouldBecomeViceCaptain) {
				this.selectViceCaptain(id);
			}
		}

		const actualLineup: ILineupPlayers = this.lineupPlayers;
		const line = [...actualLineup[position]];

		const newLineup: ILineupPlayers = {
			...actualLineup,
			[position]: set(line, indexOf(line, replaceFrom), replaceTo),
		};

		runInAction(() => {
			this._team.lineup.players = newLineup;
			this.changePlayerActions(isAddPlayerAction, position);
			if (replaceFrom) {
				this.checkCaptain(replaceFrom);
			}
		});
	}

	changePlayerActions(isAddPlayerAction: boolean, position: PlayerPosition) {
		if (this.isTeamFullFilled) {
			if (!this.isTeamCreated) {
				this.selectedStep = CreateTeamSteps.PickCaptain;
				this._modalsStore.showModal(ModalType.CAPTAIN_SELECT);
			}
			this._teamBuilderStore.closePlayerPool();
			this._teamBuilderStore.resetPosition();
		} else if (this.isLineupFullFilled) {
			if (!this.isTeamCreated) {
				this.selectedStep = CreateTeamSteps.PickSquads;
			}
			this._teamBuilderStore.playerPoolType = PlayerPoolType.Squad;
		} else {
			if (!this.isTeamCreated) {
				this.selectedStep = CreateTeamSteps.PickTeam;
			} else {
				this.selectedStep = CreateTeamSteps.UpdateTeam;
			}
			this.checkNextPosition(isAddPlayerAction, position);
		}
	}

	checkCaptain(id: number) {
		if (this.team.captainId === id) {
			this.team.captainId = 0;
			this._firstAddedShouldBecomeCaptain = true;
		}
		if (this.team.viceCaptainId === id) {
			this.team.viceCaptainId = 0;
			this._firstAddedShouldBecomeViceCaptain = true;
		}
	}

	hasEmptySpot(lineup: ILineupPlayers, position: PlayerPosition) {
		return hasEmptySpot(lineup, position);
	}

	getAvailableFormation(lineup: ILineupPlayers, position: PlayerPosition) {
		return getAvailableFormation(lineup, position);
	}

	checkNextPosition(isAddAction: boolean, position: PlayerPosition) {
		if (!isAddAction) {
			return;
		}

		const isGKAdded = position === PlayerPosition.GK;

		if (isGKAdded || !this.hasEmptySpot(this.lineupPlayers, position)) {
			const newFormation = this.getAvailableFormation(this.lineupPlayers, position);

			if (!newFormation || isGKAdded || this.isTeamCreated) {
				this._teamBuilderStore.setSelectedFieldPosition(nextPosition[position]);
			}
		}
	}

	removeFromTeam = (id: number) => {
		this.setTeamChanged();
		this.findAndReplaceInLineup(id, id, 0);
	};

	addToTeam = (id: number) => {
		this.setTeamChanged();
		this.findAndReplaceInLineup(id, 0, id);
	};

	findAndReplaceInSquads(id: number, replaceFrom: number, replaceTo: number) {
		const squads = this.lineupSquads;

		const newSquads = set(squads, indexOf(squads, replaceFrom), replaceTo);

		runInAction(() => {
			this._team.lineup.squads = newSquads;
			this.changeSquadActions();
		});
	}

	changeSquadActions() {
		if (this.isTeamFullFilled && !this.isTeamCreated) {
			this.selectedStep = CreateTeamSteps.PickCaptain;
			this._modalsStore.showModal(ModalType.CAPTAIN_SELECT);
			this._teamBuilderStore.closePlayerPool();
		} else if (this.isTeamFullFilled) {
			this._teamBuilderStore.closePlayerPool();
		} else if (this.isTeamCreated) {
			this.selectedStep = CreateTeamSteps.UpdateTeam;
		}
	}

	removeSquadFromTeam = (id: number) => {
		this.setTeamChanged();
		this.findAndReplaceInSquads(id, id, 0);
	};

	addSquadToTeam = (id: number) => {
		this.setTeamChanged();
		this.findAndReplaceInSquads(id, 0, id);
	};

	@action selectCaptain = (id: number) => {
		this.setTeamChanged();
		this._team.captainId = id;
		this._firstAddedShouldBecomeCaptain = false;
	};

	@action selectViceCaptain = (id: number) => {
		this.setTeamChanged();
		this._team.viceCaptainId = id;
		this._firstAddedShouldBecomeViceCaptain = false;
	};

	canAddFromSquad = (squadId: number) => {
		const playersFromSameSquad = chain(this.lineupIDs)
			.map((id) => this._playersStore.getPlayerById(id))
			.compact()
			.filter((player) => squadId === player.squadId)
			.value();

		return playersFromSameSquad.length < 2;
	};

	canAddPlayerToTeam = (player: IPlayer) => {
		const lineup = this.lineupPlayers;

		if (!player) {
			return false;
		}

		const {position, squadId} = player;

		if (!this.canAddFromSquad(squadId)) {
			return false;
		}

		if (position === PlayerPosition.GK) {
			return this.hasEmptySpot(lineup, position);
		}

		return (
			this.hasEmptySpot(lineup, position) ||
			Boolean(this.getAvailableFormation(lineup, position))
		);
	};

	getSquadSelectedCount = (squadId: number) => {
		// BE increments squad selection count for squads in team
		// so we need to decrease it by 1 if squad is in team but not locked
		const selected = get(this.team.squadSelections, squadId, 0);
		if (this.savedTeam) {
			const isInTeam = this.savedTeam.lineup.squads.includes(squadId);
			const isLocked = this._squadsStore.getSquadById(squadId)?.locked;
			if (every([isInTeam, !isLocked])) {
				return selected - 1;
			}
		}
		return selected;
	};

	isSquadCapReached = (squadId: number) => {
		const selectedCount = this.getSquadSelectedCount(squadId);
		return selectedCount >= 5;
	};

	canAddSquadToTeam = (squad: ISquad) => {
		return !this.isSquadsFullFilled && !this.isSquadCapReached(squad.id);
	};

	isViceCaptainScoringBonusActive = (captainId: number, roundId: number) => {
		const captain = this._playersStore.getPlayerById(captainId);
		if (!captain) {
			return false;
		}

		const isAllGamesPlayed = this._roundsStore.isAllSquadGamesPlayed(captain.squadId, roundId);
		if (!isAllGamesPlayed) {
			return false;
		}

		const scores = this._liveScores.getPlayerScoresByRound(roundId, captainId);
		return isEmpty(scores);
	};

	getHighestPointsPlayer = (team: ITeam | IOtherUserTeam): number | null => {
		return (
			chain(team.lineup.players)
				.values()
				.flatten()
				.maxBy((id) => this.getPlayerRoundPoints(id, team.roundId))
				.value() || null
		);
	};

	getMaxCaptain = (team: ITeam | IOtherUserTeam): number | undefined => {
		if (!team.maxCaptainEnabled) {
			return;
		}
		return this.getHighestPointsPlayer(team) ?? team.captainId;
	};

	getPlayerRoundPoints = (
		id: number,
		roundId: number,
		gameId?: number,
		team?: ITeam | IOtherUserTeam
	) => {
		const scores = this._liveScores
			.getPlayerScoresByRound(roundId, id)
			.filter((score) => !gameId || score.gameId === gameId);

		if (isEmpty(scores)) {
			return null;
		}

		const points = reduce(scores, (acc, score) => acc + score.points, 0);

		if (!team) {
			return points;
		}

		const {captainId, viceCaptainId} = team;
		const maxCaptainId = this.getMaxCaptain(team);

		if (maxCaptainId) {
			return id === maxCaptainId ? points * 2 : points;
		}

		if (id === captainId) {
			return points * 2;
		}

		if (
			every([id === viceCaptainId, this.isViceCaptainScoringBonusActive(captainId, roundId)])
		) {
			return points * 2;
		}

		return points;
	};

	getSquadRoundPoints = (id: number, roundId: number, gameId?: number) => {
		const scores = this._liveScores
			.getSquadScoresByRound(roundId, id)
			.filter((score) => !gameId || score.gameId === gameId);
		if (isEmpty(scores)) {
			return null;
		}
		return reduce(scores, (acc, score) => acc + score.points, 0);
	};

	@action
	async fetchTeam() {
		const response = await this._teamApiProvider.team();

		runInAction(() => {
			const team = response.data.success.team;
			if (team) {
				this._team = team;
				this._savedTeam = team;
				this._isTeamChanged = false;
			}
		});
	}

	@action
	async fetchTeamByRound(roundId: number) {
		const response = await this._teamApiProvider.teamByRound({roundId});

		runInAction(() => {
			const team = response.data.success.team;
			if (team) {
				this._team = team;
				this._savedTeam = team;
			}
		});
	}

	@action
	async saveTeam() {
		const response = await this._teamApiProvider.saveTeam({
			lineup: this.team.lineup,
			formation: this.team.formation,
			captainId: this.team.captainId,
			viceCaptainId: this.team.viceCaptainId,
			maxCaptainEnabled: this.team.maxCaptainEnabled,
		});

		runInAction(() => {
			const team = response.data.success.team;
			if (team) {
				this._team = team;
				this._savedTeam = team;
				this._isTeamSaved = true;
				this._isTeamChanged = false;
			}
		});
	}

	@action
	setMaxCaptain = async (maxCaptainEnabled: boolean) => {
		if (!this.savedTeam) {
			throw new Error("Team not found");
		}

		const response = await this._teamApiProvider.saveTeam({
			lineup: this.savedTeam.lineup,
			formation: this.savedTeam.formation,
			captainId: this.savedTeam.captainId,
			viceCaptainId: this.savedTeam.viceCaptainId,
			maxCaptainEnabled,
		});

		runInAction(() => {
			const team = response.data.success.team;
			if (team) {
				this._team.maxCaptainEnabled = team.maxCaptainEnabled;
				this._team.maxCaptainUsed = team.maxCaptainUsed;
				this._savedTeam = team;
			}
		});
	};

	@action
	clearLineup = () => {
		this._team.lineup = {
			players: this._defaultLineup,
			squads: this._defaultSquads,
		};
		this._team.formation = this._defaultTeam.formation;
		this._team.captainId = 0;
		this._team.viceCaptainId = 0;
		this._firstAddedShouldBecomeCaptain = true;
		this._firstAddedShouldBecomeViceCaptain = true;
		this.setTeamChanged();
	};

	@action
	clearTeam = () => {
		this._team = this._defaultTeam;
		this._savedTeam = null;
		this._isTeamChanged = false;
		this._isTeamSaved = false;
		this._selectedStep = CreateTeamSteps.PickTeam;
	};

	@action
	async fetchOtherUserTeam(userId: number, roundId: number) {
		const response = await this._teamApiProvider.otherUserTeam({userId, roundId});

		runInAction(() => {
			const team = response.data.success.team;
			if (team) {
				this._otherUserTeam = team;
			}
		});
	}

	@action
	async setClubCapModal() {
		if (!this.savedTeam) {
			throw new Error("Team not found");
		}

		const response = await this._teamApiProvider.saveTeam({
			lineup: this.savedTeam.lineup,
			formation: this.savedTeam.formation,
			captainId: this.savedTeam.captainId,
			viceCaptainId: this.savedTeam.viceCaptainId,
			modalShown: true,
		});

		runInAction(() => {
			const team = response.data.success.team;
			if (team) {
				this._team.clubCapacityModal = null;
				this._savedTeam = team;
			}
		});
	}
}
