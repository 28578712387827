import {action, computed, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeaguesStore, ITab} from "data/stores/leagues/leagues.store";
import {Bindings} from "data/constants/bindings";
import {isEqual} from "lodash";
import type {IUserStore} from "data/stores/user/user.store";
import {useLocation} from "react-router-dom";

interface IProps {
	leagueId: number;
	location: ReturnType<typeof useLocation>;
}

export interface ILeagueNavigationController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get league(): ILeague | null;
	get isCommissioner(): boolean;
	get tabs(): ITab[];
	get activeIndex(): number;
}

@injectable()
export class LeagueNavigationController implements ILeagueNavigationController {
	@observable private _location?: ReturnType<typeof useLocation>;
	@observable private _leagueId?: number;

	get tabs() {
		if (!this._leagueId) return [];
		return this._leaguesStore.getTabs(this._leagueId, this.isCommissioner);
	}

	@computed get activeIndex() {
		const index = this.tabs.findIndex((tab) => isEqual(tab.path, this._location?.pathname));

		return index === -1 ? 0 : index;
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get isCommissioner() {
		return isEqual(this.league?.leagueManager?.userId, this._userStore.user!.id);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@action onChange({leagueId, location}: IProps) {
		this._leagueId = leagueId;
		this._location = location;
	}

	@action init({leagueId, location}: IProps): void {
		this._leagueId = leagueId;
		this._location = location;
	}
}
