import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IPlayerProfileResultsController} from "views/components/modals/modal_player_profile/player_profile_results/player_profile_results.controller";
import {PlayerProfileMatch} from "./player_profile_match/player_profile_match.component";
import {IPlayer} from "data/stores/players/players.store";
import {
	ModalProfileResults,
	ModalProfileResultsScrollGradient,
} from "views/components/modals/modal_profile_styles";
import {Exist} from "views/components/exist/exist.component";
import {isEmpty} from "lodash";
import {Box, Typography} from "@mui/material";
import {Preloader} from "views/components/preloader";

interface IProps {
	player: IPlayer;
}

export const PlayerProfileResults: React.FC<IProps> = observer(({player}) => {
	const {i18n, results, fixtures, isLoading} = useViewController<IPlayerProfileResultsController>(
		Bindings.PlayerProfileResultsController,
		{player}
	);

	return (
		<Box position="relative" overflow="hidden">
			<ModalProfileResults>
				<Exist when={isLoading}>
					<Preloader />
				</Exist>
				<Exist when={!isEmpty(results)}>
					<Exist when={!isEmpty(fixtures)}>
						<Typography variant="caption" fontWeight={700}>
							{i18n.t("player_profile.matches.results", "Results")}
						</Typography>
					</Exist>
					{results.map((game, index) => (
						<PlayerProfileMatch
							key={index}
							gameId={game.id}
							playerId={player.id}
							position={player.position}
						/>
					))}
				</Exist>
				<Exist when={!isEmpty(fixtures)}>
					<Exist when={!isEmpty(results)}>
						<Typography variant="caption" fontWeight={700}>
							{i18n.t("player_profile.matches.upcoming", "Fixtures")}
						</Typography>
					</Exist>
					{fixtures.map((game, index) => (
						<PlayerProfileMatch
							key={index}
							gameId={game.id}
							playerId={player.id}
							position={player.position}
						/>
					))}
				</Exist>
			</ModalProfileResults>
			<ModalProfileResultsScrollGradient />
		</Box>
	);
});
