import {action, computed, makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";
import {
	PlayerPitchStat,
	PlayerPoolStat,
	PlayerPoolType,
	PlayerPosition,
	SortOrder,
} from "data/enums";
import {SelectChangeEvent} from "@mui/material";
import {get, xor} from "lodash";
import {parseSingleValueFromArray} from "data/utils";
import {ChangeEvent} from "react";

export interface IPoolFilters {
	search: string;
	position: (PlayerPosition | "null")[];
	squad: string[];
	competition: string[];
	stat: PlayerPoolStat;
}

export interface ISelectOption {
	val: string;
	label: string;
	preseason?: boolean;
}

const defaultFilters: IPoolFilters = {
	search: "",
	position: ["null"],
	squad: ["null"],
	competition: ["null"],
	stat: PlayerPoolStat.PercentSelected,
};

export interface ITeamBuilderStore {
	get isPlayerPoolVisible(): boolean;
	get isPoolFiltersVisible(): boolean;
	get isPoolSearchVisible(): boolean;
	get playerPoolType(): PlayerPoolType;
	set playerPoolType(type: PlayerPoolType);
	get order(): SortOrder;
	get filters(): IPoolFilters;
	set filters(filters: IPoolFilters);
	get selectedStatLabel(): string;
	get statsOptions(): ISelectOption[];
	get selectedPitchStatLabel(): string;
	get selectedPitchStatOption(): PlayerPitchStat;
	set selectedPitchStatOption(optionName: PlayerPitchStat);
	get pitchStatsOptions(): ISelectOption[];
	get positionOptions(): ISelectOption[];
	get isPoolGridView(): boolean;
	set isPoolGridView(isGrid: boolean);
	get isPoolSortedByCap(): boolean;
	set isPoolSortedByCap(isSorted: boolean);
	get selectedFiltersLength(): number;
	setSelectedFieldPosition: (position: PlayerPosition) => void;
	resetPosition: () => void;
	setCompetition: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	updateFilter: (e: SelectChangeEvent | SelectChangeEvent<unknown>) => void;
	updateSearch: (e: ChangeEvent<HTMLInputElement>) => void;
	sortPlayerPool: () => void;
	resetFilters: () => void;
	resetSearch: () => void;
	openPlayerPool: () => void;
	closePlayerPool: () => void;
	toggleFilters: () => void;
	openSearch: () => void;
	closeSearch: () => void;
}

const selectedKey = "player_pool.stats_filter.selected";
const isMobile = window.matchMedia("(max-width: 960px)").matches;

@injectable()
export class TeamBuilderStore implements ITeamBuilderStore {
	@observable private _isPoolFiltersVisible: boolean = isMobile;
	@observable private _isPoolSearchVisible: boolean = false;
	@observable private _isPlayerPoolVisible: boolean = false;
	@observable private _playerPoolType: PlayerPoolType = PlayerPoolType.Player;
	@observable private _order: SortOrder = SortOrder.DESC;
	@observable private _filters: IPoolFilters = {
		...defaultFilters,
	};
	@observable private _selectedPitchStatOption = PlayerPitchStat.NextFixture;
	@observable private _isPoolGridView: boolean = false;
	@observable private _isPoolSortedByCap: boolean = false;

	private _statsOptions: ISelectOption[] = [
		{val: PlayerPoolStat.PercentSelected, label: selectedKey},
		{val: PlayerPoolStat.TotalPoints, label: "player_pool.stats_filter.total_pts"},
		{val: PlayerPoolStat.RoundPoints, label: "player_pool.stats_filter.round_pts"},
		{val: PlayerPoolStat.AveragePoints, label: "player_pool.stats_filter.average_pts"},
	];

	private _pitchStatsOptions = [
		{val: PlayerPitchStat.RoundPoints, label: "player_pool.stats_filter.round_pts"},
		{val: PlayerPitchStat.TotalPoints, label: "player_pool.stats_filter.total_pts"},
		{val: PlayerPitchStat.PercentSelected, label: selectedKey, preseason: true},
		{
			val: PlayerPitchStat.NextFixture,
			label: "player_pool.stats_filter.next_fixture",
			preseason: true,
		},
	];

	private _positionOptions: ISelectOption[] = [
		{label: "player_pool.position_filter.goalkeeper", val: PlayerPosition.GK},
		{label: "player_pool.position_filter.defender", val: PlayerPosition.DEF},
		{label: "player_pool.position_filter.midfielder", val: PlayerPosition.MID},
		{label: "player_pool.position_filter.striker", val: PlayerPosition.FWD},
	];

	get isPoolFiltersVisible(): boolean {
		return this._isPoolFiltersVisible;
	}

	get isPoolSearchVisible(): boolean {
		return this._isPoolSearchVisible;
	}

	get isPlayerPoolVisible(): boolean {
		return this._isPlayerPoolVisible;
	}

	get playerPoolType(): PlayerPoolType {
		return this._playerPoolType;
	}

	set playerPoolType(type: PlayerPoolType) {
		this._playerPoolType = type;
	}

	get order() {
		return this._order;
	}

	get filters(): IPoolFilters {
		return this._filters;
	}

	set filters(filters: IPoolFilters) {
		this._filters = filters;
	}

	get selectedStatLabel(): string {
		const option = this.statsOptions.find(({val}) => val === this.filters.stat);
		return get(option, "label", "");
	}

	get statsOptions() {
		return this._statsOptions;
	}

	get selectedPitchStatLabel(): string {
		const option = this.pitchStatsOptions.find(({val}) => val === this.selectedPitchStatOption);
		return get(option, "label", "");
	}

	get selectedPitchStatOption() {
		return this._selectedPitchStatOption;
	}

	set selectedPitchStatOption(optionName: PlayerPitchStat) {
		this._selectedPitchStatOption = optionName;
	}

	get pitchStatsOptions() {
		return this._pitchStatsOptions;
	}

	get positionOptions() {
		return this._positionOptions;
	}

	get isPoolGridView() {
		return this._isPoolGridView;
	}

	set isPoolGridView(isGrid: boolean) {
		this._isPoolGridView = isGrid;
	}

	get isPoolSortedByCap() {
		return this._isPoolSortedByCap;
	}

	set isPoolSortedByCap(isSorted: boolean) {
		this._isPoolSortedByCap = isSorted;
	}

	@computed
	get selectedFiltersLength(): number {
		const {position, squad, competition, search} = this.filters;
		const filters = [competition];
		if (this.playerPoolType === PlayerPoolType.Player) {
			filters.push(position, squad);
		}
		const dropdownWithFilterValues = filters.filter(
			(arr) => arr.filter((item) => item !== "null").length
		);
		const searchValue = search.trim().length > 0 ? 1 : 0;
		return dropdownWithFilterValues.length + searchValue;
	}

	constructor() {
		makeAutoObservable(this);
	}

	@action
	setSelectedFieldPosition = (position: PlayerPosition) => {
		this._filters = {
			...this._filters,
			position: [position],
		};
		this._playerPoolType = PlayerPoolType.Player;
	};

	@action
	sortPlayerPool = () => {
		this._order = this._order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
	};

	@action
	resetFilters = () => {
		this._filters = {
			...defaultFilters,
		};
		this._order = SortOrder.DESC;
	};

	@action
	resetSearch = () => {
		this._filters.search = "";
	};

	@action
	resetPosition = () => {
		this._filters = {
			...this._filters,
			position: ["null"],
		};
	};

	@action
	setCompetition = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const {competition} = e.currentTarget.dataset;
		const value = xor(this._filters.competition, [competition as string]);
		const newValue = parseSingleValueFromArray(value, "null");

		this._filters = {
			...this._filters,
			squad: ["null"],
			competition: newValue as string[],
		};
	};

	@action
	updateFilter = (e: SelectChangeEvent | SelectChangeEvent<unknown>) => {
		const {value, name} = e.target;
		const newValue = parseSingleValueFromArray(value as string[], "null");

		this._filters = {
			...this._filters,
			[name]: newValue,
		};

		if (name === "competition") {
			this._filters.squad = ["null"];
		}

		if (name === "stat") {
			this._order = SortOrder.DESC;
		}
	};

	updateSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const {value, name} = e.target;

		this._filters = {
			...this._filters,
			[name]: value,
		};
	};

	@action
	public openPlayerPool = () => {
		window.document.body.classList.add("overflow-hidden-mobile");
		this._isPlayerPoolVisible = true;
	};

	@action
	public closePlayerPool = () => {
		window.document.body.classList.remove("overflow-hidden-mobile");
		this._isPlayerPoolVisible = false;
	};

	@action
	public toggleFilters(): void {
		this._isPoolFiltersVisible = !this._isPoolFiltersVisible;
	}

	@action
	public openSearch(): void {
		this._isPoolFiltersVisible = false;
		this._isPoolSearchVisible = true;
	}

	@action
	public closeSearch(): void {
		this._isPoolSearchVisible = false;
	}
}
