import {Box, Container, Divider, Stack, Typography} from "@mui/material";
import React from "react";
import logo from "assets/img/logos/efl_white.svg";
import {useViewController} from "data/services/locator";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import {Bindings} from "data/constants/bindings";
import {Link} from "react-router-dom";
import styled from "@emotion/styled";
import background from "assets/img/footer_shape.svg";
import geniusLogo from "assets/img/logos/genius.svg";

const Shape = styled.div`
	background-image: url(${background});
	background-size: cover;
	background-position: center;
	height: 20px;

	${({theme}) => theme.breakpoints.up("md")} {
		height: 66px;
	}
`;

const FooterLink = styled(Link)`
	text-decoration: underline;
	font-weight: 700;
`;

export const Footer: React.FC = () => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);

	return (
		<div>
			<Shape />
			<Box bgcolor="primary.main" color="#FFF" py={{xs: 2, md: 2.5}}>
				<Container>
					<Box
						display="flex"
						alignItems="center"
						justifyContent="space-between"
						gap={1.5}
						flexDirection={{xs: "column", md: "row"}}>
						<Stack spacing={2.5}>
							<img src={logo} alt="EFL" width="91" />
							<Typography variant="caption" display={{xs: "none", md: "block"}}>
								{i18n.t("footer.copy.genius", "© 2024 Genius Sports")}
							</Typography>
						</Stack>

						<Stack
							spacing={{xs: 1, md: 2.5}}
							alignItems={{xs: "center", md: "flex-end"}}>
							<Box display="flex" alignItems="center" gap={1}>
								<FooterLink to="/help/terms-and-conditions" target="_blank">
									{i18n.t("footer.button.terms", "Terms & Conditions")}
								</FooterLink>
								<Divider orientation="vertical" color="#FFF" flexItem />
								<FooterLink to="/help/contact-us" target="_blank">
									{i18n.t("footer.button.contact", "Contact Us")}
								</FooterLink>
							</Box>

							<Box display="flex" alignItems="center" gap={1}>
								<Typography variant="caption">
									{i18n.t("footer.copy.powered_by", "Powered By:")}
								</Typography>
								<a
									href="https://geniussports.com/"
									target="_blank"
									rel="noreferrer">
									<img src={geniusLogo} alt="Genius" />
								</a>
							</Box>
							<Typography variant="caption" display={{md: "none"}}>
								{i18n.t("footer.copy.genius", "© 2024 Genius Sports")}
							</Typography>
						</Stack>
					</Box>
				</Container>
			</Box>
		</div>
	);
};
