/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {ThemeOptions} from "@mui/material/styles";

// Update the Button prop options
declare module "@mui/material/Button" {
	interface ButtonPropsColorOverrides {
		tertiary: true;
	}
}

export const buttonsTheme: ThemeOptions = {
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					minHeight: "48px",
					padding: "6px 20px",
					fontWeight: "700",
					fontSize: "16px",
					borderRadius: "100px",
					boxShadow: "none",
					textTransform: "none",
					":hover": {
						boxShadow: "none",
					},
					":disabled": {
						cursor: "default",
					},
					"@media (max-width:899px)": {
						fontSize: "14px",
					},
				},
				sizeSmall: {
					minHeight: "32px",
					lineHeight: "1.4",
					fontSize: "14px",
				},
				outlined: {
					color: "#151B34",
					borderWidth: "2px",
					":hover": {
						color: "#FFF",
						borderWidth: "2px",
					},
				},
				containedPrimary: {
					":disabled": {
						background: "#CED2EE",
						color: "#7E808F",
					},
					":hover": {
						background: "#3343A0",
					},
				},
				containedSecondary: {
					":disabled": {
						background: "#F5D4DB",
						color: "#7E808F",
					},
					":hover": {
						background: "#C73C58",
					},
				},
				outlinedPrimary: {
					borderColor: "#3343A0",
					":hover": {
						background: "#3343A0",
						borderColor: "#3343A0",
					},
					":disabled": {
						background: "#CED2EE",
						borderColor: "#CED2EE",
						color: "#7E808F",
					},
				},
				outlinedSecondary: {
					borderColor: "#C73C58",
					":hover": {
						background: "#C73C58",
						borderColor: "#C73C58",
					},
					":disabled": {
						background: "#F5D4DB",
						borderColor: "#F5D4DB",
						color: "#7E808F",
					},
				},
			},
			variants: [
				{
					props: {className: "byClassName"},
					style: {
						background: "yellow",
						color: "#000",
						":hover": {
							background: "blue",
						},
					},
				},
			],
		},
	},
};
