import React, {Fragment} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IModalLeagueJoinController} from "views/components/modals/modal_league_join/modal_league_join.controller";
import {Box, Button, IconButton, Modal, Stack, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useNavigate} from "react-router-dom";
import {Preloader} from "views/components/preloader";
import styled from "@emotion/styled";
import {getLoginUrl} from "data/utils";

const ModalContent = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	outline: none;
	max-width: 600px;
	width: 100%;
	background: #fff;
	padding: 20px;
	border-radius: 8px;

	@media screen and (max-width: 640px) {
		max-width: unset;
		left: 24px;
		right: 24px;
		width: auto;
		transform: translateY(-50%);
	}
`;

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const ModalLeagueJoin: React.FC = observer(() => {
	const {i18n, isOpen, league, isLoading, isAuthorized, close, setLeagueCode, joinLeague} =
		useViewController<IModalLeagueJoinController>(Bindings.ModalLeagueJoinController, {
			navigate: useNavigate(),
		});

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<CloseBlock>
					<IconButton sx={{minWidth: 0}} onClick={close}>
						<CloseIcon />
					</IconButton>
				</CloseBlock>
				<Box maxWidth={360} margin="auto">
					<Typography variant="h4" textAlign="center" mb={1}>
						{i18n.t(
							"modal.league_join.title",
							"Congrats, You’ve Been Invited To Join A League!"
						)}
					</Typography>
					{league ? (
						<Fragment>
							<Typography
								variant="body2"
								fontSize={{md: "16px"}}
								textAlign="center"
								fontWeight={700}
								mb={2}>
								{league.name}
							</Typography>
							<Stack
								p={1.5}
								borderRadius="4px"
								bgcolor="#F2F2F7"
								spacing={1.5}
								mb={2}>
								<Typography variant="h5">
									{i18n.t("modal.league_join.details", "League Details")}
								</Typography>
								<Box>
									<Typography
										variant="body2"
										fontSize={{md: "16px"}}
										fontWeight={700}>
										{i18n.t("modal.league_join.chairman", "Chairman")}
									</Typography>
									<Typography variant="body2" fontSize={{md: "16px"}}>
										{league.leagueManager?.displayName}
									</Typography>
								</Box>
								<Box>
									<Typography
										variant="body2"
										fontSize={{md: "16px"}}
										fontWeight={700}>
										{i18n.t("modal.league_join.starting_week", "Starting Week")}
									</Typography>
									<Typography variant="body2" fontSize={{md: "16px"}}>
										{i18n.t(
											"modal.league_join.game_week",
											"Gameweek {{week}}",
											{week: league.startId}
										)}
									</Typography>
								</Box>
								<Box>
									<Typography
										variant="body2"
										fontSize={{md: "16px"}}
										fontWeight={700}>
										{i18n.t("modal.league_join.privacy", "Privacy")}
									</Typography>
									<Typography variant="body2" fontSize={{md: "16px"}}>
										{i18n.t(
											`modal.league_join.${league.privacy}`,
											league.privacy
										)}
									</Typography>
								</Box>
							</Stack>
							{isAuthorized ? (
								<Button
									variant="contained"
									color="primary"
									fullWidth
									disabled={isLoading}
									onClick={joinLeague}>
									{i18n.t("modal.league_join.join", "Join League")}
								</Button>
							) : (
								<Button
									variant="contained"
									color="primary"
									fullWidth
									onClick={setLeagueCode}
									href={getLoginUrl("/team")}>
									{i18n.t("modal.league_join.join", "Join League")}
								</Button>
							)}
						</Fragment>
					) : (
						<Preloader />
					)}
				</Box>
			</ModalContent>
		</Modal>
	);
});
