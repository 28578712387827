import styled from "@emotion/styled";
import {ButtonBase} from "@mui/material";
import {ModalInfo} from "views/components/modals/common";

export const ModalProfileInfo = styled(ModalInfo)`
	padding: 0;
	padding-top: 20px;
	position: relative;
	z-index: 1;
`;

export const ModalProfileGame = styled.div`
	padding: 2px 6px;
	border-radius: 10px;
	background: ${({theme}) => theme.palette.primary.dark};
`;

export const ModalProfileButtons = styled.div`
	padding: 12px 16px;
	border-radius: 0px 0px 10px 10px;
	background-color: #fff;
`;

export const ModalProfileLoader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({theme}) => theme.palette.neutral.light};
	height: 148px;
`;

export const ModalProfileStats = styled.div`
	background-color: ${({theme}) => theme.palette.neutral.light};
	padding: 12px 16px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	row-gap: 12px;
	column-gap: 2px;
	min-height: 148px;

	&.preseason {
		grid-template-columns: repeat(1, 1fr);
		min-height: auto;
	}

	&.DEF,
	&.MID,
	&.FWD {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export const ModalProfileStat = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 56px;
	flex: 1;
	border-radius: 10px;
	background: #fff;
`;

export const ModalProfileResults = styled.div`
	background-color: ${({theme}) => theme.palette.neutral.light};
	padding: 12px 16px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	min-height: 148px;
	max-height: 268px;
	overflow-y: auto;
`;

export const ModalProfileResultsScrollGradient = styled.div`
	position: absolute;
	bottom: -1px;
	left: 0;
	width: 100%;
	height: 48px;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
	pointer-events: none;
`;

export const ModalProfileMatch = styled.div`
	border-radius: 10px;
	border: 1px solid ${({theme}) => theme.palette.neutral.main};
	background-color: #fff;
`;

export const ModalProfileMatchInner = styled.div`
	min-height: 58px;
	padding: 0 16px;
	display: grid;
	grid-template-columns: 1fr 2fr 3fr 2fr 1fr;
	align-items: center;
	transition: background-color 0.3s ease;
	border-radius: 10px 10px 0 0;

	&.open {
		background-color: rgba(0, 20, 137, 0.1);
	}
`;

export const ModalProfileMatchClubBadge = styled.img`
	height: 32px;
	width: 32px;
	object-fit: contain;
`;

export const ModalProfileMatchPoints = styled(ButtonBase)`
	border-radius: 10px;
	background-color: ${({theme}) => theme.palette.primary.main};
	color: #fff;
	padding: 2px 6px;
	text-align: center;
	margin: auto;
	align-items: center;
	gap: 4px;
	min-height: 28px;

	&.playing {
		background: ${({theme}) => theme.palette.success.main};
		color: ${({theme}) => theme.palette.text.primary};
	}
`;

export const ModalProfileMatchStat = styled.div`
	padding: 8px 16px;
	border-bottom: 1px solid #dedee1;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&:last-child {
		border-bottom: none;
	}
`;
