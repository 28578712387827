import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IPlayerProfileButtonsController} from "views/components/modals/modal_player_profile/player_profile_buttons/player_profile_buttons.controller";
import styled from "@emotion/styled";
import {Box, Button} from "@mui/material";
import {Exist} from "views/components/exist/exist.component";
import {ModalProfileButtons} from "views/components/modals/modal_profile_styles";

const CaptainedButton = styled(Button)`
	pointer-events: none;
	background-color: ${({theme}) => theme.palette.captain};
	color: ${({theme}) => theme.palette.primary.main};
`;

const ViceCaptainedButton = styled(Button)`
	pointer-events: none;
	background-color: ${({theme}) => theme.palette.viceCaptain};
	color: ${({theme}) => theme.palette.primary.main};
`;

const MaxCaptainedButton = styled(Button)`
	pointer-events: none;
	background: linear-gradient(
		180deg,
		#9e8120 0%,
		#c8ac4f 26%,
		#e6c760 42.5%,
		#e6c760 60.5%,
		#c8ac4f 74.5%,
		#9e8120 100%
	);
	color: ${({theme}) => theme.palette.primary.main};
`;

interface IProps {
	playerId: number;
}

export const PlayerProfileButtons: React.FC<IProps> = observer(({playerId}) => {
	const {
		i18n,
		isInTeam,
		isCaptain,
		isViceCaptain,
		isMaxCaptain,
		isDisabled,
		isCaptainDisabled,
		isViceCaptainDisabled,
		className,
		addPlayer,
		removePlayer,
		selectCaptain,
		selectViceCaptain,
	} = useViewController<IPlayerProfileButtonsController>(
		Bindings.PlayerProfileButtonsController,
		{playerId}
	);

	return (
		<ModalProfileButtons className={className}>
			<Exist when={isInTeam}>
				<Box display="flex" justifyContent="space-between" gap={1.5} mb={1.5}>
					<Exist when={!isCaptain && !isMaxCaptain}>
						<Button
							variant="outlined"
							color="secondary"
							fullWidth
							onClick={selectCaptain}
							disabled={isCaptainDisabled}>
							{i18n.t("player_profile.buttons.captain", "Captain")}
						</Button>
					</Exist>
					<Exist when={isCaptain}>
						<CaptainedButton variant="outlined" color="primary" fullWidth>
							{i18n.t("player_profile.buttons.captained", "Captained")}
						</CaptainedButton>
					</Exist>
					<Exist when={isMaxCaptain}>
						<MaxCaptainedButton variant="outlined" color="primary" fullWidth>
							{i18n.t("player_profile.buttons.captained", "Captained")}
						</MaxCaptainedButton>
					</Exist>
					<Exist when={!isViceCaptain}>
						<Button
							variant="outlined"
							color="secondary"
							fullWidth
							onClick={selectViceCaptain}
							disabled={isViceCaptainDisabled}>
							{i18n.t("player_profile.buttons.vice_captain", "Vice Captain")}
						</Button>
					</Exist>
					<Exist when={isViceCaptain}>
						<ViceCaptainedButton variant="outlined" color="primary" fullWidth>
							{i18n.t("player_profile.buttons.vice_captained", "Vice Captained")}
						</ViceCaptainedButton>
					</Exist>
				</Box>
			</Exist>
			<Exist when={isInTeam}>
				<Button
					variant="contained"
					color="primary"
					fullWidth
					onClick={removePlayer}
					disabled={isDisabled}>
					{i18n.t("player_profile.buttons.remove", "Remove Player")}
				</Button>
			</Exist>
			<Exist when={!isInTeam}>
				<Button
					variant="contained"
					color="primary"
					fullWidth
					onClick={addPlayer}
					disabled={isDisabled}>
					{i18n.t("player_profile.buttons.add", "Add Player")}
				</Button>
			</Exist>
		</ModalProfileButtons>
	);
});
