import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {isNull, isNumber} from "lodash";
import type {IRankingsStore} from "data/stores/rankings/rankings.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {GameStatus, ModalType, RoundStatus} from "data/enums";

interface IParam {
	squadId: number;
}

export interface IOtherUserSquadCardController extends ViewController<IParam> {
	get squad(): ISquad | undefined;
	get points(): number | string;
	get className(): string;

	handleClick: (e: React.SyntheticEvent<HTMLDivElement>) => void;
}

@injectable()
export class OtherUserSquadCardController implements IOtherUserSquadCardController {
	@observable private _squadId = 0;

	get squad() {
		return this._squadsStore.getSquadById(this._squadId);
	}

	get roundId() {
		return isNumber(this._rankingsStore.ladderType)
			? this._rankingsStore.ladderType
			: this._roundsStore.scoreRound?.id;
	}

	get points() {
		if (!this.roundId) {
			return "-";
		}
		const points = this._teamStore.getSquadRoundPoints(this._squadId, this.roundId);
		return isNull(points) ? "-" : points;
	}

	private get match() {
		if (!this._squadId) {
			return;
		}
		const match = this._roundsStore.getRoundFixture(this._squadId);
		if (!match) {
			return;
		}
		const isMatchComplete = match.status === GameStatus.Complete;
		const isMatchPlaying = match.status === GameStatus.Playing;
		const isMatchAvailable = isMatchComplete || isMatchPlaying;
		if (isMatchAvailable) {
			return match;
		}
	}

	private get isRoundPlaying() {
		if (!this.roundId) {
			return false;
		}
		const round = this._roundsStore.getRoundById(this.roundId);
		return round?.status === RoundStatus.Playing;
	}

	private get isPlaying() {
		if (!this.squad) {
			return false;
		}
		return this.isRoundPlaying && this._roundsStore.isSquadPlaying(this.squad.id);
	}

	get className() {
		if (this.isPlaying) {
			return "playing";
		}
		return "";
	}

	constructor(
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action handleClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
		if (!this._squadId) {
			return;
		}
		if (this.match) {
			this._modalsStore.showModal(ModalType.SQUAD_PROFILE, {
				squadId: this._squadId,
				matchId: this.match.id,
				hideButtons: true,
			});
			return;
		}
		this._modalsStore.showModal(ModalType.SQUAD_PROFILE, {
			squadId: this._squadId,
			hideButtons: true,
		});
	};

	init(param: IParam): void {
		this._squadId = param.squadId;
	}

	onChange(param: IParam): void {
		this._squadId = param.squadId;
	}
}
