import {Bindings} from "data/constants/bindings";
import {PlayerPoolType} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {compact} from "lodash";
import {makeAutoObservable} from "mobx";

export interface IPlayerPoolController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isPlayerPoolVisible(): boolean;
	get playerPoolClass(): string;
	get playerPoolType(): PlayerPoolType;
	get playersSelected(): number;
	get squadsSelected(): number;
	get searchExpanded(): boolean;
	get title(): string;
	get searchTitle(): string;
	get isGridView(): boolean;
	openSearch: () => void;
	closeSearch: () => void;
	closePool: () => void;
	toggleGridView: () => void;
}

@injectable()
export class PlayerPoolController implements IPlayerPoolController {
	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	get isPlayerPoolVisible(): boolean {
		return this._teamBuilderStore.isPlayerPoolVisible;
	}

	get playerPoolClass(): string {
		if (this.isPlayerPoolVisible) {
			return "visible";
		}

		return "";
	}

	get playerPoolType(): PlayerPoolType {
		return this._teamBuilderStore.playerPoolType;
	}

	get playersSelected(): number {
		return compact(this._teamStore.lineupIDs).length;
	}

	get squadsSelected(): number {
		return compact(this._teamStore.lineupSquads).length;
	}

	get searchExpanded(): boolean {
		return this._teamBuilderStore.isPoolSearchVisible;
	}

	get title(): string {
		return this.playerPoolType === PlayerPoolType.Player
			? this.i18n.t("player_pool.header.title", "Player Pool")
			: this.i18n.t("player_pool.header.club_title", "Club Pool");
	}

	get searchTitle(): string {
		return this.playerPoolType === PlayerPoolType.Player
			? this.i18n.t("player_pool.header.search", "Search for a player")
			: this.i18n.t("player_pool.header.club_search", "Search for a club");
	}

	get isGridView(): boolean {
		return this._teamBuilderStore.isPoolGridView;
	}

	openSearch = () => {
		this._teamBuilderStore.openSearch();
	};

	closeSearch = () => {
		this._teamBuilderStore.closeSearch();
	};

	closePool = () => {
		this._teamBuilderStore.closePlayerPool();
	};

	toggleGridView = () => {
		this._teamBuilderStore.isPoolGridView = !this._teamBuilderStore.isPoolGridView;
	};
}
