import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {
	IPoolFilters,
	ISelectOption,
	ITeamBuilderStore,
} from "data/stores/team_builder/team_builder.store";
import {SelectChangeEvent} from "@mui/material";
import {isArray} from "lodash";
import type {ICompetition, ICompetitionsStore} from "data/stores/competitions/competitions.store";

export interface IPlayerPoolSquadFiltersController extends ViewController {
	readonly i18n: ILocalizationStore;

	get expanded(): boolean;
	get filters(): IPoolFilters;
	get competitions(): ICompetition[];
	get statsOptions(): ISelectOption[];
	get isResetDisabled(): boolean;

	updateFilter: (e: SelectChangeEvent | SelectChangeEvent<unknown>) => void;
	renderCompetitionValue: (value: unknown) => string;
	resetFilters: () => void;
}

@injectable()
export class PlayerPoolSquadFiltersController implements IPlayerPoolSquadFiltersController {
	constructor(
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.CompetitionsStore) private _competitionsStore: ICompetitionsStore
	) {
		makeAutoObservable(this);
	}

	get expanded(): boolean {
		return this._teamBuilderStore.isPoolFiltersVisible;
	}

	get competitions(): ICompetition[] {
		return this._competitionsStore.list;
	}

	get statsOptions() {
		return this._teamBuilderStore.statsOptions;
	}

	get filters(): IPoolFilters {
		return this._teamBuilderStore.filters;
	}

	get isResetDisabled(): boolean {
		return this._teamBuilderStore.selectedFiltersLength === 0;
	}

	public updateFilter = (e: SelectChangeEvent | SelectChangeEvent<unknown>) => {
		this._teamBuilderStore.updateFilter(e);
	};

	public renderCompetitionValue = (value: unknown) => {
		const selected = value as string[];
		if (!selected || selected.length === 0 || selected[0] === "null") {
			return this.i18n.t("player_pool.league_filter.all");
		}

		const competitions = this.competitions
			.filter((competition) => selected.includes(competition.id.toString()))
			.map((competition) => competition.name);
		return isArray(selected) ? competitions.join(", ") : selected;
	};

	public resetFilters = () => {
		this._teamBuilderStore.resetFilters();
	};
}
