/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {ThemeOptions} from "@mui/material/styles";

export const componentsTheme: ThemeOptions = {
	components: {
		MuiPaper: {
			styleOverrides: {
				root: {
					borderRadius: "10px",
					border: "1px solid #DEDEE1",
					boxShadow: "none",
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: "#0E132F",
					color: "#fff",
					fontSize: "12px",
					lineHeight: "135%",
					fontFamily: "var(--fontFamilyBase)",
				},
				arrow: {
					color: "#0E132F",
				},
			},
			defaultProps: {
				arrow: true,
				enterTouchDelay: 0,
			},
		},
		MuiAlert: {
			styleOverrides: {
				root: {
					borderRadius: "4px",
					border: "none",
					boxShadow:
						"0px 8px 16px 0px rgba(28, 28, 30, 0.20), 0px 1px 2px 0px rgba(28, 28, 30, 0.10)",
					padding: "12px 16px",
					color: "#0E132F",
				},
			},
		},
		MuiBadge: {
			styleOverrides: {
				badge: {
					height: "16px",
					minWidth: "16px",
					fontFamily: "var(--fontFamilyBase)",
					fontSize: "8px",
					border: "1px solid #FFF",
					"&.MuiBadge-anchorOriginTopRight": {
						top: "4px",
						right: "4px",
					},
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				indicator: {
					backgroundColor: "#BA0C2F",
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					fontWeight: 700,
					padding: "12px 16px",
					color: "#0E132F",
					flex: "1",
					textTransform: "none",
					"&.Mui-selected": {
						color: "#001489",
					},
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					"&:last-of-type .MuiTableCell-root": {
						borderBottom: "none",
					},
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					height: "52px",
					padding: "10px",
				},

				stickyHeader: {
					background: "#0E132F",
					color: "#fff",
					borderBottom: "none",

					"&:first-of-type": {
						borderTopLeftRadius: "10px",
					},
					"&:last-of-type": {
						borderTopRightRadius: "10px",
					},

					"@media (max-width:899px)": {
						"&:first-of-type": {
							borderTopLeftRadius: "0",
						},
						"&:last-of-type": {
							borderTopRightRadius: "0",
						},
					},
				},
			},
			variants: [
				{
					props: {className: "rank"},
					style: {
						paddingLeft: "16px",
						width: "60px",
					},
				},
				{
					props: {className: "name"},
					style: {
						maxWidth: 0,
					},
				},
				{
					props: {className: "week-points"},
					style: {
						width: "90px",
					},
				},
				{
					props: {className: "points"},
					style: {
						paddingRight: "16px",
						width: "100px",
					},
				},
			],
		},
	},
};
