import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IModalPlayerProfileController} from "views/components/modals/modal_player_profile/modal_player_profile.controller";
import {ReactComponent as CloseIcon} from "assets/img/icons/close.svg";
import {Box, IconButton, Modal, Tab, Tabs, Typography} from "@mui/material";
import {CloseBlock, ModalContent, ModalImage} from "views/components/modals/common";
import {PlayerStatusIcon} from "views/components/player_status_icon/player_status_icon.component";
import styled from "@emotion/styled";
import {PlayerProfileButtons} from "./player_profile_buttons/player_profile_buttons.component";
import {Exist} from "views/components/exist/exist.component";
import {ReactComponent as CaptainIcon} from "assets/img/icons/captain.svg";
import {ReactComponent as ViceCaptainIcon} from "assets/img/icons/vice_captain.svg";
import {ReactComponent as MaxCaptainIcon} from "assets/img/icons/max_captain.svg";
import {PlayerProfileOverview} from "./player_profile_overview/player_profile_overview.component";
import {PlayerProfileFantasyStats} from "./player_profile_fantasy_stats/player_profile_fantasy_stats.component";
import {PlayerProfileResults} from "./player_profile_results/player_profile_results.component";
import {ModalProfileGame, ModalProfileInfo} from "views/components/modals/modal_profile_styles";
import {ProfileTab} from "data/enums";

const ShirtWrapper = styled.div`
	text-align: center;
	margin-bottom: -70px;
`;

export const ModalPlayerProfile: React.FC = observer(() => {
	const {
		i18n,
		isOpen,
		player,
		position,
		squad,
		competition,
		nextFixtures,
		isCaptain,
		isViceCaptain,
		isMaxCaptain,
		className,
		activeTab,
		isPreseason,
		hideButtons,
		close,
		handleChangeTab,
	} = useViewController<IModalPlayerProfileController>(Bindings.ModalPlayerProfileController);

	if (!player) {
		return null;
	}

	return (
		<Modal open={isOpen} onClose={close}>
			<ModalContent>
				<ModalImage className={className}>
					<CloseBlock>
						<IconButton sx={{minWidth: 0}} onClick={close}>
							<CloseIcon />
						</IconButton>
					</CloseBlock>

					<ShirtWrapper>
						{squad && <img src={squad.jersey} alt={player.displayName} height="150" />}
					</ShirtWrapper>
				</ModalImage>

				<ModalProfileInfo>
					<Box px={2} py={1.5}>
						<Box display="flex" alignItems="center" gap="6px">
							<Exist when={isMaxCaptain}>
								<MaxCaptainIcon width={20} height={20} />
							</Exist>
							<Exist when={isCaptain}>
								<CaptainIcon width={20} height={20} />
							</Exist>
							<Exist when={isViceCaptain}>
								<ViceCaptainIcon width={20} height={20} />
							</Exist>
							<PlayerStatusIcon status={player.status} size={20} />
							<Typography variant="body2" fontWeight={700}>
								{player.firstName} {player.lastName}
							</Typography>
						</Box>
						<Typography variant="body3" mb={1}>
							{position} / {squad?.name} / {competition?.name}
						</Typography>
						<Box display="flex" gap="6px">
							{nextFixtures.map((game) => (
								<ModalProfileGame key={game}>
									<Typography variant="body2" color="#FFF">
										vs {game}
									</Typography>
								</ModalProfileGame>
							))}
						</Box>
					</Box>

					<Exist when={!isPreseason}>
						<Tabs value={activeTab} onChange={handleChangeTab}>
							<Tab
								value={ProfileTab.Overview}
								label={i18n.t("player_profile.tabs.overview", "Overview")}
							/>
							<Tab
								value={ProfileTab.Stats}
								label={i18n.t("player_profile.tabs.fantasy_stats", "Fantasy Stats")}
							/>
							<Tab
								value={ProfileTab.Results}
								label={i18n.t("player_profile.tabs.results", "Results")}
							/>
						</Tabs>
					</Exist>

					<Exist when={activeTab === ProfileTab.Overview}>
						<PlayerProfileOverview player={player} />
					</Exist>
					<Exist when={activeTab === ProfileTab.Stats}>
						<PlayerProfileFantasyStats player={player} />
					</Exist>
					<Exist when={activeTab === ProfileTab.Results}>
						<PlayerProfileResults player={player} />
					</Exist>

					<Exist when={!hideButtons}>
						<PlayerProfileButtons playerId={player.id} />
					</Exist>
				</ModalProfileInfo>
			</ModalContent>
		</Modal>
	);
});
