export const Bindings = {
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	UserApiProvider: Symbol("UserApiProvider"),
	LeaguesApiProvider: Symbol("LeaguesApiProvider"),
	PasswordApiProvider: Symbol("PasswordApiProvider"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	CountriesStore: Symbol("CountriesStore"),
	RoundsStore: Symbol("RoundsStore"),
	PlayersStore: Symbol("PlayersStore"),
	SquadsStore: Symbol("SquadsStore"),
	UserStore: Symbol("UserStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	AuthController: Symbol("AuthController"),
	MyAccountController: Symbol("MyAccountController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	ChecksumStore: Symbol("ChecksumStore"),
	LiveScoreController: Symbol("LiveScoreController"),
	SessionController: Symbol("SessionController"),
	BootstrapController: Symbol("BootstrapController"),
	LeaguesStore: Symbol("LeaguesStore"),
	MyLeaguesController: Symbol("MyLeaguesController"),
	JoinLeaguesController: Symbol("JoinLeaguesController"),
	CreateLeagueController: Symbol("CreateLeagueController"),
	LeagueController: Symbol("LeagueController"),
	LeagueInviteController: Symbol("LeagueInviteController"),
	LeagueSettingsController: Symbol("LeagueSettingsController"),
	LeagueAboutController: Symbol("LeagueAboutController"),
	LeagueInviteFormController: Symbol("LeagueInviteFormController"),
	ModalsStore: Symbol("ModalsStore"),
	ModalErrorController: Symbol("ModalErrorController"),
	ModalConfirmController: Symbol("ModalConfirmController"),
	LocalizationController: Symbol("LocalizationController"),
	NavigationController: Symbol("NavigationController"),
	SnackbarController: Symbol("SnackbarController"),
	SnackbarStore: Symbol("SnackbarStore"),
	LeaguesSearchController: Symbol("LeaguesSearchController"),
	LeagueCardController: Symbol("LeagueCardController"),
	JoinLeaguesCardController: Symbol("JoinLeaguesCardController"),
	TeamController: Symbol("TeamController"),
	TeamStore: Symbol("TeamStore"),
	LineupController: Symbol("LineupController"),
	TeamBuilderStore: Symbol("TeamBuilderStore"),
	PlayerPoolController: Symbol("PlayerPoolController"),
	PlayerPoolFiltersController: Symbol("PlayerPoolFiltersController"),
	PlayerPoolCardController: Symbol("PlayerPoolCardController"),
	TeamApiProvider: Symbol("TeamApiProvider"),
	PlayerCardController: Symbol("PlayerCardController"),
	TeamCreateStepsController: Symbol("TeamCreateStepsController"),
	PlayerPoolSquadCardController: Symbol("PlayerPoolSquadCardController"),
	SquadCardController: Symbol("SquadCardController"),
	TeamActionButtonsController: Symbol("TeamActionButtonsController"),
	PlayerPoolPlayersController: Symbol("PlayerPoolPlayersController"),
	PlayerPoolSquadsController: Symbol("PlayerPoolSquadsController"),
	CompetitionsStore: Symbol("CompetitionsStore"),
	PlayerPoolSearchController: Symbol("PlayerPoolSearchController"),
	TeamMessageStore: Symbol("TeamMessageStore"),
	TeamMessageBarController: Symbol("TeamMessageBarController"),
	ModalTeamSavedController: Symbol("ModalTeamSavedController"),
	PlayerPoolSquadFiltersController: Symbol("PlayerPoolSquadFiltersController"),
	LeagueNavigationController: Symbol("LeagueNavigationController"),
	ModalPlayerProfileController: Symbol("ModalPlayerProfileController"),
	PlayerProfileButtonsController: Symbol("PlayerProfileButtonsController"),
	PlayerProfileOverviewController: Symbol("PlayerProfileOverviewController"),
	PlayerProfileFantasyStatsController: Symbol("PlayerProfileFantasyStatsController"),
	PlayerProfileResultsController: Symbol("PlayerProfileResultsController"),
	PlayerProfileMatchController: Symbol("PlayerProfileMatchController"),
	BackdoorController: Symbol("BackdoorController"),
	ModalRegistrationController: Symbol("ModalRegistrationController"),
	ModalLeagueJoinController: Symbol("ModalLeagueJoinController"),
	TeamInfoController: Symbol("TeamInfoController"),
	RankingsApiProvider: Symbol("RankingsApiProvider"),
	RankingsStore: Symbol("RankingsStore"),
	LeagueTableController: Symbol("LeagueTableController"),
	RankingsRowController: Symbol("RankingsRowController"),
	OtherUserTeamController: Symbol("OtherUserTeamController"),
	OtherUserPlayerCardController: Symbol("OtherUserPlayerCardController"),
	OtherUserSquadCardController: Symbol("OtherUserSquadCardController"),
	ModalSaveTeamController: Symbol("ModalSaveTeamController"),
	ModalSquadProfileController: Symbol("ModalSquadProfileController"),
	SquadProfileOverviewController: Symbol("SquadProfileOverviewController"),
	SquadProfileFantasyStatsController: Symbol("SquadProfileFantasyStatsController"),
	SquadProfileButtonsController: Symbol("SquadProfileButtonsController"),
	SquadProfileResultsController: Symbol("SquadProfileResultsController"),
	SquadProfileMatchController: Symbol("SquadProfileMatchController"),
	TimerController: Symbol("TimerController"),
	HowToScoreController: Symbol("HowToScoreController"),
	RankingsController: Symbol("RankingsController"),
	ModalTutorialController: Symbol("ModalTutorialController"),
	PrizesController: Symbol("PrizesController"),
	LiveScoresStore: Symbol("LiveScoresStore"),
	LandingController: Symbol("LandingController"),
	LegendController: Symbol("LegendController"),
	PlayerPoolControlsController: Symbol("PlayerPoolControlsController"),
	PlayerPoolLeagueFilterController: Symbol("PlayerPoolLeagueFilterController"),
	DashboardController: Symbol("DashboardController"),
	FixturesController: Symbol("FixturesController"),
	FixtureController: Symbol("FixtureController"),
	LeagueFilterController: Symbol("LeagueFilterController"),
	ModalCaptainSelectController: Symbol("ModalCaptainSelectController"),
	ModalBoostersController: Symbol("ModalBoostersController"),
	ModalClubCapController: Symbol("ModalClubCapController"),
	OverallRankingsCardController: Symbol("OverallRankingsCardController"),
	NativeAppInstallPromptController: Symbol("NativeAppInstallPromptController"),
};
