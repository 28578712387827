import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IModalRegistrationController} from "views/components/modals/modal_registration/modal_registration.controller";
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	MenuItem,
	Modal,
	Stack,
	Typography,
} from "@mui/material";
import {ModalContent, ModalImage, ModalInfo} from "views/components/modals/common";
import {Input, Select} from "views/components/form";
import {Exist} from "views/components/exist/exist.component";
import {Preloader} from "views/components/preloader";
import {useLocation} from "react-router-dom";
import logo from "assets/img/logos/efl_badge.svg";
import {InputIcon} from "views/components/input_icon/input_icon.component";

export const ModalRegistration: React.FC = observer(() => {
	const location = useLocation();
	const {
		i18n,
		isOpen,
		formValue,
		error,
		teamNameError,
		isLoading,
		isDisabled,
		squads,
		isCheckingTeamName,
		isTeamNameAvailable,
		handleFormChange,
		teamNameChange,
		supportedTeamChange,
		register,
	} = useViewController<IModalRegistrationController>(Bindings.ModalRegistrationController, {
		pathname: location.pathname,
	});

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<ModalImage>
					<img src={logo} alt="EFL" />
				</ModalImage>

				<ModalInfo>
					<Typography variant="h4" textAlign="center" mb={1}>
						{i18n.t("terms.modal.title", "Welcome to EFL Fantasy")}
					</Typography>
					<Typography variant="body2" textAlign="center" mb={1.5}>
						{i18n.t(
							"terms.modal.description",
							"To get started give yourself a display name and accept our game terms to be eligible to win <Prizes>"
						)}
					</Typography>

					<form onChange={handleFormChange}>
						<Stack spacing={1.5}>
							<FormControl fullWidth variant="filled">
								<Input
									label={i18n.t("terms.modal.team_name", "Name your team")}
									name="teamName"
									type="text"
									placeholder={i18n.t("terms.modal.team_name", "Name your team")}
									required={true}
									value={formValue.teamName}
									onChange={teamNameChange}
									helperText={teamNameError}
									error={Boolean(teamNameError)}
									InputProps={{
										endAdornment: (
											<InputIcon
												isValid={isTeamNameAvailable}
												isInvalid={Boolean(teamNameError)}
												isLoading={isCheckingTeamName}
											/>
										),
									}}
									inputProps={{
										minLength: 3,
										maxLength: 40,
									}}
								/>
							</FormControl>
							<FormControl fullWidth variant="filled">
								<Select
									label={i18n.t("terms.modal.team_supported", "Team Supported")}
									name="teamSupportedId"
									required={true}
									value={formValue.teamSupportedId}
									onChange={supportedTeamChange}>
									{squads.map((squad) => (
										<MenuItem key={squad.id} value={squad.id}>
											{squad.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<FormGroup>
								<FormControlLabel
									control={<Checkbox checked={formValue.terms} name="terms" />}
									label={
										<Typography
											variant="caption"
											sx={{a: {color: "primary.main"}}}
											dangerouslySetInnerHTML={{
												__html: i18n.t(
													"terms.modal.terms",
													'I have read and accepted the <a href="/help/terms-and-conditions" target="_blank" rel="noreferrer">Terms & Conditions</a>'
												),
											}}
										/>
									}
								/>
							</FormGroup>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={formValue.marketingOptIn}
											name="marketingOptIn"
										/>
									}
									label={
										<Typography variant="caption">
											{i18n.t(
												"terms.modal.marketing",
												"I wish to receive email and marketing"
											)}
										</Typography>
									}
								/>
							</FormGroup>

							<Exist when={error}>
								<Typography color="error" variant="caption" textAlign="center">
									{error}
								</Typography>
							</Exist>

							<Button
								fullWidth
								disabled={isDisabled}
								onClick={register}
								variant="contained"
								color="primary">
								<Exist when={isLoading}>
									<Preloader />
								</Exist>
								<Exist when={!isLoading}>
									{i18n.t("terms.modal.confirm", "Join Now")}
								</Exist>
							</Button>
						</Stack>
					</form>
				</ModalInfo>
			</ModalContent>
		</Modal>
	);
});
