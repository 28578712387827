import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {action, computed, makeAutoObservable, observable, reaction} from "mobx";
import {type IChecksums, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type IPlayersStore} from "data/stores/players/players.store";
import {type ISquadsStore} from "data/stores/squads/squads.store";
import {type ICompetitionsStore} from "data/stores/competitions/competitions.store";
import type {ILiveScoresStore} from "data/stores/live_scores/live_scores.store";
import {isEqual} from "lodash";
import {RoundStatus} from "data/enums";

/**
 * Constant for determine update frequency.
 */
const LIVE_SCORING_FETCH_TIMING = 1000 * 60;

type IChecksumAction = Record<keyof IChecksums, () => Promise<void>>;

export interface ILiveScoreController extends ViewController {
	subscribeLiveScoring: () => void;
	unsubscribeLiveScoring: () => void;
}

@injectable()
export class LiveScoreController implements ILiveScoreController {
	protected readonly _actions: IChecksumAction;
	@observable protected _interval?: ReturnType<typeof setInterval>;
	@observable protected _isSubscribed: boolean = false;
	@observable protected _liveScoreDisposer?: ReturnType<typeof reaction>;
	@observable protected _subscriptionDisposer?: ReturnType<typeof reaction>;

	get updatedChecksum(): IChecksums {
		return this._checksumStore.changedChecksums;
	}

	@computed private get isLive() {
		return isEqual(this._roundsStore.currentRound?.status, RoundStatus.Playing);
	}

	get roundId() {
		return this._roundsStore.currentRound?.id ?? 1;
	}

	constructor(
		@inject(Bindings.ChecksumStore) private _checksumStore: IChecksumStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.CompetitionsStore) private _competitionsStore: ICompetitionsStore,
		@inject(Bindings.LiveScoresStore) private _liveScoresStore: ILiveScoresStore
	) {
		makeAutoObservable(this);
		this._actions = this.generateActions();
	}

	/**
	 * Call to subscribe on checksum changes
	 */
	@action
	public subscribeLiveScoring() {
		if (this._isSubscribed) {
			return;
		}

		this._isSubscribed = true;

		void this._checksumStore.fetchChecksums();

		this._interval = setInterval(() => {
			void this._checksumStore.fetchChecksums();
		}, LIVE_SCORING_FETCH_TIMING);
	}

	/**
	 * Stop checking changes
	 * called on dispose
	 * or you can call it when you want to stop listen checksums, for example on the end of the game match/round/etc.
	 */
	@action
	public unsubscribeLiveScoring() {
		this._isSubscribed = false;

		if (this._interval) {
			clearInterval(this._interval);
		}
	}

	/**
	 * Check changed checksums and call actions
	 */
	@action
	callActions = () => {
		Object.keys(this.updatedChecksum).forEach((key) => {
			const action = this._actions[key];
			if (action && typeof action === "function") {
				void action();
			}
		});
	};

	/**
	 * Provide object of files you want to update
	 * for example: rounds
	 */
	private generateActions(): IChecksumAction {
		return {
			fantasyRounds: () => this._roundsStore.fetchRounds(),
			fantasyPlayers: () => this._playersStore.fetchPlayers(),
			fantasySquads: () => this._squadsStore.fetchSquads(),
			fantasyCompetitions: () => this._competitionsStore.fetchCompetitions(),
			fantasyLiveScores: () => this._liveScoresStore.fetchLiveScores(this.roundId),
		};
	}

	dispose(): void {
		this._liveScoreDisposer?.();
		this._subscriptionDisposer?.();
		this.unsubscribeLiveScoring();
	}

	init() {
		this._subscriptionDisposer = reaction(
			() => this.isLive,
			() => {
				this.unsubscribeLiveScoring();

				if (this.isLive) {
					this.subscribeLiveScoring();
				}
			},
			{fireImmediately: true}
		);

		// Subscribe on changes
		this._liveScoreDisposer = reaction(() => this.updatedChecksum, this.callActions, {
			fireImmediately: true,
		});
	}
}
