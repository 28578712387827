import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {RequestState} from "data/enums";
import {ChangeEvent, SyntheticEvent} from "react";
import {isEqual} from "lodash";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {IUserStore} from "data/stores/user/user.store";
import {MuiTelInputInfo, matchIsValidTel} from "mui-tel-input";

interface IRegistrationFormElement extends HTMLFormElement {
	firstName: HTMLInputElement;
	email: HTMLInputElement;
	phone: HTMLInputElement;
}

export interface ILandingController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isFormDisabled(): boolean;
	get error(): Record<string, string> | null;
	get phone(): string;
	get isRegistered(): boolean;

	handleFormSubmit: (event: SyntheticEvent<IRegistrationFormElement>) => void;
	handleFormOnChange: (event: ChangeEvent<IRegistrationFormElement>) => void;
	handleChangePhone: (value: string, info: MuiTelInputInfo) => void;
}

@injectable()
export class LandingController implements ILandingController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _phone = "";
	@observable private _isRegistered = localStorage.getItem("preRegistered") === "true";

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled(): boolean {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get phone() {
		return this._phone;
	}

	get isRegistered() {
		return this._isRegistered;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@action private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action public handleFormSubmit = (event: SyntheticEvent<IRegistrationFormElement>) => {
		event.preventDefault();

		const {firstName, email, phone} = event.currentTarget;

		const errorInvalidEmail = this.i18n.t(
			"pre_reg.field.email_error",
			"Please provide a valid email address"
		);
		const errorInvalidFirstName = this.i18n.t(
			"pre_reg.field.first_name_error",
			"Please provide your name"
		);
		const errorPhone = this.i18n.t(
			"pre_reg.field.number_error",
			"Please provide a valid phone number"
		);

		const validateList = [
			{field: firstName, error: errorInvalidFirstName, place: "firstName"},
			{field: email, error: errorInvalidEmail, place: "email"},
			{field: phone, error: errorPhone, place: "phone"},
		];

		const hasError = validateList.find(({field, error, place}) =>
			field.checkValidity() ? false : this.reportError(error, place)
		);

		if (hasError) {
			return;
		}

		if (matchIsValidTel(phone.value) === false) {
			return this.reportError(errorPhone, "phone");
		}

		const payload = {
			firstName: firstName.value,
			email: email.value,
			phone: phone.value,
		};

		this._requestState = RequestState.PENDING;
		this._userStore.preRegister(payload).then(this.onSuccess).catch(this.onError);
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
		this._isRegistered = true;
		localStorage.setItem("preRegistered", "true");
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};

	@action handleFormOnChange = (event: ChangeEvent<IRegistrationFormElement>) => {
		this._errorMsg = null;
		this._errorPlace = "";
	};

	@action handleChangePhone = (value: string, info: MuiTelInputInfo) => {
		this._phone = value;
	};
}
