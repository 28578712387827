import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import {LeaguePrivacy, LeagueType, ModalType, RequestState} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {useNavigate} from "react-router";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import {isEqual} from "lodash";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

interface IParam {
	league: ILeague;
	navigate: ReturnType<typeof useNavigate>;
}

export interface IJoinLeaguesCardController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get isLoading(): boolean;
	get label(): string;

	joinLeague: () => void;
}

@injectable()
export class JoinLeaguesCardController implements IJoinLeaguesCardController {
	@observable private _league: ILeague | null = null;
	@observable private _requestState = RequestState.IDLE;
	@observable private _navigate!: ReturnType<typeof useNavigate>;

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get label() {
		if (!this._league) {
			return "";
		}

		if (this._league.class === LeagueType.CLUB) {
			return this.i18n.t("league.label.club", "Club");
		}

		if (this._league.class === LeagueType.CELEBRITY) {
			return this.i18n.t("league.type.celebrity", "Celebrity");
		}

		if (this._league.privacy === LeaguePrivacy.PUBLIC) {
			return this.i18n.t("join_leagues.privacy.public", "Public");
		}

		return this.i18n.t("join_leagues.privacy.private", "Private");
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action joinLeague = () => {
		const code = this._league?.code;

		if (!code) return;

		this._requestState = RequestState.PENDING;

		this._leaguesStore
			.joinToLeague({code})
			.then(() => this.redirectToMyLeagueByCode())
			.catch(this.onError);
	};

	private redirectToMyLeagueByCode = (replace = false) => {
		const id = this._league?.id;
		if (id) this._navigate(`/league/${id}/`, {replace});
	};

	@action init(param: IParam): void {
		this._league = param.league;
		this._navigate = param.navigate;
	}

	@action onChange(param: IParam): void {
		this._league = param.league;
		this._navigate = param.navigate;
	}
}
