import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IModalSquadProfileController} from "views/components/modals/modal_squad_profile/modal_squad_profile.controller";
import {ReactComponent as CloseIcon} from "assets/img/icons/close.svg";
import {Box, IconButton, Modal, Tab, Tabs, Typography} from "@mui/material";
import {CloseBlock, ModalContent, ModalImage} from "views/components/modals/common";
import styled from "@emotion/styled";
import {Exist} from "views/components/exist/exist.component";
import {SquadProfileOverview} from "views/components/modals/modal_squad_profile/squad_profile_overview/squad_profile_overview.component";
import {SquadProfileFantasyStats} from "views/components/modals/modal_squad_profile/squad_profile_fantasy_stats/squad_profile_fantasy_stats.component";
import {SquadProfileButtons} from "views/components/modals/modal_squad_profile/squad_profile_buttons/squad_profile_buttons.component";
import {ModalProfileGame, ModalProfileInfo} from "views/components/modals/modal_profile_styles";
import {SquadProfileResults} from "views/components/modals/modal_squad_profile/squad_profile_results/squad_profile_results.component";
import {LeagueIcon} from "views/components/league_icon/league_icon.component";
import {ProfileTab} from "data/enums";
import background from "assets/img/modal_shape_squad_profile.svg";

const StyledModalImage = styled(ModalImage)`
	background-image: url(${background});
	height: 178px;
	padding-top: 7%;
`;

export const ModalSquadProfile: React.FC = observer(() => {
	const {
		i18n,
		isOpen,
		squad,
		competition,
		nextFixtures,
		isPreseason,
		activeTab,
		hideButtons,
		close,
		handleChangeTab,
	} = useViewController<IModalSquadProfileController>(Bindings.ModalSquadProfileController);

	if (!squad) {
		return null;
	}

	return (
		<Modal open={isOpen} onClose={close}>
			<ModalContent>
				<StyledModalImage>
					<CloseBlock>
						<IconButton sx={{minWidth: 0}} onClick={close}>
							<CloseIcon />
						</IconButton>
					</CloseBlock>

					<img src={squad.darkBadge} alt={squad.name} height="121" />
				</StyledModalImage>

				<ModalProfileInfo>
					<Box px={2} py={1.5}>
						<Box display="flex" alignItems="center" gap="6px">
							<Typography variant="body2" fontWeight={700}>
								{squad.name}
							</Typography>
						</Box>
						{competition && (
							<Box display="flex" alignItems="center" gap={0.5} mb={1}>
								<Typography variant="body3">{competition?.name}</Typography>
								<LeagueIcon id={competition?.id} size={20} />
							</Box>
						)}
						<Box display="flex" gap="6px">
							{nextFixtures.map((game) => (
								<ModalProfileGame key={game}>
									<Typography variant="body2" color="#FFF">
										vs {game}
									</Typography>
								</ModalProfileGame>
							))}
						</Box>
					</Box>

					<Exist when={!isPreseason}>
						<Tabs value={activeTab} onChange={handleChangeTab}>
							<Tab
								value={ProfileTab.Overview}
								label={i18n.t("player_profile.tabs.overview", "Overview")}
							/>
							<Tab
								value={ProfileTab.Stats}
								label={i18n.t("player_profile.tabs.fantasy_stats", "Fantasy Stats")}
							/>
							<Tab
								value={ProfileTab.Results}
								label={i18n.t("player_profile.tabs.results", "Results")}
							/>
						</Tabs>
					</Exist>

					<Exist when={activeTab === ProfileTab.Overview}>
						<SquadProfileOverview squad={squad} />
					</Exist>
					<Exist when={activeTab === ProfileTab.Stats}>
						<SquadProfileFantasyStats squad={squad} />
					</Exist>
					<Exist when={activeTab === ProfileTab.Results}>
						<SquadProfileResults squad={squad} />
					</Exist>

					<Exist when={!hideButtons}>
						<SquadProfileButtons squadId={squad.id} />
					</Exist>
				</ModalProfileInfo>
			</ModalContent>
		</Modal>
	);
});
