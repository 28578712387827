import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {isNumber} from "lodash";
import {RankStatus} from "data/enums";

export interface IOverallRankingsCardController extends ViewController {
	readonly i18n: ILocalizationStore;
	get rank(): number | undefined;
	get rankStatus(): RankStatus | null;
}

@injectable()
export class OverallRankingsCardController implements IOverallRankingsCardController {
	get rank() {
		return this._leaguesStore.myLeagues.overallLeaderboardCard?.rank;
	}

	get rankStatus() {
		if (!this._leaguesStore.myLeagues.overallLeaderboardCard) {
			return null;
		}

		const {rank, prevRank} = this._leaguesStore.myLeagues.overallLeaderboardCard;

		if (!isNumber(rank) || !isNumber(prevRank)) {
			return null;
		}

		if (rank === prevRank) {
			return RankStatus.SAME;
		}

		return prevRank > rank ? RankStatus.UP : RankStatus.DOWN;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}
}
