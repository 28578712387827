import {makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IGame, IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ICompetition, ICompetitionsStore} from "data/stores/competitions/competitions.store";
import {chain, get, isEmpty, xor} from "lodash";
import {parseSingleValueFromArray} from "data/utils";
import type {IUserStore} from "data/stores/user/user.store";
import type {ISquadsStore} from "data/stores/squads/squads.store";

export interface IFixturesController extends ViewController {
	readonly i18n: ILocalizationStore;
	get selectedRound(): IRound | undefined;
	get hasPreviousRound(): boolean;
	get hasNextRound(): boolean;
	get fixtures(): IGame[];
	get competitions(): ICompetition[];
	get filteredCompetitions(): string[];
	setCompetition: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	previousRound: () => void;
	nextRound: () => void;
}

@injectable()
export class FixturesController implements IFixturesController {
	@observable private _filterCompetitions: string[] = ["null"];
	@observable private _filterFixturesDisposer?: ReturnType<typeof reaction>;
	@observable private _selectedRoundId: number | null = null;

	get rounds(): IRound[] {
		return this._roundsStore.list;
	}

	get selectedRound(): IRound | undefined {
		if (this._selectedRoundId) {
			return this._roundsStore.getRoundById(this._selectedRoundId);
		}
		return this._roundsStore.selectedRound;
	}

	get selectedRoundIndex() {
		return this.rounds.findIndex((round) => round.id === this.selectedRound?.id);
	}

	get hasPreviousRound() {
		return this.selectedRoundIndex > 0;
	}

	get hasNextRound() {
		return this.selectedRoundIndex < this.rounds.length - 1;
	}

	get fixtures(): IGame[] {
		if (!this.selectedRound) {
			return [];
		}
		return chain(this.selectedRound.games)
			.filter(
				({competitionId}) =>
					this._filterCompetitions.includes(competitionId.toString()) ||
					this._filterCompetitions.includes("null")
			)
			.orderBy(["date", "competitionId"])
			.value();
	}

	get competitions(): ICompetition[] {
		return this._competitionsStore.list;
	}

	get filteredCompetitions(): string[] {
		return this._filterCompetitions;
	}

	private get isFilterCompetitionsEmpty(): boolean {
		return isEmpty(this._filterCompetitions) || this._filterCompetitions.includes("null");
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.CompetitionsStore) private _competitionsStore: ICompetitionsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	init(): void {
		this._filterFixturesDisposer = reaction(
			() => this._userStore.user,
			() => {
				if (this._userStore.user) {
					const competition = this._squadsStore.getSquadById(
						this._userStore.user.teamSupportedId
					);
					const competitionId = get(competition, "competitionId", null);
					this._filterCompetitions = [String(competitionId)];
				} else {
					this._filterCompetitions = ["null"];
				}
			},
			{fireImmediately: true}
		);
	}

	dispose(): void {
		this._filterFixturesDisposer?.();
	}

	public setCompetition = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		if (this.isFilterCompetitionsEmpty) {
			const competitionIds = this.competitions.map(({id}) => id.toString());
			this._filterCompetitions = competitionIds;
		}
		const {competition} = e.currentTarget.dataset;
		const value = xor(this._filterCompetitions, [competition as string]);
		this._filterCompetitions = parseSingleValueFromArray(value, "null") as string[];
	};

	public previousRound = (): void => {
		const round = this.rounds[this.selectedRoundIndex - 1];
		if (round) {
			this._selectedRoundId = round.id;
		}
	};

	public nextRound = (): void => {
		const round = this.rounds[this.selectedRoundIndex + 1];
		if (round) {
			this._selectedRoundId = round.id;
		}
	};
}
