import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {GameStatus, ModalType, RoundStatus} from "data/enums";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import {isNull, isNumber} from "lodash";
import type {IRankingsStore} from "data/stores/rankings/rankings.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";

interface IParam {
	playerId: number;
}

export interface IOtherUserPlayerCardController extends ViewController<IParam> {
	get player(): IPlayer | undefined;
	get squad(): ISquad | undefined;
	get isCaptain(): boolean;
	get isViceCaptain(): boolean;
	get isMaxCaptain(): boolean;
	get points(): number | string;
	get className(): string;
	handleClick: (e: React.SyntheticEvent<HTMLDivElement>) => void;
}

@injectable()
export class OtherUserPlayerCardController implements IOtherUserPlayerCardController {
	@observable private _playerId = 0;

	get player() {
		return this._playersStore.getPlayerById(this._playerId);
	}

	get squad() {
		if (!this.player) {
			return;
		}
		return this._squadsStore.getSquadById(this.player.squadId);
	}

	get isCaptain() {
		return (
			!this._teamStore.otherUserTeam.maxCaptainEnabled &&
			this._teamStore.otherUserTeam.captainId === this._playerId
		);
	}

	get isViceCaptain() {
		return (
			!this._teamStore.otherUserTeam.maxCaptainEnabled &&
			this._teamStore.otherUserTeam.viceCaptainId === this._playerId
		);
	}

	get isMaxCaptain() {
		return this._teamStore.getMaxCaptain(this._teamStore.otherUserTeam) === this._playerId;
	}

	get roundId() {
		return isNumber(this._rankingsStore.ladderType)
			? this._rankingsStore.ladderType
			: this._roundsStore.scoreRound?.id;
	}

	get points() {
		if (!this.roundId) {
			return "-";
		}
		const points = this._teamStore.getPlayerRoundPoints(
			this._playerId,
			this.roundId,
			undefined,
			this._teamStore.otherUserTeam
		);
		return isNull(points) ? "-" : points;
	}

	private get isRoundPlaying() {
		if (!this.roundId) {
			return false;
		}
		const round = this._roundsStore.getRoundById(this.roundId);
		return round?.status === RoundStatus.Playing;
	}

	private get isPlaying() {
		if (!this.player) {
			return false;
		}
		return this.isRoundPlaying && this._roundsStore.isSquadPlaying(this.player.squadId);
	}

	get className() {
		if (this.isPlaying) {
			return "playing";
		}
		if (this.isMaxCaptain) {
			return "max-captain";
		}
		if (this.isCaptain) {
			return "captain";
		}
		if (this.isViceCaptain) {
			return "vice-captain";
		}
		return "";
	}

	private get match() {
		if (!this.player) {
			return;
		}
		const match = this._roundsStore.getRoundFixture(this.player.squadId);
		if (!match) {
			return;
		}
		const isMatchComplete = match.status === GameStatus.Complete;
		const isMatchPlaying = match.status === GameStatus.Playing;
		const isMatchAvailable = isMatchComplete || isMatchPlaying;
		if (isMatchAvailable) {
			return match;
		}
	}

	constructor(
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	@action handleClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
		if (!this.player) {
			return;
		}
		if (this.match) {
			this._modalsStore.showModal(ModalType.PLAYER_PROFILE, {
				playerId: this._playerId,
				matchId: this.match.id,
				hideButtons: true,
			});
			return;
		}
		this._modalsStore.showModal(ModalType.PLAYER_PROFILE, {
			playerId: this._playerId,
			hideButtons: true,
		});
	};

	init(param: IParam): void {
		this._playerId = param.playerId;
	}

	onChange(param: IParam): void {
		this._playerId = param.playerId;
	}
}
