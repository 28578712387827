import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import {PlayerPoolType} from "data/enums";

export interface IPlayerPoolSearchController extends ViewController {
	readonly i18n: ILocalizationStore;
	get searchValue(): string;
	get placeholder(): string;
	updateSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class PlayerPoolSearchController implements IPlayerPoolSearchController {
	get searchValue(): string {
		return this._teamBuilderStore.filters.search;
	}

	get placeholder(): string {
		return this._teamBuilderStore.playerPoolType === PlayerPoolType.Player
			? this.i18n.t("player_pool.search_filter.placeholder", "Search for a player")
			: this.i18n.t("player_pool.club_search_filter.placeholder", "Search for a club");
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore
	) {
		makeAutoObservable(this);
	}

	updateSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		this._teamBuilderStore.updateSearch(e);
	};
}
