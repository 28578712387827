import React, {useEffect, useRef} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IPlayerProfileMatchController} from "views/components/modals/modal_player_profile/player_profile_results/player_profile_match/player_profile_match.controller";
import {Box, Collapse, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {PlayerPosition} from "data/enums";
import {
	ModalProfileMatch,
	ModalProfileMatchClubBadge,
	ModalProfileMatchInner,
	ModalProfileMatchPoints,
	ModalProfileMatchStat,
} from "views/components/modals/modal_profile_styles";
import {Exist} from "views/components/exist/exist.component";
import {DateTime} from "luxon";
import {get} from "lodash";

interface IProps {
	gameId: number;
	playerId: number;
	position: PlayerPosition;
}

export const PlayerProfileMatch: React.FC<IProps> = observer(({gameId, playerId, position}) => {
	const ref = useRef<HTMLDivElement>(null);
	const {
		i18n,
		stats,
		game,
		isScheduled,
		homeSquad,
		awaySquad,
		isExpanded,
		hasStats,
		matchStats,
		toggleDropdown,
	} = useViewController<IPlayerProfileMatchController>(Bindings.PlayerProfileMatchController, {
		gameId,
		playerId,
		position,
	});

	useEffect(() => {
		if (isExpanded && ref.current) {
			ref.current.scrollIntoView({behavior: "smooth", block: "start"});
		}
	}, [isExpanded]);

	return (
		<ModalProfileMatch ref={ref}>
			<ModalProfileMatchInner className={isExpanded ? "open" : ""}>
				<Box textAlign="center">
					{homeSquad && (
						<ModalProfileMatchClubBadge
							src={homeSquad.darkBadge}
							alt={homeSquad.name}
						/>
					)}
				</Box>
				<Box textAlign="center">
					<Typography variant="body2" fontWeight={700}>
						{homeSquad?.abbreviation}
					</Typography>
					<Typography variant="body2" fontWeight={700}>
						{game?.homeScore}
					</Typography>
				</Box>
				<Exist when={!isScheduled}>
					<ModalProfileMatchPoints
						onClick={toggleDropdown}
						disabled={!hasStats}
						className={game?.status}>
						<Typography variant="body2">
							{i18n.t("player_profile.match.points", "{{points}} Pts", {
								points: get(matchStats, "points", 0),
							})}
						</Typography>
						<Exist when={hasStats}>
							{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</Exist>
					</ModalProfileMatchPoints>
				</Exist>
				<Exist when={isScheduled}>
					{game && (
						<Box textAlign="center">
							<Typography variant="body2" color="primary.main">
								{DateTime.fromISO(game.date).toFormat("HH:mm")}
							</Typography>
							<Typography variant="caption">
								{DateTime.fromISO(game.date).toFormat("dd/MM/yyyy")}
							</Typography>
						</Box>
					)}
				</Exist>
				<Box textAlign="center">
					<Typography variant="body2" fontWeight={700}>
						{awaySquad?.abbreviation}
					</Typography>
					<Typography variant="body2" fontWeight={700}>
						{game?.awayScore}
					</Typography>
				</Box>
				<Box textAlign="center">
					{awaySquad && (
						<ModalProfileMatchClubBadge
							src={awaySquad.darkBadge}
							alt={awaySquad.name}
						/>
					)}
				</Box>
			</ModalProfileMatchInner>
			<Exist when={matchStats}>
				<Collapse in={isExpanded}>
					{stats.map((stat, index) => {
						const value = stat.val();
						const color = value > 0 ? "success.dark" : "error.dark";
						const occurrences = matchStats ? matchStats[stat.stat] : 0;
						const label = occurrences === 1 ? stat.label : `${stat.label}_plural`;

						return (
							<ModalProfileMatchStat key={index}>
								<Typography variant="body2">
									{occurrences} {i18n.t(label)}
								</Typography>
								<Typography variant="body2" color={color} fontWeight={700}>
									{value > 0 ? `+${value}` : value}
								</Typography>
							</ModalProfileMatchStat>
						);
					})}
				</Collapse>
			</Exist>
		</ModalProfileMatch>
	);
});
