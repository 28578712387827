import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {ITeamStore} from "data/stores/team/team.store";

export interface IModalSaveTeamController extends ViewController {
	i18n: ILocalizationStore;
	get isOpen(): boolean;
	close: () => void;
	saveTeam: () => void;
}

@injectable()
export class ModalSaveTeamController implements IModalSaveTeamController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.TeamStore) private readonly _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.SAVE_TEAM;
	}

	close = () => {
		this._modalsStore.hideModal();
	};

	saveTeam = () => {
		localStorage.setItem("team", JSON.stringify(this._teamStore.team));
	};
}
