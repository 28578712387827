import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {ModalType, RequestState} from "data/enums";
import {isEqual} from "lodash";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";

export interface IModalBoostersController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isLoading(): boolean;
	get isOpen(): boolean;
	get captainId(): number;
	get title(): string;
	get description(): string;
	get buttonText(): string;
	close: () => void;
	confirm: () => void;
}

@injectable()
export class ModalBoostersController implements IModalBoostersController {
	@observable private _requestState = RequestState.IDLE;

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.TeamStore) private readonly _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.BOOSTERS;
	}

	get captainId(): number {
		return this._teamStore.team.captainId;
	}

	private get isMaxCaptainEnabled(): boolean {
		return this._teamStore.team.maxCaptainEnabled;
	}

	get title(): string {
		if (this.isMaxCaptainEnabled) {
			return this.i18n.t("max_captain.reset_modal.header", "Max Captain Booster");
		}
		return this.i18n.t("max_captain.modal.header", "Max Captain Booster");
	}

	get description(): string {
		if (this.isMaxCaptainEnabled) {
			return this.i18n.t(
				"max_captain.reset_modal.description",
				"With the Max Captain booster, your player with the most points at the end of the gameweek is made Captain, earning you double points.  You can reset this booster up until the first game of the gameweek."
			);
		}
		return this.i18n.t(
			"max_captain.modal.description",
			"With the Max Captain booster, your player with the most points at the end of the gameweek is made Captain, earning you double points.  You can reset this booster up until the first game of the gameweek."
		);
	}

	get buttonText(): string {
		if (this.isMaxCaptainEnabled) {
			return this.i18n.t("max_captain.reset_modal.deactivate", "Remove Booster");
		}
		return this.i18n.t("max_captain.modal.button", "Use Booster");
	}

	close = () => {
		this._modalsStore.hideModal();
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
		this.close();
	};

	@action confirm = async () => {
		this._requestState = RequestState.PENDING;
		await this._teamStore
			.setMaxCaptain(!this.isMaxCaptainEnabled)
			.then(this.onSuccess)
			.catch(this.onError);
	};
}
