import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {AxiosResponse} from "axios";
import type {IApiResponse, IHttpClientService} from "data/services/http";

export interface IRankingsPayload {
	page?: number;
	limit?: number;
}

export interface IWeeklyRankingsPayload extends IRankingsPayload {
	roundId?: number;
}

export interface IRanking {
	userId: number;
	teamName: string;
	displayName: string;
	rank: number | null;
	overallPoints: number | null;
	roundPoints: number | null;
	prevRank: number | null;
}

export interface IRankingsResponse {
	rankings: IRanking[];
	user: IRanking | null;
	nextPage: boolean;
}

export interface ILeaderboardResponse {
	leaderboard: IRankingsResponse;
}

export type TLeaderboardResponse = IApiResponse<ILeaderboardResponse>;

export interface ILadderPayload extends IRankingsPayload {
	leagueId: number;
}

export interface IWeeklyLadderPayload extends ILadderPayload {
	roundId?: number;
}

export interface ILadderResponse {
	ladder: IRankingsResponse;
}

export type TLadderResponse = IApiResponse<ILadderResponse>;

export interface IRankingsApiProvider {
	overall_leaderboard: (params: IRankingsPayload) => Promise<AxiosResponse<TLeaderboardResponse>>;
	weekly_leaderboard: (
		params: IWeeklyRankingsPayload
	) => Promise<AxiosResponse<TLeaderboardResponse>>;
	overall_ladder: (params: ILadderPayload) => Promise<AxiosResponse<TLadderResponse>>;
	weekly_ladder: (params: IWeeklyLadderPayload) => Promise<AxiosResponse<TLadderResponse>>;
}

@injectable()
export class RankingsApiProvider implements IRankingsApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	overall_leaderboard = (params: IRankingsPayload) =>
		this._http.get<TLeaderboardResponse>("fantasy/rankings/overall_leaderboard", params);
	weekly_leaderboard = (params: IWeeklyRankingsPayload) =>
		this._http.get<TLeaderboardResponse>("fantasy/rankings/weekly_leaderboard", params);
	overall_ladder = (params: ILadderPayload) =>
		this._http.get<TLadderResponse>("fantasy/ranking/overall_ladder", params);
	weekly_ladder = (params: IWeeklyLadderPayload) =>
		this._http.get<TLadderResponse>("fantasy/ranking/weekly_ladder", params);
}
