import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {IGame} from "data/stores/rounds/rounds.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {GameStatus} from "data/enums";

interface IParam {
	fixture: IGame;
}

export interface IFixtureController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get homeSquad(): ISquad | undefined;
	get awaySquad(): ISquad | undefined;
	get isScheduled(): boolean;
}

@injectable()
export class FixtureController implements IFixtureController {
	@observable private _fixture: IGame | undefined;

	get homeSquad(): ISquad | undefined {
		if (!this._fixture) {
			return;
		}
		return this._squadsStore.getSquadById(this._fixture.homeId);
	}

	get awaySquad(): ISquad | undefined {
		if (!this._fixture) {
			return;
		}
		return this._squadsStore.getSquadById(this._fixture.awayId);
	}

	get isScheduled(): boolean {
		if (!this._fixture) {
			return false;
		}
		return this._fixture.status === GameStatus.Scheduled;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	init(param: IParam): void {
		this._fixture = param.fixture;
	}

	onChange(param: IParam): void {
		this._fixture = param.fixture;
	}
}
