import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import type {ITeamStore} from "data/stores/team/team.store";
import {chain} from "lodash";
import type {ISquadsStore} from "data/stores/squads/squads.store";

export interface IModalClubCapController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isOpen(): boolean;
	get oldTeamNames(): string;
	get newTeamNames(): string;
	close: () => void;
}

@injectable()
export class ModalClubCapController implements IModalClubCapController {
	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.TeamStore) private readonly _teamStore: ITeamStore,
		@inject(Bindings.SquadsStore) private readonly _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.CLUB_CAP;
	}

	get oldTeamNames(): string {
		if (!this._teamStore.team.clubCapacityModal) {
			return "";
		}

		const name = chain(this._teamStore.team.clubCapacityModal)
			.map((squads) => this._squadsStore.getSquadById(squads.oldSquadId))
			.compact()
			.map((squad) => squad.name)
			.join(" and ")
			.value();

		return name ?? "";
	}

	get newTeamNames(): string {
		if (!this._teamStore.team.clubCapacityModal) {
			return "";
		}

		const name = chain(this._teamStore.team.clubCapacityModal)
			.map((squads) => this._squadsStore.getSquadById(squads.newSquadId))
			.compact()
			.map((squad) => squad.name)
			.join(" and ")
			.value();

		return name ?? "";
	}

	close = () => {
		this._modalsStore.hideModal();
		this._teamStore.setClubCapModal();
	};
}
