import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IPlayerProfileOverviewController} from "views/components/modals/modal_player_profile/player_profile_overview/player_profile_overview.controller";
import {Typography} from "@mui/material";
import {Exist} from "views/components/exist/exist.component";
import {IPlayer} from "data/stores/players/players.store";
import {ModalProfileStat, ModalProfileStats} from "views/components/modals/modal_profile_styles";

interface IProps {
	player: IPlayer;
}

export const PlayerProfileOverview: React.FC<IProps> = observer(({player}) => {
	const {i18n, isPreseason} = useViewController<IPlayerProfileOverviewController>(
		Bindings.PlayerProfileOverviewController
	);

	return (
		<ModalProfileStats className={isPreseason ? "preseason" : ""}>
			<ModalProfileStat>
				<Typography variant="body2" fontWeight={700}>
					{player.percentSelected}
				</Typography>
				<Typography variant="caption" fontSize={10}>
					{i18n.t("player_profile.stat.selected", "% Selected")}
				</Typography>
			</ModalProfileStat>
			<Exist when={!isPreseason}>
				<ModalProfileStat>
					<Typography variant="body2" fontWeight={700}>
						{player.roundPoints ?? "-"}
					</Typography>
					<Typography variant="caption" fontSize={10}>
						{i18n.t("player_profile.stat.gameweek_points", "GW Points")}
					</Typography>
				</ModalProfileStat>
				<ModalProfileStat>
					<Typography variant="body2" fontWeight={700}>
						{player.averagePoints ?? "-"}
					</Typography>
					<Typography variant="caption" fontSize={10}>
						{i18n.t("player_profile.stat.average_points", "Average Points")}
					</Typography>
				</ModalProfileStat>
				<ModalProfileStat>
					<Typography variant="body2" fontWeight={700}>
						{player.totalPoints ?? "-"}
					</Typography>
					<Typography variant="caption" fontSize={10}>
						{i18n.t("player_profile.stat.total_points", "Total Points")}
					</Typography>
				</ModalProfileStat>
			</Exist>
		</ModalProfileStats>
	);
});
