import type {ComponentType} from "react";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {isEmpty, last} from "lodash";
import {SSO_LOGIN_URL} from "data/constants";

type Factory<T> = () => Promise<{
	default: ComponentType<T>;
}>;

export function retryFailLoad<T>(fn: Factory<T>, retriesLeft = 5, interval = 1000): Factory<T> {
	return () =>
		new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error: unknown) => {
					setTimeout(() => {
						if (retriesLeft === 1) {
							reject(error);
							return;
						}

						retryFailLoad(fn, retriesLeft - 1, interval)().then(resolve, reject);
					}, interval);
				});
		});
}

export const extractErrorMessage = (error: AxiosError<IApiResponse>) =>
	error.response?.data?.errors?.[0]?.message || error.message;

export const copyToClipboard = async (content: string) => {
	try {
		await navigator.clipboard.writeText(content);
		return true;
	} catch (_err) {
		return false;
	}
};

/*
 * If last value of array is necessary value - clear all other values
 * If added new value after necessary value - remove it from array to filter correct
 */
export function parseSingleValueFromArray(
	values: string | string[],
	singleValue: string
): string | string[] {
	if (!Array.isArray(values)) {
		return values;
	}
	if (isEmpty(values) || last(values) === singleValue) {
		return [singleValue];
	}
	if (values.includes(singleValue) && last(values) !== singleValue) {
		return values.filter((value) => value !== singleValue);
	}
	return values;
}

export const getLoginUrl = (path = "") =>
	`${SSO_LOGIN_URL}?returnUrl=${encodeURIComponent(window.location.origin + path)}`;

const pr = new Intl.PluralRules("en-US", {
	type: "ordinal",
});

const suffixes = new Map([
	["one", "st"],
	["two", "nd"],
	["few", "rd"],
	["other", "th"],
]);

export const formatOrdinals = (n: number) => {
	const rule = pr.select(n);
	const suffix = suffixes.get(rule) as string;
	return `${n}${suffix}`;
};

export * from "data/utils/countdown";
export * from "data/utils/socialShare";
