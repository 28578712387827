import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {CreateTeamSteps, ModalType, RequestState, RoundStatus} from "data/enums";
import type {IUserStore} from "data/stores/user/user.store";
import {isEqual} from "lodash";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {extractErrorMessage} from "data/utils";
import type {ITeamBuilderStore} from "data/stores/team_builder/team_builder.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {useNavigate} from "react-router-dom";
import type {ISnackbarStore} from "data/stores/snackbar/snackbar.store";

interface IParam {
	navigate: ReturnType<typeof useNavigate>;
}

export interface ITeamActionButtonsController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get isViceCaptainButtonVisible(): boolean;
	get isSaveTeamButtonVisible(): boolean;
	get hasCaptain(): boolean;
	get isTeamComplete(): boolean;
	get isLoading(): boolean;
	get isSaveTeamButtonDisabled(): boolean;
	get isEditTeamButtonVisible(): boolean;
	get isPlayerPoolButtonVisible(): boolean;
	get className(): string;

	pickTeam: () => void;
	pickViceCaptain: () => void;
	saveTeam: () => void;
	openPlayerPool: () => void;
}

@injectable()
export class TeamActionButtonsController implements ITeamActionButtonsController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _navigate?: ReturnType<typeof useNavigate>;

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isViceCaptainButtonVisible(): boolean {
		return this._teamStore.teamCreationStep.step === CreateTeamSteps.PickCaptain;
	}

	get isSaveTeamButtonVisible(): boolean {
		if (this._teamStore.isTeamCreated) {
			return this._teamStore.isTeamChanged;
		}
		return [CreateTeamSteps.PickViceCaptain, CreateTeamSteps.UpdateTeam].includes(
			this._teamStore.teamCreationStep.step
		);
	}

	get hasCaptain(): boolean {
		return this._teamStore.team.captainId !== 0;
	}

	get isTeamComplete(): boolean {
		return this._teamStore.isTeamComplete;
	}

	get isSaveTeamButtonDisabled(): boolean {
		return this.isLoading || !this.isTeamComplete;
	}

	get isEditTeamButtonVisible(): boolean {
		return !this._teamStore.isTeamCreated;
	}

	get isPlayerPoolButtonVisible(): boolean {
		return this._teamStore.isTeamCreated && !this._teamStore.isTeamChanged;
	}

	private get isCompletedRound() {
		return this._roundsStore.selectedRound?.status === RoundStatus.Complete;
	}

	get className(): string {
		if (this._teamStore.isTeamCreated) {
			return this.isCompletedRound ? "hide" : "hide-desktop";
		}
		if (
			[
				CreateTeamSteps.PickCaptain,
				CreateTeamSteps.PickViceCaptain,
				CreateTeamSteps.UpdateTeam,
			].includes(this._teamStore.teamCreationStep.step)
		) {
			return "sticky-mobile";
		}
		return "hide";
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.TeamBuilderStore) private _teamBuilderStore: ITeamBuilderStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.SnackbarStore) private _snackbarStore: ISnackbarStore
	) {
		makeAutoObservable(this);
	}

	init({navigate}: IParam) {
		this._navigate = navigate;
	}

	pickTeam = () => {
		this._teamStore.selectedStep = CreateTeamSteps.PickTeam;
	};

	pickViceCaptain = () => {
		this._teamStore.selectedStep = CreateTeamSteps.PickViceCaptain;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
		this._teamStore.selectedStep = CreateTeamSteps.UpdateTeam;

		const savedLeagueCode = localStorage.getItem("leagueToJoinCode");
		if (savedLeagueCode) {
			localStorage.removeItem("leagueToJoinCode");
			void this._leaguesStore
				.joinToLeague({code: savedLeagueCode})
				.then(this.onSuccessJoined)
				.catch(this.onError);
		} else {
			this._modalsStore.showModal(ModalType.TEAM_SAVED);
		}
	};

	private onSuccessJoined = () => {
		const league = this._leaguesStore.leagueToJoin;
		if (this._navigate && league) {
			this._snackbarStore.enqueueSnackbar(
				this.i18n.t(
					"league_join.notification.success",
					`Congratulations you have joined ${league.name}!`,
					{name: league.name}
				)
			);
			this._navigate(`/league/${league.id}/ladder`);
		}
	};

	@action saveTeam = async () => {
		if (this._userStore.isAuthorized) {
			this._requestState = RequestState.PENDING;
			await this._teamStore.saveTeam().then(this.onSuccess).catch(this.onError);
		} else {
			this._modalsStore.showModal(ModalType.SAVE_TEAM);
		}
	};

	@action openPlayerPool = () => {
		this._teamBuilderStore.openPlayerPool();
	};
}
