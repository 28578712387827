import React, {Fragment} from "react";
import {ModalError} from "views/components/modals/modal_error/modal_error.component";
import {ModalConfirm} from "views/components/modals/modal_confirm/modal_confirm.component";
import {Snackbar} from "views/components/snackbar/snackbar.component";
import {ModalRegistration} from "views/components/modals/modal_registration/modal_registration.component";
import {ModalLeagueJoin} from "views/components/modals/modal_league_join/modal_league_join.component";
import {ModalTutorial} from "views/components/modals/modal_tutorial/modal_tutorial.component";
import {ModalPlayerProfile} from "views/components/modals/modal_player_profile/modal_player_profile.component";
import {ModalSquadProfile} from "views/components/modals/modal_squad_profile/modal_squad_profile.component";

export const GlobalModals: React.FC = () => {
	return (
		<Fragment>
			<Snackbar />
			<ModalPlayerProfile />
			<ModalSquadProfile />
			<ModalTutorial />
			<ModalLeagueJoin />
			<ModalRegistration />
			<ModalError />
			<ModalConfirm />
		</Fragment>
	);
};
