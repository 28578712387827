import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";

export interface IPlayerProfileOverviewController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isPreseason(): boolean;
}

@injectable()
export class PlayerProfileOverviewController implements IPlayerProfileOverviewController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	get isPreseason(): boolean {
		return !this._roundsStore.isSeasonStarted;
	}
}
