import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {extractErrorMessage} from "data/utils";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {SetURLSearchParams} from "react-router-dom";

interface IParam {
	searchParams: URLSearchParams;
	setSearchParams: SetURLSearchParams;
}

export interface ISessionController extends ViewController<IParam> {
	get isSessionChecked(): boolean;
}

@injectable()
export class SessionController implements ISessionController {
	@observable _isSessionChecked = false;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isSessionChecked(): boolean {
		return this._isSessionChecked;
	}

	@action init({searchParams, setSearchParams}: IParam) {
		const token = searchParams.get("token");

		if (token) {
			this._userStore.token = token;
			searchParams.delete("token");
			setSearchParams(searchParams);
		}

		if (this._userStore.token) {
			void this._userStore
				.login({token: this._userStore.token})
				.catch(this.onLoginError)
				.then(this.setSessionChecked);
		} else {
			void this._userStore.requestUser().catch(this.onUserError).then(this.setSessionChecked);
		}
	}

	private onLoginError = (error: AxiosError<IApiResponse>) => {
		if (error.response?.status === 418) {
			this._modalsStore.showModal(ModalType.REGISTRATION);
		} else {
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error),
			});
		}
	};

	private onUserError = (error: AxiosError<IApiResponse>) => {
		if (error.response?.status !== 401) {
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error),
			});
		}
	};

	@action private setSessionChecked = () => {
		runInAction(() => {
			this._isSessionChecked = true;
		});
	};
}
