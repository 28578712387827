import {action, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RequestState} from "data/enums";
import {useNavigate} from "react-router-dom";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import {isEqual} from "lodash";
import type {IUserStore} from "data/stores/user/user.store";

interface IParam {
	navigate: ReturnType<typeof useNavigate>;
}

export interface IModalLeagueJoinController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get isOpen(): boolean;
	get isLoading(): boolean;
	get league(): ILeague | null;
	get leagueCode(): string;
	get isAuthorized(): boolean;
	close: () => void;
	setLeagueCode: () => void;
	joinLeague: () => void;
}

@injectable()
export class ModalLeagueJoinController implements IModalLeagueJoinController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _navigate!: ReturnType<typeof useNavigate>;
	@observable private _fetchLeagueDisposer?: ReturnType<typeof reaction>;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.LeaguesStore) private readonly _leaguesStore: ILeaguesStore,
		@inject(Bindings.UserStore) private readonly _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.LEAGUE_JOIN;
	}

	get isLoading(): boolean {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get league(): ILeague | null {
		return this._leaguesStore.leagueToJoin;
	}

	get leagueCode(): string {
		return (this._modalsStore.modalContent?.leagueCode as string) || "";
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	close = () => {
		this._modalsStore.hideModal();
	};

	setLeagueCode = () => {
		localStorage.setItem("leagueToJoinCode", this.leagueCode);
	};

	@action joinLeague = () => {
		this._requestState = RequestState.PENDING;
		this._leaguesStore
			.joinToLeague({code: this.leagueCode})
			.then(() => {
				this._requestState = RequestState.SUCCESS;
				if (this.league) {
					this._modalsStore.hideModal();
					this._navigate(`/league/${this.league.id}/ladder`);
				}
			})
			.catch(this.onError);
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action init(param: IParam): void {
		this._navigate = param.navigate;

		this._fetchLeagueDisposer = reaction(
			() => this.isOpen,
			(isOpen) => {
				if (isOpen) {
					void this._leaguesStore
						.fetchLeagueByCode({code: this.leagueCode})
						.then(this.onSuccess)
						.catch(this.onError);
				}
			}
		);
	}

	dispose(): void {
		this._fetchLeagueDisposer?.();
	}
}
